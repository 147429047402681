import React from "react";
import { Modal } from "react-bootstrap";
import "./index.css";
type ModalTypes = {
    isOpen: boolean;
    setIsOpen: Function;
    setAction: Function;
    actionTitle: string;
    message?: string;
    header: any;
    loading?: boolean;
    children?: any;
};

const CustomModal = ({ isOpen, setIsOpen, setAction, actionTitle, loading, header, message, children }: ModalTypes) => {
    return (
        <Modal show={isOpen} onHide={() => {}} centered>
            <div
                className="popup popup--alt"
                style={{
                    position: "relative",
                    backgroundColor: "#fff",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                }}>
                <>
                    <div className="modal-close">
                        {" "}
                        <span onClick={() => setIsOpen()} className="modal-close-btn">
                            <img src="/assets/images/svg/ico-cross.svg" width="15" alt="close" />
                        </span>
                    </div>
                    <div className="popup__content">
                        <h3>
                            <b>{header}</b>
                        </h3>
                        {message && <p>{message}</p>}
                    </div>
                    {children}
                    <div className="popup__actions">
                        <button
                            type="button"
                            className="btn__cancel button--small"
                            onClick={() => {
                                setIsOpen();
                            }}>
                            Cancel
                        </button>
                        {loading ? (
                            <button type="button" className="btn " disabled={loading}>
                                <i className="fas fa-circle-notch fa-spin"></i>
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn button--small"
                                onClick={() => {
                                    setIsOpen();
                                    setAction();
                                }}>
                                {actionTitle}
                            </button>
                        )}
                    </div>
                </>
            </div>
        </Modal>
    );
};

export default CustomModal;
