import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { campaignClient } from "../../api";
import { useQuery } from "react-query";
import { showToast } from "../../Components/Toast/ToastManager";
import { PageLoader } from "../../Components";
import { Link } from "react-router-dom";
import { AddQuestionArgs } from "../../helpers/types";


export type SurveyType = "text" | "radio" | "free";
function AddSurveyQuestion() {
    const inputId = crypto.randomUUID();

    const [loading, setLoading] = useState(false);

    const {
        isLoading,
        data: q_seq,
        refetch,
    } = useQuery("getSurvey", async () => {
        const res: { survey: AddQuestionArgs[] } = await campaignClient.call("getSurvey", {});
        if (res.survey?.length) {
            const survey = res.survey.map((i) => i.q_seq);
            const maxSeq = survey ? Math.max(...survey) : 0;
            return maxSeq + 1;
        }
        return 1;
    });

    const {
        handleSubmit,
        register,
        setValue,
        control,
        watch,
        formState: { errors },
    } = useForm<AddQuestionArgs>({
        criteriaMode: "all",
        defaultValues: {
            id: crypto.randomUUID() as string,
            type: "free",
            question: { en: "", fr: "" },
            options: [
                { label_en: "", label_fr: "", id: crypto.randomUUID() },
                { label_en: "", label_fr: "", id: crypto.randomUUID() },
            ],
        },
    });

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: "options",
    });

    const handleSaveChanges = async (data: AddQuestionArgs) => {
        if (loading) {
            return;
        }
        setLoading(true);
        if (data.type === "free") {
            data.options = [];
        }

        const { result } = await campaignClient.call<{ result: boolean }>("saveQuestion", { ...data, statsType: "survey", q_seq });

        if (result) {
            refetch();
            setValue("id", crypto.randomUUID() as string);
            showToast({
                content: "Saved",
                duration: 3000,
                error: false,
            });

            setLoading(false);
        } else {
            showToast({
                content: "Try again ",
                duration: 3000,
                error: true,
            });
        }
        setLoading(false);
    };

    if (loading) {
        return <PageLoader />;
    }
    return (
        <>
            <div className="main__head">
                <h2 className="main__title">Add a survey Question</h2>
            </div>

            <div className="main__body main__body--flex main__body--flex-alt">
                <form onSubmit={handleSubmit(handleSaveChanges)} className="form">
                    <div style={{ maxWidth: "600px" }}>
                        <div className="form__row">
                            <label htmlFor="question.en" className="form__label">
                                <b>Question - English</b>
                            </label>

                            <input
                                type="text"
                                className="field" 
                                {...register("question.en", { required: { value: true, message: "Please enter question in English." } })}
                            />
                            {errors.question?.en && (
                                <p className="error-message">
                                    <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                                    {errors.question.en.message}
                                </p>
                            )}
                        </div>
                        <div className="form__row">
                            <label htmlFor="question.fr" className="form__label">
                                <b>Question - French</b>
                            </label>
                            <input
                                type="text"
                                className="field"   
                                {...register("question.fr", { required: { value: true, message: "Please enter question in French." } })}
                            />
                            {errors.question?.fr && (
                                <p className="error-message">
                                    <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                                    {errors.question?.fr.message}
                                </p>
                            )}
                        </div>
                        <div className="form__row">
                            <label htmlFor="type" className="form__label">
                                <b>Question type</b>
                            </label>
                            <select className="form-control-select" {...register("type")} style={{ width: "60%" }}>
                                <option value="free">Free input (one line)</option>
                                <option value="text">Text box</option>
                                <option value="radio">Radio button (multiple choice)</option>
                            </select>
                        </div>
                        {watch("type") === "radio" &&
                            fields &&
                            fields.map((answer, i) => (
                                <div className="form__row flexOneLine" key={answer.id}>
                                    <div className={errors?.options && errors?.options[i]?.label_en ? "flex250 errorField" : "flex250"}>
                                        <label htmlFor={`options.${i}.label_en`} className="form__label">
                                            <b>Answer {i + 1} - English</b>
                                        </label>

                                        <input type="text" className="field" placeholder="Answer" id="message" {...register(`options.${i}.label_en`, { required: true })} />
                                    </div>
                                    <div className={errors?.options && errors?.options[i]?.label_fr ? "flex250 errorField" : "flex250"}>
                                        <label htmlFor="option" className="form__label">
                                            <b>Answer {i + 1} - French</b>
                                        </label>

                                        <input type="text" className="field" placeholder="Answer" id="message" {...register(`options.${i}.label_fr`, { required: true })} />
                                    </div>
                                    <div className="absolute-order-controls">
                                        <button
                                            className="btn-icon"
                                            type="button"
                                            onClick={() => {
                                                remove(i);
                                            }}
                                            disabled={fields.length <= 2}>
                                            <img src="\assets\images\icons\icon-minus-circle.svg" alt="update" />
                                        </button>
                                        <button
                                            className="btn-icon"
                                            type="button"
                                            disabled={fields.length >= 4}
                                            onClick={() => {
                                                append({ label_en: "", label_fr: "", id: crypto.randomUUID() });
                                            }}>
                                            <img src="\assets\images\icons\icon-plus-circle.svg" alt="update" />
                                        </button>
                                    </div>
                                </div>
                            ))}

                        <br />
                        <br />
                        <div className="flex-gap">
                            <Link className="btn__cancel button--small" to="/survey">
                                Back
                            </Link>
                            <button className="btn button--small" type="submit">
                                Save changes
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddSurveyQuestion;
