import { Fragment, useEffect, useMemo, useState } from "react";
import Button from 'react-bootstrap/Button';
import { Control, FieldErrors, UseFormRegister, useFieldArray, useForm } from "react-hook-form";
import { drop, dropWhile, pullAt, slice, remove as removeElement } from "lodash";
import { campaignClient } from "../../api";
import { QueryClient, useQuery, useQueryClient } from "react-query";
import { showToast } from "../../Components/Toast/ToastManager";
import { PageLoader } from "../../Components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AddQuestionArgs, Option, Question, TriviaQuestions } from "../../helpers/types";
import { getQuestion, questionAdaptor } from "../../helpers/utils";
import { MESSAGES } from "../../helpers/constants";
import { QuizForm } from "./QuizForm";


function AddTriviaQuestion() {

    const quizParams = useParams();

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const quiz = quizParams.quiz ? decodeURI(quizParams.quiz) : "";

    const initialQuiz = Array.from(Array(2).keys()).map(() => {
        return getQuestion()
    })

    const {
        isLoading,
        data: questions,        
    } = useQuery(["getQuiz", quiz], async () => {
        const res = await campaignClient.call<{questions: AddQuestionArgs[]}>("getQuiz", { quiz });
        return res.questions ? res.questions.map(q=>questionAdaptor(q)) : [];
    },
    {refetchOnWindowFocus: false}     

    );
    const quizId = questions && questions.length > 0 ? questions[0].quiz : quiz;

    const quizName = questions && questions.length > 0 ? questions[0].quizName : "";    

    if (isLoading || !questions) {
        return <PageLoader />
    }

    return <>
        <div className="main__head">
            <div className="row">
                <div className="col-auto">
                <div className="inline-block">
          <a
            href="#"
            className="btn-back"
            onClick={(e) => {
              e.preventDefault();
              navigate("/trivia");
            }}>
            <svg className="ico-arrow">
              <image xlinkHref="/assets/images/svg/ico-arrow.svg"></image>
            </svg>
          </a>
        </div>  
                </div>
                <div className="col-auto">
                <h2 className="main__title">{questions && questions.length > 0 ? 'Edit a quiz' : 'Add a new quiz'}</h2>
                </div>
            </div>
            
        </div>
       
        <QuizForm FormValues={{
            quiz: questions && questions.length > 0 ? questions : initialQuiz,
            quizName,
            quizId: quizId
        }} 
        queryClient= { queryClient }
        />
    </>
}



export default AddTriviaQuestion;
