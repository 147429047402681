import { Link } from "react-router-dom";
import { Row, TableProps } from "react-table";
import dayjs from "dayjs";
import { campaignClient } from "../../api";
import DownloadImage from "../../Components/DownloadImage";

export const DreamChampionColumns = ({ updateStatus, downloadImage }: { updateStatus: Function, downloadImage: Function }) => {

    const handleDownload = (imageName: string, imageUrl: string) => {
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = imageName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const COLUMNS = [
        {
            Header: "No.",
            accessor: "colNumber",
            width: "1%",
            Cell: (TableInfo: TableProps) => {
                const nmb = TableInfo.row.index + 1;
                return nmb;
            },
        },
        {
            Header: "Create Time",
            sortType: "datetime",
            accessor: "creationTime",
            Cell: (TableInfo: TableProps) => {
                const dateString = TableInfo.data[TableInfo.row.index].creationTime ? TableInfo.data[TableInfo.row.index].creationTime.toLocaleString() : "NA";
                return dateString;
            },
        },

        {
            Header: "First Name",
            accessor: "firstName",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].firstName ? (
                    <>
                        {TableInfo.data[TableInfo.row.index].firstName}
                    </>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Last Name",
            accessor: "lastName",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].lastName ? (
                    <>
                        {TableInfo.data[TableInfo.row.index].lastName}
                    </>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Email Address",
            accessor: "email",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].email ? (
                    <>
                        {TableInfo.data[TableInfo.row.index].email}
                    </>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Weekly Challenge Number (1-6)",
            accessor: "weekNo",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].weekNo ? (
                    <>
                       Week {TableInfo.data[TableInfo.row.index].weekNo}
                    </>
                ) : (
                    "N/A"
                );
            }
        },
        {
            Header: "Photo",
            accessor: "image",
            width: "15%",
            Cell: (TableInfo: TableProps) => {
                let photo = TableInfo?.data[TableInfo.row.index].photo
                let fileName = TableInfo?.data[TableInfo.row.index].fileName
                return <img src={photo ? photo : "/assets/images/empty.png"} width="100" alt={fileName} />;
            },
        },
        {
            Header: "Visible in carousel",
            accessor: "visible",
            width: "5%",
            Cell: (TableInfo: TableProps) => {
                const isVisible = +TableInfo.data[TableInfo.row.index].status === 1;
                const id = TableInfo.data[TableInfo.row.index].id
                const sessionKey = TableInfo.data[TableInfo.row.index].sessionKey
                return (
                    <div className="textCenter">
                        <button className={isVisible ? "btn-approve" : "btn-decline"} onClick={() => { isVisible ? updateStatus(id, sessionKey, "0") : updateStatus(id, sessionKey, "1") }}>
                            <img src={!isVisible ? "/assets/images/icons/eye-off.svg" : "/assets/images/icons/eye.svg"} alt="visible" />
                        </button>
                    </div>
                );
            },
        },

        {
            Header: " ",
            accessor: "actions",
            width: "20%",
            Cell: (TableInfo: TableProps) => {
                let photo = TableInfo?.data[TableInfo.row.index].photo
                let img = document.createElement('img');
                img.src = photo;
                let fileName = TableInfo?.data[TableInfo.row.index].fileName
    
                return (

                    <DownloadImage imageUrl={photo} imageName={fileName} imageKey ={TableInfo?.data[TableInfo.row.index].imageKey} />

                );
            },
        },
    ];
    return COLUMNS;
};
