import { Row, TableProps } from "react-table";
import { AddQuestionArgs } from "../../helpers/types";
import dayjs from "dayjs";
import { Button } from "react-bootstrap";
import { ESurveyWinnerStatus } from "../../helpers/constants";

function multiSelectFilter(rows:any, columnIds:any, filterValue:string[]) { 
   
    return filterValue.length === 0
      ? rows
      : rows.filter((row:any) => {        
        return filterValue.some(v=>row.original[columnIds[0]].includes(v))
      });
  }

export const ResultColumns = ({questions, selectMutation}:{questions: AddQuestionArgs[], selectMutation: Function}) => {

    const COLUMNS = [
        {
            Header: "No.",
            accessor: "colNumber",
            width: "1%",
            Cell: (TableInfo: TableProps) => {
                const nmb = TableInfo.row.index + 1;
                return nmb;
            },
        },

        {
            Header: "First Name",
            accessor: "firstName",
            // Cell: (TableInfo: TableProps) => {
            //     return TableInfo.data[TableInfo.row.index].firstName ? (
            //         <a className="link-more link-more--alt" href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            //             {TableInfo.data[TableInfo.row.index].firstName}
            //         </a>
            //     ) : (
            //         "N/A"
            //     );
            // },
        },
        {
            Header: "Last Name",
            accessor: "lastName",
            // Cell: (TableInfo: TableProps) => {
            //     return TableInfo.data[TableInfo.row.index].lastName ? (
            //         <a className="link-more link-more--alt" href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            //             {TableInfo.data[TableInfo.row.index].lastName}
            //         </a>
            //     ) : (
            //         "N/A"
            //     );
            // },
        },
        {
            Header: "Email Address",
            accessor: "email",
            // Cell: (TableInfo: TableProps) => {
            //     return TableInfo.data[TableInfo.row.index].email ? (
            //         <a className="link-more link-more--alt" href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            //             {TableInfo.data[TableInfo.row.index].email}
            //         </a>
            //     ) : (
            //         "N/A"
            //     );
            // },
        },
        {
            Header: "Submit Time",
            accessor: "submitTime",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].submitTime ? dayjs(TableInfo.data[TableInfo.row.index].submitTime).format("MM/DD/YYYY, hh:mm A") : "";
            },
        },
        {
          Header: "Store number",
          accessor: "storeNo",        
      },
        {
            Header: "Employee number",
            accessor: "employeeNo",           
        },
        {
            Header: "Location",
            accessor: "location",          
        },
        {
            Header: "Backstage Office",
            accessor: "office",           
        },       
        {
            Header: "Empire Store Number",
            accessor: "empireStore",
          
        },
        {
            Header: "Empire Banner",
            accessor: "empireBanner",
           
        },
        {
            Header: "Empire Job Title",
            accessor: "empireJob",
         
        },
        {
            Header: "Canadian passport",
            accessor: "passport",
            
        },
        ...questions.map((q, i)=>{
          
            return {
                Header: `${q.question.en}`,
                accessor: `${q.id}`,
                Cell: (TableInfo: TableProps) => {                 

                if (q.type === "radio"){ 
                    
                    const answer = q.options.find(o=>o.id === TableInfo.data[TableInfo.row.index].answers[q.id])                 
                   
                    return answer ? answer.label_en : "";

                }else{
                    return `${TableInfo.data[TableInfo.row.index].answers[questions[i].id] ? TableInfo.data[TableInfo.row.index].answers[questions[i].id] : ""}`
                }
                   
                },
            }
        }),
        {
            Header: "Potential winners selection",
            accessor: "sessionKey",
              Cell: (TableInfo: TableProps) => {

                if (TableInfo.data[TableInfo.row.index].reject){
                    return <></>
                }
                return <div className="d-flex gap-1">
                    {
                        (TableInfo.data[TableInfo.row.index].status && +TableInfo.data[TableInfo.row.index].status === 1) ? <Button className="btn btn--small btn__cancel fs-6"
                        onClick={()=>{ selectMutation(TableInfo.data[TableInfo.row.index].sessionKey, ESurveyWinnerStatus.decline) }}
                        >Decline</Button> 
                        : <Button className="btn btn--small fs-6" 
                        onClick={()=>{ selectMutation(TableInfo.data[TableInfo.row.index].sessionKey, ESurveyWinnerStatus.potential) }}>Approve</Button>
                    }
                    
                    
                </div>
            },

        },
        {
            Header: "Potential winners selection",
            accessor: "answerForSearch",
            filter: multiSelectFilter    
        }
    ];
    return COLUMNS;
};
