import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { campaignClient } from "../../api";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Graph } from "../../Components";
import dayjs from "dayjs";
import { getDaysBetween2Dates, getPastMonths } from "../../helpers/utils";


import axios from "axios";
import EnablePages from "./EnablePages";


type Inventory = {
    campaignKey: string;
    giftCardTypeKey?: string;
    prizeKey?: string;
    prizePoolKey?: string;
    value: number;
    unassignedCount: number;
    assignedCount: number;
    prizeName: string;
    description: string;
    inventoryCount?: number;
};

type InventoryReturn = {
    instantWinsInventory: Inventory[];
    grandPrizeInventory: Inventory[];
};

interface PrizeReport extends MainDbReturnTypes.Prize {
    totalWon: number;
    fulfilled: number;
    claimed: number;
    forfeited: number;
}
function Overview() {
    const [selectedmonths, setSelectedmonths] = useState(2);
    const [loading, setLoading] = useState(false);
    const { isLoading: isLoadingParticipants, data: participants } = useQuery("getParticipants", async () => {
        const res: any = await campaignClient.call("getParticipants", {});
        let participants;
        if (res.participants) {
            participants = res.participants;
        }
        if (res.result) {
            participants = res.result.participants;
        }

        if (res.largeResultUrl) {
            const result = await axios(res.largeResultUrl);
            const response: MainDbReturnTypes.Participant[] = await result.data.result.participants;
            participants = response;
        }
        return participants;
    });

    const { isLoading: isLoadingPrizes, data: prizes } = useQuery("getPrizes", async () => {
        const res: MainDbReturnTypes.Prizes = await campaignClient.call("getPrizes", {});

        return res.prizes;
    });

    const { isLoading: isLoadingWinners, data: winners } = useQuery("getPrizeWinners", async () => {
        const res: MainDbReturnTypes.PrizeWinners = await campaignClient.call("getPrizeWinners", {});

        return res.prizeWinners;
    });
    

    const prizeStats = useMemo(() => {
        let prizesFinal: PrizeReport[] = [];

        if (winners && prizes) {            
            prizes.length > 0 &&
                prizes.forEach((prize) => {    
                    const prizeStats = {
                        ...prize,
                        totalWon: 0,
                        fulfilled: 0,
                        claimed: 0,
                        forfeited: 0,
                    };

                    winners.forEach(w=>{

                        if (w.prizeKey === prize.prizeKey && !w.forfeitTime){
                            prizeStats.totalWon++;
                        }
                        
                        if (w.prizeKey === prize.prizeKey && w.declarationAndRelease){
                            prizeStats.claimed++;
                        }

                        if (w.prizeKey === prize.prizeKey && w.forfeitTime){
                            prizeStats.forfeited++;                        
                        }

                    })                  

                    prizesFinal.push({
                        ...prizeStats,                       
                        fulfilled: prizeStats.claimed, //most of the case claimed and fulfilled are same number                     
                       
                    });
                });
        }

        return prizesFinal;
    }, [winners, prizes]);

    const data = useMemo(() => {
        if (participants && participants.length > 0) {
            return participants.map((participant: MainDbReturnTypes.Participant) => {
                return {
                    firstName: participant.firstName,
                    lastName: participant.lastName,
                    email: participant.email,
                    creationTime: new Date(participant.creationTime),
                    updateTime: new Date(participant.updateTime),
                    sessionKey: participant.sessionKey,
                    rules: participant.metadata.rules ? participant.metadata.rules.toLocaleString() : "NA",
                    optin1: participant.metadata.optin1 ? participant.metadata.optin1.toLocaleString() : "NA",
                    age: participant.metadata.age ? participant.metadata.age.toLocaleString() : "NA",
                };
            });
        }
    }, [participants]);

    const graphLabels = useMemo(() => {
        if (data) {
            if ([12, 6, 3].includes(+selectedmonths)) {
                const xlables = getPastMonths(+selectedmonths).reverse();
                const ylables = xlables.map((m) => data.filter((p: any) => dayjs(p.creationTime).format("MMM YY") === m).length);
                return { x: xlables, y: ylables };
            } else {
                const endDate = dayjs().toISOString();
                let startDate = dayjs().subtract(7, "day").toISOString();
                if (selectedmonths === 1) {
                    startDate = dayjs()
                        .set("month", dayjs().get("month") - 1)
                        .toISOString();
                }

                if (selectedmonths === 2) {
                    startDate = dayjs().subtract(14, "day").toISOString();
                }
                const xlables = getDaysBetween2Dates(startDate, endDate).map((date) => dayjs(date).format("MMM DD"));
                const ylables = xlables.map((m) => data.filter((p: any) => dayjs(p.creationTime).format("MMM DD YYYY") === m + " " + dayjs().year()).length);

                return { x: xlables, y: ylables };
            }
        }
    }, [selectedmonths, data]);

    const { isLoading: isLoadingInventory, data: inventory } = useQuery("getInventory", async () => {
        const res: InventoryReturn = await campaignClient.call("getInventory", {});
        return res;
    });

    if (        
        isLoadingParticipants ||
        // !data ||
        isLoadingPrizes ||
        // !prizeStats ||
        isLoadingWinners ||
        isLoadingInventory
    ) {
        return <PageLoader />;
    }

    return (
        <>
            <div className="main__head">
                <h2 className="main__title">Overview</h2>
            </div>
            <div className="main__body main__body--flex main__body--flex-alt">
                {!isLoadingParticipants && !!participants?.length  ? (
                    <>
                        <div className="boxes-info">
                            <ul>
                                <li>
                                    <div className="info-box">
                                        <p>Total entries</p>
                                        <h1>{ participants.length }</h1>
                                    </div>
                                </li>

                                {prizeStats &&
                                    prizeStats.map((v, i) => {
                                        return (
                                            <li key={v.prizeKey}>
                                                <div className="info-box">
                                                    <h4>{v.prizeName}</h4>
                                                    <p>
                                                        {inventory?.grandPrizeInventory[i]?.inventoryCount} total {v.prizeName}{" "}
                                                    </p>
                                                    <p>{v.totalWon} prizes won</p>
                                                    <p>{v.claimed - v.fulfilled} prizes claimed</p>
                                                    {/* <p>{v.fulfilled} prizes fulfilled</p> */}
                                                    {/* <p>{v.totalWon - v.claimed} prizes unclaimed</p> */}
                                                    {/* <p>{v.forfeited} prizes forfeited</p> */}
                                                </div>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                        <br />
                        <div className="chart">
                            <select
                                className="form-control"
                                value={selectedmonths}
                                onChange={(e) => {
                                    setSelectedmonths(+e.currentTarget.value);
                                }}>
                                <option value="12">12 months</option>
                                <option value="6">6 months</option>
                                <option value="3">3 months</option>
                                <option value="1">1 month</option>
                                <option value="2">2 weeks</option>
                                <option value="0">1 week</option>
                            </select>

                            <div className="graph">{graphLabels && <Graph graphLabels={graphLabels} selectedmonths={selectedmonths} />}</div>
                        </div>
                    </>
                ) : (
                    <p>Currently there are no any stats to display.</p>
                )}

                <div className="boxes-info ms-4"><EnablePages /></div>
     
            </div>
        </>
    );
}

export default Overview;
