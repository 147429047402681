import { Flex } from '@aws-amplify/ui-react';
import React, { useCallback, useEffect } from 'react'

const Pagination = ({ pageChangeHandler, totalRows, rowsPerPage, currentPage, setCurrentPage }: { pageChangeHandler: Function, totalRows: number, rowsPerPage: number, currentPage: number, setCurrentPage: Function }) => {

    const noOfPages = Math.ceil(totalRows / rowsPerPage);

    const pagesArr = [...new Array(noOfPages || 1)];

    const onNextPage = () => setCurrentPage(currentPage + 1);
    const onPrevPage = () => setCurrentPage(currentPage - 1);
    const onPageSelect = (pageNo: number) => setCurrentPage(pageNo);

    useCallback(
        () => {
            pageChangeHandler(currentPage)
        },
        [currentPage, pageChangeHandler],
    )


    return (
        <>
            {noOfPages > 1 ? (

                <div className='paginationCont' style={{ border: "none", textAlign: 'center', display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className="pagination">
                        <li className="page-item">
                            <button
                                className="page-link"
                                onClick={onPrevPage}
                                disabled={currentPage === 1 ? true : false}
                            >
                                &#8249;
                            </button></li>
                        {pagesArr.map((num, index) => (
                            <li className={` ${index + 1 === currentPage ? "page-item active" : "page-item"
                                }`} key={index}>
                                <button
                                    key={index}
                                    onClick={() => onPageSelect(index + 1)}
                                    className={`page-link `}
                                >
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                        <li className="page-item">

                            <button
                                className="page-link"
                                onClick={onNextPage}
                                disabled={noOfPages === currentPage ? true : false}
                            >
                                &#8250;
                            </button>
                        </li>
                    </div>
                    {/* </div> */}
                </div>

            ) : null}
        </>
    )
}


export default Pagination