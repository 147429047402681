import { Link } from "react-router-dom";
import { TableProps } from "react-table";
import dayjs from "dayjs";
import { EquestionStatus } from "../../helpers/constants";

export const questionsColumns = ({ updateStatus, setDeleteQuestion, maxOption }: { setDeleteQuestion: Function; updateStatus: Function, maxOption: number }) => {
    const COLUMNS = [
        {
            Header: "#",
            accessor: "q_seq",
            width: "3%",
        },
        {
            Header: "Visible",
            accessor: "visible",
            width: "5%",
            Cell: (TableInfo: TableProps) => {
                const isVisible = TableInfo.data[TableInfo.row.index].status + "" === EquestionStatus.visible;
                return TableInfo.data[TableInfo.row.index].status ? (
                    <div className="textCenter">
                        <button
                            type="button"
                            className={isVisible ? "btn-approve" : "btn-decline"}
                            onClick={() => updateStatus({ question: TableInfo.data[TableInfo.row.index], status: isVisible ? "0" : "1" })}>
                            <img src={!isVisible ? "/assets/images/icons/eye-off.svg" : "/assets/images/icons/eye.svg"} alt="visible" />
                        </button>
                    </div>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Question",
            accessor: "question",
            with: "30%",
            Cell: (TableInfo: TableProps) => {
                const en = TableInfo.data[TableInfo.row.index].question.en ?? "N/A";
                const fr = TableInfo.data[TableInfo.row.index].question.fr ?? "N/A";

                return TableInfo.data[TableInfo.row.index].status ? (
                    <div className="">
                        <p>
                            EN: {en}
                            <br />
                            FR: {fr}
                        </p>
                    </div>
                ) : (
                    "N/A"
                );
            },
        },

        ...Array(maxOption)
            .fill("_")
            .map((_, i) => {
                return {
                    Header: `Answer ${i + 1}`,
                    accessor: "options " + i,
                    with: "8%",

                    Cell: (TableInfo: TableProps) => {
                        //console.log("!!!", i, TableInfo.row.index, TableInfo.data[TableInfo.row.index])

                        const optionId = TableInfo.data[TableInfo.row.index]?.options?.[i] && TableInfo.data[TableInfo.row.index]?.options?.[i].id ? TableInfo.data[TableInfo.row.index]?.options?.[i].id : ""
                        const isValid = TableInfo.data[TableInfo.row.index]?.options?.length >= i + 1;
                        const en = isValid ? TableInfo.data[TableInfo.row.index]?.options?.[i].label_en : "N/A";
                        const fr = isValid ? TableInfo.data[TableInfo.row.index]?.options?.[i].label_fr : "N/A";

                        return (
                            <div 
                            key={optionId}
                            className={isValid && optionId === TableInfo.data[TableInfo.row.index].answer ? "green" : ""}>
                                <p>
                                    EN: {en}
                                    <br />
                                    FR: {fr}
                                </p>
                            </div>
                        );
                    },
                };
            }),

        // {
        //     Header: "Type",
        //     accessor: "type",
        //     width: "8%",
        // },
        {
            Header: "Modified",
            accessor: "updateTime",
            width: "8%",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].updateTime ? dayjs(TableInfo.data[TableInfo.row.index].updateTime).format("MMMM DD, YYYY") : "N/A";
            },
        },

        {
            Header: " ",
            accessor: "actions",
            width: "15%",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].statsType ? (
                    <div className="column-icons" style={{gap: "2rem"}}>
                        <button className="btn-icon" onClick={() => setDeleteQuestion({ question: TableInfo.data[TableInfo.row.index], isOpen: true })}>
                            <img src="/assets/images/icons/trash.svg" alt="delete" />
                        </button>
                        <Link
                            className="btn-icon textCenter"
                            to={`/trivia/question/${TableInfo.data[TableInfo.row.index].quiz}/${TableInfo.data[TableInfo.row.index].id}`}
                            >
                            <img src="/assets/images/icons/udate.svg" alt="update" />
                        </Link>
                    </div>
                ) : (
                    "N/A"
                );
            },
        },
    ];
    return COLUMNS;
};
