import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import CustomLink from "../CustomLink";

function SideBar() {
    const [profile, setProfile] = useState("");

    const getProfile = async () => {
        let user = await Auth.currentAuthenticatedUser();
        setProfile(user.attributes.profile);
    };

    useEffect(() => {
        getProfile();
    }, []);

    return (
        <>
            <nav className="nav sidebar__nav">
                <ul>
                    {profile === "Standard" || profile === "Admin" ? (
                        <CustomLink to="overview">
                            <svg className="ico-bolt" viewBox="0 0 14 16" width="50">
                                <use xlinkHref="/assets/images/svg/sprite.svg#ico-bolt" />
                            </svg>
                            <span>Overview</span>
                        </CustomLink>
                    ) : (
                        <></>
                    )}

                    {profile === "Standard" || profile === "Admin" ? (
                        <CustomLink to="participants">
                            <svg className="ico-user">
                                <use xlinkHref="/assets/images/svg/sprite.svg#ico-user" />
                            </svg>
                            <span>Participants</span>
                        </CustomLink>
                    ) : (
                        <></>
                    )}

                    <CustomLink to="winners">
                        <svg className="ico-star">
                            <use xlinkHref="/assets/images/svg/sprite.svg#ico-star" />
                        </svg>
                        <span>Winners</span>
                    </CustomLink>

                    <CustomLink to="trivia">
                        <div className="sideBar_link">
                            <img className="icon ico-inventory " src="/assets/images/icons/trivia.svg" alt="trivia-ico" />
                            <span>Trivia</span>
                        </div>
                    </CustomLink>
                    <CustomLink to="messages">
                        <div className="sideBar_link">
                            <img className="icon ico-inventory " src="/assets/images/icons/message.svg" alt="message-ico" />
                            <span>Messages</span>
                        </div>
                    </CustomLink>
                    <CustomLink to="survey">
                        <div className="sideBar_link">
                            <img className="icon ico-inventory " src="/assets/images/icons/survey.svg" alt="survey-ico" />
                            <span>Correspondent Program</span>
                        </div>
                    </CustomLink>

                    {/* <CustomLink to="events">
                        <div className="sideBar_link">
                            <img className="icon ico-inventory " src="/assets/images/icons/video.svg" alt="event-ico" />
                            <span>Live events</span>
                        </div>
                    </CustomLink> */}

                    <CustomLink to="rules">
                        <div className="sideBar_link">
                            <img className="icon ico-inventory " src="/assets/images/icons/rules.svg" alt="rules-ico" />
                            <span>Contest rules</span>
                        </div>
                    </CustomLink>
                    <CustomLink to="spotlights">
                        <div className="sideBar_link">
                            <img className="icon ico-inventory " src="/assets/images/icons/spotlight.svg" alt="spotlight-ico" />
                            <span>Athlete spotlights</span>
                        </div>
                    </CustomLink>
                    <CustomLink to="dreamChampion">
                        <div className="sideBar_link">
                            <img className="icon ico-inventory " src="/assets/images/icons/icon-image.svg" alt="survey-ico" />
                            <span>Dream champion</span>
                        </div>
                    </CustomLink>
                    <CustomLink to="weeklyChallenge">
                        <div className="sideBar_link">
                            <img className="icon ico-inventory " src="/assets/images/icons/icon-image.svg" alt="survey-ico" />
                            <span>Weekly challenge</span>
                        </div>
                    </CustomLink>
                    <CustomLink to="scrapBook">
                        <div className="sideBar_link">
                            <img className="icon ico-inventory " src="/assets/images/icons/icon-image.svg" alt="survey-ico" />
                            <span>Scrapbook</span>
                        </div>
                    </CustomLink>

                    {/* {profile === "Admin" ? (
                        <CustomLink to="manage">
                            <svg className="ico-account2">
                                <use xlinkHref="/assets/images/svg/sprite.svg#ico-account2" />
                            </svg>
                            <span>User Management</span>
                        </CustomLink>
                    ) : (
                        <></>
                    )} */}
                </ul>
            </nav>
        </>
    );
}

export default SideBar;
