import React, { useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { campaignClient } from "../../api";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../Components";
import { DreamChampionColumns } from "./ChampionCol";
import { showToast } from "../../Components/Toast/ToastManager";
import { Tab, Tabs } from "react-bootstrap";
import { byKey } from "../../helpers/utils";
import axios from "axios";
import Pagination from "../../Components/Pagination";
import { CSVLink } from "react-csv";
import "./index.css"
import { ChallengeReturn, WeeklyChallengeReturn } from "../../helpers/types";
import { concat, slice } from "lodash";
import { Participant } from "@sprycore/spry-api-client/dist/MainDbReturnTypes";


type ChallengesType = "dream" | "weekly";

const Champion = ({ type }: { type: ChallengesType }) => {

    const [key, setKey] = useState("photos");
    const inputEl = useRef<HTMLInputElement | null>(null);
    const [uploading, setUploading] = useState(false);
    const [selectedRows, setSelectedRows] = React.useState({});
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [filterValue, setFilterValue] = useState("")
    const [filterTable, setFilterTable] = useState("")

    const searchRef = useRef<HTMLInputElement | null>(null)

    const { isLoading: isLoadingParticipants, data: participants } = useQuery("getParticipants", async () => {
        const res: { participants: Participant[], largeResultUrl?: string, result?: { participants: Participant[] } } = await campaignClient.call("getParticipants", {});
        let participants;
        if (res.participants) {
            participants = res.participants;
        }
        if (res.result) {
            participants = res.result.participants;
        }

        if (res.largeResultUrl) {
            const result = await axios(res.largeResultUrl);
            const response: Participant[] = await result.data.result.participants;
            participants = response;
        }
        return participants;
    });

    const {
        isLoading: isloadingChallenges,
        data: challenges,
        refetch,
    } = useQuery(["getAllChallenges", { type }], async () => {

        let temp_challenges: ChallengeReturn[] = []
        let token
        do {
            const res = await campaignClient.call<{ result: ChallengeReturn[], continuationToken?: string }>("getAllChallenges", { type });
            if (res.result) {
                temp_challenges = concat(temp_challenges, res.result)
            }
            if (res.continuationToken) {
                token = res.continuationToken || ""
            }
        } while (token)
        return temp_challenges;
    }, { refetchOnWindowFocus: false, enabled: !!type });


    const filteredChallenges = useMemo(() => {

        if (participants && challenges && challenges.length > 0) {

            const participantsByKey = byKey(participants, (p) => p.sessionKey)

            if (filterTable) {
               

                const filtered = participants.filter(p => p.email?.includes(filterTable))

                const challenges_sessionKey = byKey(challenges, (p: any) => p.sessionKey)

                const filteredChallenges = filtered.map(f => {
                    const f_challenges = challenges_sessionKey[f.sessionKey]
                    return f_challenges
                })


                return filteredChallenges.flat().filter(m => m!).map(k => { return { ...k, participant: participantsByKey[k.sessionKey] ? participantsByKey[k.sessionKey][0] : null } })
            }
            else {
                return challenges.map(ch => { return { ...ch, participant: participantsByKey[ch.sessionKey] ? participantsByKey[ch.sessionKey][0] : null } })
            }
        }
    }, [filterTable, participants, challenges])



    const slicedData = useMemo(() => {

        if (filteredChallenges && filteredChallenges.length > 0) {

            const sortedChallenges = filteredChallenges.sort((a: { creationTime: string }, b: { creationTime: string }) => new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime())

            let selectedChallenges = sortedChallenges

            if (currentPage > 0) {

                return slice(selectedChallenges, (currentPage - 1) * 6, (currentPage - 1) * 6 + 6)
                //const display: any[] = selectedChallenges.slice((+currentPage - 1) * 6)
                //return display.map(ch => { return { ...ch } })
            }
            else {
                return selectedChallenges.slice(0, 6).map(ch => { return { ...ch } })
            }

        }
        else {
            return []
        }

    }, [filteredChallenges, currentPage])

    const {
        isLoading: loadingImages,
        data: images,

    } = useQuery(["getImages", slicedData], async () => {
        const res = await Promise.all(slicedData.map(async ch => {

            const url = await downloadImage(ch.key)

            return { key: ch.key, url: url }
        }))

        return res
    }, { enabled: !!slicedData.length });


    const data = useMemo(() => {
        if (slicedData.length > 0 && images) {

            return slicedData.map((challenge) => {

                const participant = (challenge as ChallengeReturn & { participant: Participant })?.participant

                return {
                    lastName: participant?.lastName || "",
                    firstName: participant?.firstName || "",
                    email: participant?.email || "",
                    creationTime: new Date(challenge.creationTime) || "",
                    sessionKey: challenge.sessionKey || "",
                    imageKey: challenge.key || "",
                    photo: images.find(i => i.key === challenge.key)?.url,
                    id: challenge.id || "",
                    status: challenge.status || "",
                    fileName: challenge.fileName || "download.jpg",
                    weekNo: challenge.weekNo || ""

                }
            })
        }
    }, [slicedData, images]);

    const reportData = useMemo(() => {
        if (challenges && participants) {
            const participantsByKey = byKey(participants, (p) => p.sessionKey)

            return challenges.map((ch) => {

                let challenge = ch as WeeklyChallengeReturn & { participant: Participant }


                const rp = participantsByKey[ch.sessionKey][0]
                const result: { [key: string]: string } = {
                    "Creation Time": new Date(ch.creationTime).toLocaleString(),
                    "First Name": rp?.firstName || "",
                    "Last Name": rp?.lastName || "",
                    "Email": rp?.email || "",
                    status: +ch.status ? "Visible" : "Not-visible"
                };

                if (type == "weekly") {
                    result["Manager first name"] = challenge.m_firstName || ""
                    result["Manager last name"] = challenge.m_lastName || ""
                    result["Manager email"] = challenge.m_email || ""
                    result["Manager phone number"] = challenge.m_phone || ""
                    result["Banner"] = challenge.banner || ""
                    result["Store number"] = rp && rp.metadata && rp.metadata?.storeNo || ""
                    result["Weekly Challenge Number (1-6)"] = challenge.weekNo || ""
                }
                return result
            });
        }
    }, [challenges, participants]);

    const handleSelection = React.useCallback((value: any) => {
        setSelectedRows(value);
    }, []);

    const updateStatus = async (id: string, sessionKey: string, status: string) => {

        await campaignClient.call("updateChallenge", { id, sessionKey, status, type })
        refetch()
        showToast({
            content: "Status updated.",
            duration: 3000,
            error: false,
        });

    };

    const pageChangeHandler = async (currentPage: number) => {
        setCurrentPage((currentPage - 1) * 6)
    }

    const dreamChColumn = DreamChampionColumns({ updateStatus, downloadImage });
    const hiddenColumnsArray = type !== "weekly" ? ["weekNo"] : []


    if (uploading || isLoadingParticipants || loadingImages || isloadingChallenges) {
        return <PageLoader />;
    }


    return (
        <>
            <div className="main__head" style={{ display: "flex", justifyContent: "space-between", padding: "2rem", alignItems: "center" }}>
                <h2 className="main__title">{type === "dream" ? "Dream champion submissions" : "Weekly challenge submissions"}</h2>
                <div className="flex" style={{ alignItems: "stretch", alignContent: "center", justifyContent: "center", gap: "5px" }}>
                    <div className="search search--alt search--medium main__search" style={{}}>
                        <div className="search__row">
                            <label htmlFor="q" className="hidden">
                                Search
                            </label>
                            <input
                                type="search"
                                name="q"
                                id="q"
                                value={filterValue}
                                placeholder="Search by email"
                                className="search__field"
                                onChange={(e) => { e.preventDefault(); setFilterValue(e.target.value) }}
                                ref={searchRef}
                            />
                            <button type="button"
                                className="btn btn--small search__btn_input"
                                onClick={() => { setFilterTable(filterValue) }
                                }>
                                Search
                            </button>

                        </div>
                    </div>
                    <CSVLink
                        filename={`Campaign-${type}-Report-${new Date().toLocaleDateString()}`}
                        className="btn btn--small btn--mobile-small csv-btn"
                        data={reportData ? reportData : ""}
                        asyncOnClick={true}
                        target="_blank">
                        Download Report
                    </CSVLink>

                </div>
            </div>


            <div className="main__body main__body--flex main__body--flex-alt">
                <div className="tabs__body">




                    <div className="section__body">
                        <div className="table">

                            <br />


                            {data && data?.length ? (<>
                                <Table
                                    columns={dreamChColumn}
                                    data={data}
                                    tablePageSize={6}
                                    // filterValue={filterTable}
                                    onSelectedRowsChange={handleSelection}
                                    hiddenColumnsArray={hiddenColumnsArray}
                                />
                                {challenges &&
                                    <Pagination pageChangeHandler={pageChangeHandler} totalRows={filterTable ? slicedData.length : challenges?.length} rowsPerPage={6} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                }

                                {/* {noofPages?.map((p, i) => <ul><li><button onClick={() => { setCurrentPage(i + 1) }}>{i + 1}</button></li></ul>)} */}

                            </>

                            ) : (
                                <p style={{ textAlign: "center", paddingTop: "4rem" }}>There are no records to display</p>
                            )}
                        </div>
                    </div>
                    <div className="section__actions">
                        <div className="paging">{/* <ReactPaginate /> */}</div>
                    </div>



                </div>
            </div>
        </>
    );
};

export const downloadImage = async (key: string) => {

    const res: any = await campaignClient.call("downloadUserFile", { key: key })
    return res.downloadUrls[key]

}

export default Champion;
