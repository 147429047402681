import React, { useCallback, useMemo, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import * as XLSX from 'xlsx';
import { campaignClient } from "../../api";
import { Participants, Participant, PrizeWinners } from "@sprycore/spry-api-client/dist/MainDbReturnTypes"
import { PageLoader, Table } from "../../Components";
import { questionsColumns } from "./QuestionsCol";
import { showToast } from "../../Components/Toast/ToastManager";
import { Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AddQuestionArgs } from "../../helpers/types";
import CustomModal from "../../Components/CustomModal";
import ParticipantSurvey from "./ParticipantSurvey";
import dayjs from "dayjs";
import { MultiOptionType, MultilSelect } from "../../Components/MultilSelect";
import { map, omit } from "lodash";
import { SURVEY_FILTER } from "../../helpers/constants";
import { byKey } from "../../helpers/utils";
import axios from "axios";

const Survey = () => {
    const [key, setKey] = useState("questions");

    const defaultDelete = { question: undefined, isOpen: false };

    const [selectedRows, setSelectedRows] = React.useState({});

    const [deleteQuestion, setDeleteQuestion] = useState<{ question: AddQuestionArgs | undefined; isOpen: boolean }>(defaultDelete);

    const [surveyReport, setSurveyReport] = useState<{[key:string]: string}[]>([]);

    const [filterTable, setFilterTable] = useState("");

    const [singleFilter, setSingleFilter] = useState<{[key:string]: string[]} | null>(null)

    const [multiValue, setMultiValue] = useState<MultiOptionType[]>(
        []
    );

    const { isLoading: isLoadingParticipants, data: participants } = useQuery("getParticipants", async () => {
        const res: { participants: Participant[], largeResultUrl?: string, result?: { participants: Participant[] }} = await campaignClient.call("getParticipants", {});
        let participants;
        if (res.participants) {
            participants = res.participants;
        }
        if (res.result) {
            participants = res.result.participants;
        }

        if (res.largeResultUrl) {
            const result = await axios(res.largeResultUrl);
            const response: Participant[] = await result.data.result.participants;
            participants = response;
        }
        return participants;
    }); 

    const {
        isLoading,
        data: questions,
        refetch: refetchQuestions,
    } = useQuery("getSurvey", async () => {
        const res: { survey: AddQuestionArgs[] } = await campaignClient.call("getSurvey", {});
        if (res.survey && res.survey?.length) {
            const filteredQuestions = res.survey?.sort((a: any, b: any) => a.q_seq - b.q_seq);
            return filteredQuestions;
        }
        return [];
    });



    const handleSelection = React.useCallback((value: any) => {
        setSelectedRows(value);
    }, []);

    const handleKeySelection = (key: string | null) => {
        if (key) {
            setKey(key);           
        }
    };

    const handleMultiSelect = (data:MultiOptionType[]) => {
        setMultiValue(data)

        if (data.length > 0){
            setSingleFilter({answerForSearch: map(data, "value")} )
        }else{
            setSingleFilter({answerForSearch: []})
        }
       
    }

    const updateStatus = useCallback(async ({ question, status }: { question: AddQuestionArgs; status: string }) => {

        const result = await campaignClient.call("saveQuestion", { ...question, status });
        if (result) {
            refetchQuestions();
            showToast({
                content: "Saved ",
                duration: 3000,
                error: false,
            });
        } else {
            showToast({
                content: "Try again ",
                duration: 3000,
                error: true,
            });
        }
    }, [])

    const updateSequence = useCallback(async ({ questionUp, questionDown }: { questionUp: AddQuestionArgs; questionDown: AddQuestionArgs }) => {
        const result1 = await campaignClient.call("saveQuestion", { ...questionUp, q_seq: questionUp.q_seq - 1 });
        const result2 = await campaignClient.call("saveQuestion", { ...questionDown, q_seq: questionDown.q_seq + 1 });

        if (result1 && result2) {
            refetchQuestions();
        }
    }, []);

    const surveyColumns = useMemo(()=>{
       // if (questions && questions?.length > 0){
            return questionsColumns({ updateStatus, setDeleteQuestion, updateSequence });
        //}
        
    }, [updateStatus, setDeleteQuestion, updateSequence, questions])

    const downloadFile = async () => {      

        const worksheet = XLSX.utils.json_to_sheet(map(surveyReport, function(r){ return omit(r, ['sessionKey', 'answerForSearch', 'answers', 'status', 'reject'])}));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet);
        await XLSX.writeFile(workbook, `Survey-result-${dayjs(new Date).format("YYYYMMDDhms")}.xlsx`, { compression: true });

    }

    if (isLoading || isLoadingParticipants) {
        return <PageLoader />;
    }

    return (
        <>
            <div className="main__head" style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="main__title">Correspondent Program</h2>
            </div>

            <div className="main__body main__body--flex main__body--flex-alt">
                <div className="tabs__body">
                    <Tab.Container activeKey={key} onSelect={(key) => handleKeySelection(key)}>
                        <div className="row">
                            <div className="col">
                                <Nav variant={"tabs"} className="flex nav__tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="questions">Questions</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="results">Results</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="col">

                                {key === "questions" ? <div className="d-flex justify-content-end gap-2">
                                    <button
                                        disabled={Object.keys(selectedRows).length ? false : true}
                                        className="btn__cancel button--small fs-6"
                                        onClick={async () => {
                                            if (questions && selectedRows && Object.keys(selectedRows)) {
                                                const ids = Object.keys(selectedRows);
                                                await Promise.all(
                                                    ids.map(async (r) => {
                                                        await updateStatus({ question: questions[+r], status: "2" });
                                                    })
                                                );
                                            }
                                        }}>
                                        Delete
                                    </button>
                                    <Link className="btn button--small fs-6" to="/addSurveyQuestion">
                                        Add question
                                    </Link>
                                </div> : <div className="d-flex justify-content-end gap-2">
                                <div className="col-5">
                                <MultilSelect  isMulti = {true} multiValue={multiValue} setMultiValue={handleMultiSelect} Options={SURVEY_FILTER} />
                                </div>
                                <div className="col-auto">
                                <div className="search search--alt search--medium main__search">
                                <div className="search__row">
                                <label htmlFor="q" className="hidden">
                                    Search
                                </label>

                                <input
                                    type="search"
                                    name="winner_search"
                                    id="winner_search"
                                    value={filterTable}
                                    placeholder="Search"
                                    className="search__field"
                                    onChange={(e) => setFilterTable(e.target.value)}></input>

                                <button type="submit" className="search__btn">
                                    <svg className="ico-search">
                                        <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                                    </svg>
                                </button>
                            </div> 
                                </div>
                           
                            </div>
                                    <button className="btn__cancel button--small fs-6" onClick={()=>{ downloadFile() }}>
                                        Download Full Report
                                    </button>
                                </div>
                                }

                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Tab.Content>
                                    <Tab.Pane eventKey="questions" title="Questions">
                                        <div className="section__body">
                                            <div className="table">
                                                {questions && questions?.length ? (
                                                    <Table
                                                        columns={surveyColumns}
                                                        data={questions ? questions : []}
                                                        tablePageSize={15}
                                                        checkbox={true}
                                                        onSelectedRowsChange={handleSelection}
                                                    />
                                                ) : (
                                                    <p style={{ textAlign: "center", paddingTop: "4rem" }}>There are no records to display</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="section__actions">
                                            <div className="paging">{/* <ReactPaginate /> */}</div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="results" title="Results">
                                        <div className="section__body">
                                            {participants && questions && <ParticipantSurvey                                            
                                            Participants = { participants } 
                                            Survey= {questions} 
                                            singleFilterValue={ singleFilter }
                                            filterTable = {filterTable}
                                            onHandle = {setSurveyReport} />}

                                        </div>
                                    </Tab.Pane>

                                </Tab.Content>

                            </div>
                        </div>

                    </Tab.Container>

                </div>
            </div>

            <CustomModal
                isOpen={deleteQuestion.isOpen}
                setIsOpen={() => {
                    setDeleteQuestion(defaultDelete);
                }}
                setAction={() => {
                    deleteQuestion.question && updateStatus({ question: deleteQuestion.question, status: "2" });
                }}
                actionTitle="Delete"
                message={deleteQuestion.question?.question?.en}
                loading={isLoading}
                header="Are you sure you would like to delete a question?"
            />
        </>
    );
};

export default Survey;
