import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../Components";

import { TableColumns } from "./MessagesCol";
import { campaignClient } from "../../api";
import { CSVLink } from "react-csv";
import "./index.css";
import CustomModal from "../../Components/CustomModal";
import { convertCaps, getFullName, getImage } from "../../helpers/utils";
import { showToast } from "../../Components/Toast/ToastManager";
import { useForm } from "react-hook-form";
import axios from "axios";

declare const window: Window &
    typeof globalThis & {
        vex: any;
    };
type Messages = {
    sessionKey: string;
    message: string;
    id: string;
    updateTime: string;
    entryKey: string;
    status?: string;
};
type modalType = {
    isOpen: boolean;
    title: string;
    body: string;
    action: string;
    sk: string;
    message: string;
};

function Messages() {
    const { isLoading: isLoadingParticipants, data: participants } = useQuery("getAllParticipants", async () => {
        const res: { participants: MainDbReturnTypes.Participant[]; result: { participants: MainDbReturnTypes.Participant[] }; largeResultUrl: string } = await campaignClient.call(
            "getParticipants",
            {}
        );
        let participants;
        if (res.participants) {
            participants = res.participants || res.result.participants;
        }
        if (res.result) {
            participants = res.result.participants;
        }

        if (res.largeResultUrl) {
            const result = await axios(res.largeResultUrl);
            const response: MainDbReturnTypes.Participant[] = await result.data.result.participants;
            participants = response;
        }
        return participants;
    });

    const {
        isLoading: isLoadingMessages,
        data: messages,
        refetch,
    } = useQuery("getMessages", async () => {
        const res: { messages: [] } = await campaignClient.call("getMessages", {});
        return res.messages;
    });

    const defaultFeedTheDream = {
        isOpen: false,
        title: "",
        body: "",
        message: "",
        action: "",
        sk: "",
    };
    const sendToFeedModal = {
        isOpen: true,
        title: "Send to Feed the Dream?",
        body: "Are you sure you want to send this message to Feed the Dream?",
        action: "Send message",
    };

    const defaultModalMessage = { sk: "", isActive: false, id: "" };

    const [filterTable, setFilterTable] = useState("");

    const [feedTheDream, setFeedTheDream] = useState<modalType>(defaultFeedTheDream);
    const [modalMessage, setModalMessage] = useState(defaultModalMessage);
    const [loading, setLoading] = useState(false);

    const { register, watch, setValue } = useForm<{ message: string }>();

    const data = useMemo(() => {
        if (messages && messages.length > 0 && participants && participants?.length) {
            return messages.map(({ id, sessionKey, message, updateTime, status }: Messages) => {
                const participant = participants.find((p) => p.sessionKey === sessionKey);

                if (participant) {
                    const isActive = status === "1";
                    return {
                        fullName: getFullName({ firstName: participant?.firstName ?? "", lastName: participant?.lastName ?? "" }),
                        email: participant.email,
                        creationTime: new Date(updateTime),
                        sessionKey: sessionKey,
                        province: participant.province,
                        banner: participant.metadata.banner ? participant.metadata.banner : "",
                        locationType: participant.metadata.locationType ? participant.metadata.locationType : "",
                        storeType: participant.metadata?.storeType ? participant.metadata?.storeType : "",
                        preferredLanguage: participant.preferredLanguage ? participant.preferredLanguage : "",
                        employeeNo: participant.metadata?.employeeNo ? participant.metadata.employeeNo : "",
                        location: participant.metadata?.location ? participant.metadata?.location : "",
                        manager: participant.metadata.manager ? participant.metadata.manager : "",
                        storeNo: participant.metadata?.storeNo ? participant.metadata?.storeNo : "",
                        status: status === "0" ? "Pending" : "Message Sent",
                        textMessage: message,
                        message: (
                            <div className="message__wrapper">
                                <div>{message}</div>
                                <button
                                    className="btn-icon"
                                    style={{ flexBasis: "62px" }}
                                    onClick={() => {
                                        setModalMessage({ isActive: true, sk: sessionKey, id });
                                        setValue("message", message);
                                    }}>
                                    <img src="/assets/images/icons/udate.svg" alt="update" />
                                </button>
                            </div>
                        ),

                        // feedTheDreamAction: (
                        //     <button className="btn grey" disabled={false} onClick={() => sendFeed(participant.sessionKey, message)}>
                        //         Send to Feed the Dream
                        //     </button>
                        // ),
                        actions: (
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    id="checkbox_school"
                                    name="status"
                                    defaultChecked={isActive}
                                    onClick={() => {
                                        updateStatus({ id, sessionKey, status: isActive ? "0" : "1" });
                                    }}
                                />

                                <span className="slider round"></span>
                            </label>
                        ),
                    };
                }
            });
        }
    }, [participants, messages]);

    const reportData = useMemo(() => {
        if (data) {
            return data.map((rp: any) => {
                return {
                    "Creation Time": rp.creationTime,
                    "Full Name": rp.fullName,
                    Email: rp.email,
                    Province: rp.province,
                    Language: rp.preferredLanguage,
                    "Store Banner": rp.banner,
                    EmployeeNo: rp.employeeNo,
                    "Location Type": rp.locationType,
                    "Store Type": rp.storeType,
                    storeNo: rp.storeNo,
                    Location: rp.location,
                    Message: rp.textMessage,
                    Status: rp.status,
                };
            });
        }
    }, [data]);

    function sendFeed(sk: string, message: string) {
        setFeedTheDream({ ...sendToFeedModal, sk, message });
    }

    const saveMessage = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const res: { result: string; error?: string; message?: string } = await campaignClient.call("saveMessage", {
                id: modalMessage.id,
                message: watch("message"),
                sessionKey: modalMessage.sk,
            });
            setModalMessage(defaultModalMessage);
            if (res?.result) {
                refetch();
                setLoading(false);
                showToast({
                    content: "Message updated",
                    duration: 3000,
                    error: false,
                });
            } else {
                setLoading(false);
                showToast({
                    content: "Something went wrong, please try again.",
                    duration: 3000,
                    error: true,
                });
            }
        } catch (e) {
            console.log(e);
            window.vex.dialog.alert({
                unsafeMessage: `Something went wrong, please try again.`,
                callback: () => {
                    return;
                },
            });
        }
        setLoading(false);
    };

    async function updateStatus(data: { id: string; status: string; sessionKey: string }) {
        const result = await campaignClient.call("messageStatus", data);

        if (result) {
            showToast({
                content: "Updated  ",
                duration: 3000,
                error: false,
            });
        } else {
            showToast({
                content: "Try again ",
                duration: 3000,
                error: true,
            });
        }
    }

    async function sendToFeedTheDream() {
        setLoading(true);
        // Send to FeedTheDream....
        setLoading(false);
    }
    const columns = TableColumns();

    if (isLoadingParticipants || isLoadingMessages) {
        return <PageLoader />;
    }

    return !isLoadingParticipants && messages && messages?.length > 0 && participants && participants?.length > 0 ? (
        <>
            <CustomModal
                isOpen={feedTheDream.isOpen}
                setIsOpen={() => setFeedTheDream({ ...feedTheDream, isOpen: false })}
                setAction={() => {
                    sendToFeedTheDream();
                }}
                actionTitle={feedTheDream.action}
                loading={loading}
                header={feedTheDream.title}
                message={feedTheDream.body + " '" + feedTheDream.message + "'"}
            />
            <CustomModal
                isOpen={modalMessage.isActive}
                setIsOpen={() => setModalMessage(defaultModalMessage)}
                setAction={() => saveMessage()}
                actionTitle="Update message"
                loading={loading}
                header="Update message">
                <form className="form__row paddingBottom">
                    <label htmlFor="message" className="form__label">
                        <b>Message</b>
                    </label>
                    <textarea className="field" id="message" {...register("message")} rows={4} cols={50} />
                </form>
            </CustomModal>

            <div className="main__head">
                <h2 className="main__title">Messages</h2>

                <div className="main__actions">
                    <div className="tabs js-tabs">
                        <div className="search search--medium flexContainer">
                            <div className="search__row">
                                <label htmlFor="q" className="hidden">
                                    Search
                                </label>
                                <input
                                    type="search"
                                    name="q"
                                    id="q"
                                    value={filterTable}
                                    placeholder="Search"
                                    className="search__field"
                                    onChange={(e) => setFilterTable(e.target.value)}></input>
                                <button type="submit" className="search__btn">
                                    <svg className="ico-search">
                                        <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                                    </svg>
                                </button>
                            </div>

                            <div className="search search--alt marginLeft">
                                <div className="search__row search__row--input">
                                    <CSVLink
                                        filename={`Campaign-Messages-Report-${new Date().toLocaleDateString()}`}
                                        className="btn btn--small btn--mobile-small"
                                        data={reportData ? reportData : ""}
                                        asyncOnClick={true}
                                        target="_blank">
                                        Download Report
                                    </CSVLink>
                                </div>
                            </div>
                        </div>{" "}
                    </div>
                </div>
            </div>

            <div className="tabs__body mt-5">
                <div className="table table--alt table--tabs table--big">
                    <Table columns={columns} data={data ? data : []} tablePageSize={15} filterValue={filterTable} filterId="creationTime" desc={true} />
                </div>
            </div>
        </>
    ) : (
        <p>Currently there are no any messages to display.</p>
    );
}

export default Messages;
