import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { CSVLink } from "react-csv";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../Components";
import { TableColumns } from "./WinnerCol";
import { campaignClient } from "../../api";
import { byKey, getLegalName } from "../../helpers/utils";
import { showToast } from "../../Components/Toast/ToastManager";
import "react-datepicker/dist/react-datepicker.css";
import { MultiOptionType, MultilSelect } from "../../Components/MultilSelect";
import ReactDatePicker from "../../Components/DatePicker";
import { Button } from "react-bootstrap";
import { assign, compact, concat, flatten, isArray, isDate, map, mapValues } from "lodash";
import { ESurveyWinnerStatus, MONTH_TYPE_FILTER, PRIZES, SURVEY_FILTER, Status_type_filter, WINNER_TYPE_FILTER } from "../../helpers/constants";
import dayjs from "dayjs";
import { AddQuestionArgs, SurveyAnswersReturn } from "../../helpers/types";
import axios from "axios";
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween);



declare const window: Window &
    typeof globalThis & {
        vex: any;
    };

interface GrandPrizeDrawRankingType extends Omit<MainDbReturnTypes.GrandPrizeDrawRanking, 'participant'> {
    prize: string
    winner: string
    participant: MainDbReturnTypes.Participant | null
    answers?: { [key: string]: string },
    drawDate: string
}

function Winners() {

    const [startDate, setStartDate] = useState<Date | null>(null);

    const [endDate, setEndDate] = useState<Date | null>(null);

    const [multiValue, setMultiValue] = useState<MultiOptionType[]>(
        []
    );

    const [filterTable, setFilterTable] = useState("");
    const [columnFilter, setColumnFilter] = useState<{ [key: string]: string | string[] } | null>(null);

    const [loading, setLoading] = useState(false);

    const [dateRange, setDateRange] = useState<{ startDate: string, endDate: string }>({ startDate: "", endDate: "" })

    const [selectType, setSelectedType] = useState<MultiOptionType | null>(null);
    const [selectedStatus, setSelectedStatus] = useState<MultiOptionType | null>(null);

    const [selectMonth, setSelectedMonth] = useState<MultiOptionType | null>(null);
    const [selectPrize, setSelectedPrize] = useState<MultiOptionType | null>(null);
    const [prizeError, setPrizeError] = useState<string | null>(null);




    const { isLoading: isLoadingParticipants, data: participants } = useQuery("getParticipants", async () => {
        const res: { participants: MainDbReturnTypes.Participant[], largeResultUrl?: string, result?: { participants: MainDbReturnTypes.Participant[] } } = await campaignClient.call("getParticipants", {});
        let participants;
        if (res.participants) {
            participants = res.participants;
        }
        if (res.result) {
            participants = res.result.participants;
        }

        if (res.largeResultUrl) {
            const result = await axios(res.largeResultUrl);
            const response: MainDbReturnTypes.Participant[] = await result.data.result.participants;
            participants = response;
        }
        return participants;
    });

    const {
        isLoading: isQuestionLoading,
        data: questions,
        refetch: refetchQuestions,
    } = useQuery("getSurvey", async () => {
        const res: { survey: AddQuestionArgs[] } = await campaignClient.call("getSurvey", {});
        if (res.survey && res.survey?.length) {
            const filteredQuestions = res.survey?.filter(v => v.type === "free" || v.type === "text").sort((a: any, b: any) => a.q_seq - b.q_seq);
            return filteredQuestions;
        }
        return [];
    });

    const {
        isLoading: isSurveyLoading,
        data: surveyResults,
        //refetch       
    } = useQuery(["getResults", { statsType: "correspondent" }], async () => {
        const res = await campaignClient.call<{ items: SurveyAnswersReturn[] }>("getResults", { statsType: "correspondent" });
        return res.items.filter(item => item.answers && item.status && (+item.status === +ESurveyWinnerStatus.potential || +item.status === +ESurveyWinnerStatus.win));
    });

    const {
        isLoading: isloadinRankings,
        data: rankings,
        refetch,
    } = useQuery(["getWinners", {}], async () => {

        const res = await campaignClient.call<{ grandPrizeDrawWinners: { [key: string]: GrandPrizeDrawRankingType[] } }>("getWinners", {});

        if (res?.grandPrizeDrawWinners) {
            const grandPrizeDraw = mapValues(res.grandPrizeDrawWinners, function (v, k) { return map(v, function (d) { return { ...d, grandPrizeDrawKey: k } }) });

            return flatten(Object.values(grandPrizeDraw));
        }
        return [];
    });

    const { isLoading: isLoadingPrizes, data: prizes } = useQuery("getPrizes", async () => {
        const res: MainDbReturnTypes.Prizes = await campaignClient.call("getPrizes", {});
        const prizes = byKey(res.prizes, (x) => x.prizeKey);
        return prizes;
    });

    const { isLoading: isloadingWinners, data: winners, refetch: refetchPrizeWinners } = useQuery("getPrizeWinners", async () => {
        const res = await campaignClient.call<MainDbReturnTypes.PrizeWinners>("getPrizeWinners", {});
        return res.prizeWinners
    });

    const handleSingleFilter = (filterType: string, value: MultiOptionType | MultiOptionType[] | Date) => {


        if ((filterType === "startDate" || filterType === "endDate" || filterType === "winnerMonth")) {

            let dateFilter: string[] = [], tempDateRange = dateRange


            if (filterType === "startDate") {

                tempDateRange = { ...dateRange, startDate: isDate(value) ? dayjs(value).format("YYYY-MM-DD") : "" }

                setDateRange(tempDateRange);

                setStartDate(isDate(value) ? value : null)
            }
            if (filterType === "endDate") {
                tempDateRange = { ...dateRange, endDate: isDate(value) ? dayjs(value).format("YYYY-MM-DD") : "" }
                setDateRange(tempDateRange);
                setEndDate(isDate(value) ? value : null)
            }
            if (filterType === "winnerMonth") {

                tempDateRange = {
                    startDate: value ? (dayjs().set('month', +(value as MultiOptionType).value - 1).startOf("month")).toISOString() : "",
                    endDate: value ? (dayjs().set('month', +(value as MultiOptionType).value - 1).endOf("month").toISOString()) : ""
                }
                setStartDate(value ? new Date(tempDateRange.startDate) : null)
                setEndDate(value ? new Date(tempDateRange.endDate) : null)
                setDateRange(tempDateRange)
                setSelectedMonth(value as MultiOptionType)
            }
            if (compact(Object.values(tempDateRange)).length === 0) {
                setColumnFilter({ creationDate: [] })
            }
            if (compact(Object.values(tempDateRange)).length === 2) {

                let start = dayjs(tempDateRange.startDate);

                while (start.isBefore(tempDateRange.endDate) || start.isSame(tempDateRange.endDate)) {
                    dateFilter.push(start.format("YYYY-MM-DD"))
                    start = start.add(1, "d");
                }
                setColumnFilter({ creationDate: dateFilter })
            }

        }

        if (filterType === "winnerType") {

            setColumnFilter({ winnerType: !value ? [] : isArray(value) ? value[0].label : (value as MultiOptionType).label })
            setSelectedType(value as MultiOptionType)
        }

        if (filterType === "surveyType") {
            if (isArray(value)) {
                setColumnFilter({ answerForSearch: map(value, "value") })
            } else {
                setColumnFilter({ answerForSearch: [] })
            }
            setMultiValue(value as MultiOptionType[])
        }
        if (filterType === "status") {
            setColumnFilter({ status: !value ? [] : isArray(value) ? value[0].label : (value as MultiOptionType).label })
            setSelectedStatus(value as MultiOptionType)
        }
    }

    const data = useMemo(() => {

        if (rankings && rankings.length > 0 && prizes && winners && participants && surveyResults && questions) {

            const winnersByKey = byKey(winners, (w) => w.prizeWinnerKey)

            const participantsByKey = byKey(participants, (p) => p.sessionKey)

            const finalWinners = concat([], rankings)



            surveyResults.forEach(s => {
                finalWinners.push({
                    grandPrizeDrawKey: "",
                    prize: "survey",
                    winner: s.prizeWinnerKey || "",
                    grandPrizeDrawRankingKey: "",
                    ranking: 0,
                    participant: participantsByKey[s.sessionKey] ? participantsByKey[s.sessionKey][0] : null,
                    rejectionTime: null,
                    rejectionReason: "",
                    creationTime: s.creationTime,
                    answers: s.answers,
                    drawDate: s.prizeWinnerKey ? winnersByKey[s.prizeWinnerKey][0].creationTime : "",

                })

            })

            return finalWinners.map((rp) => {


                const winner = rp.winner && winnersByKey[rp.winner] ? winnersByKey[rp.winner][0] : null;

                let status = ""
                if (rp.rejectionTime) {
                    status = "Rejected"
                }
                if (winner && !winner.declarationAndRelease) {
                    status = "Pending Claim"
                }

                if (winner && winner.declarationAndRelease) {
                    status = "Claimed"

                }
                if (winner && winner.forfeitTime) {
                    status = "Rejected"
                }

                return {
                    firstName: rp.participant?.firstName,
                    lastName: rp.participant?.lastName,
                    email: rp.participant?.email,
                    winnerType: rp.prize && PRIZES[rp.prize] ? PRIZES[rp.prize].label : rp.prize,
                    fulfillTime: winner?.declarationAndRelease ? new Date(winner.declarationAndRelease.creationTime) : "",
                    updateTime: winner ? new Date(winner?.creationTime) : rp.creationTime ? rp.creationTime : rp.participant?.creationTime,
                    creationDate: dayjs(rp.drawDate).format("YYYY-MM-DD"),
                    creationTime: dayjs(rp.participant?.creationTime).format("YYYY-MM-DD"),
                    status: status, //rejectionTime, wi
                    legalName: winner?.declarationAndRelease && getLegalName(winner.declarationAndRelease.firstName, winner.declarationAndRelease?.lastName),
                    address: winner?.declarationAndRelease?.address,
                    address2: winner?.declarationAndRelease?.address2,
                    city: winner?.declarationAndRelease?.city,
                    province: winner?.declarationAndRelease?.province,
                    postal: winner?.declarationAndRelease?.postal,
                    phone: winner?.declarationAndRelease?.phone,
                    prizeDes: rp?.prize && prizes[PRIZES[rp.prize].prizeKey] ? prizes[PRIZES[rp.prize].prizeKey][0].description : "",
                    prize: winner ? prizes[winner.prizeKey][0].description : "",
                    sessionKey: rp.participant?.sessionKey,
                    rules: rp.participant?.metadata?.rules ? rp.participant?.metadata?.rules.toLocaleString() : "NA",
                    //optin1: rp.participant?.metadata?.optin1 ? rp.participant?.metadata?.optin1.toLocaleString() : "NA",
                    grandPrizeDrawKey: winner ? winner.grandPrizeDrawKey : rp.grandPrizeDrawKey,
                    grandPrizeDrawRankingKey: winner ? winner.grandPrizeDrawRankingKey : rp.grandPrizeDrawRankingKey,
                    winner: rp?.winner || "",
                    rejectionTime: rp?.rejectionTime,
                    forfeitTime: winner?.forfeitTime,
                    sceneplus: winner?.participant.metadata?.sceneplus || "N/A",
                    answers: rp.answers || null,
                    answerForSearch: rp.answers ? Object.values(rp.answers).join(",") : "",
                    banner: rp.participant?.metadata.banner ? rp.participant?.metadata.banner : "N/A",
                    locationType: rp.participant?.metadata.locationType ? rp.participant?.metadata.locationType : "N/A",
                    storeType: rp.participant?.metadata?.storeType ? rp.participant?.metadata?.storeType : "N/A",
                    preferredLanguage: rp.participant?.preferredLanguage ? rp.participant?.preferredLanguage : "NA",
                    employeeNo: rp.participant?.metadata?.employeeNo ? rp.participant?.metadata.employeeNo : "NA",
                    location: rp.participant?.metadata?.location ? rp.participant?.metadata?.location : "NA",
                    manager: rp.participant?.metadata.manager ? rp.participant?.metadata.manager : "NA",
                    storeNo: rp.participant?.metadata?.storeNo ? rp.participant?.metadata?.storeNo : "NA",
                };
            });
        }

    }, [rankings, prizes, winners, participants, surveyResults, questions, selectType]);


    async function selectWinner({
        grandPrizeDrawRankingKey,
        grandPrizeDrawKey,
        type,
        email,
        prizeKey
    }: {
        email: string;
        grandPrizeDrawRankingKey: string;
        grandPrizeDrawKey: string;
        type: "assign" | "reject";
        prizeKey: string
    }) {

        setLoading(true);
        try {
            const res: { result: string; error?: string; message?: string } = await campaignClient.call("selectWinner", {
                grandPrizeDrawRankingKey,
                grandPrizeDrawKey,
                type,
                prizeKey
            });

            if (res?.result) {
                refetch();
                refetchPrizeWinners()
                setLoading(false);
                showToast({
                    content: "Winner " + email + " " + type + "ed",
                    duration: 3000,
                    error: false,
                });
            } else {
                setLoading(false);
                showToast({
                    content: "Something went wrong, please try again.",
                    duration: 3000,
                    error: true,
                });
            }
        } catch (e) {
            window.vex.dialog.alert({
                unsafeMessage: `Something went wrong, please try again.`,
                callback: () => {
                    return;
                },
            });
        }
        setLoading(false);
    }

    async function selectSurveyWinner({
        sessionKey,
        prizeWinnerKey,
        type,
    }: {
        sessionKey: string,
        prizeWinnerKey: string,
        type: "3" | "4"
    }) {
        if (loading) return;
        setLoading(true);
        try {
            const res: { result: string; error?: string; message?: string } = await campaignClient.call("selectSurvey", {
                prizeWinnerKey,
                sessionKey,
                type,
            });

            if (res?.result) {
                refetch();
                refetchPrizeWinners()
                setLoading(false);
                showToast({
                    content: "Prize is assigned.",
                    duration: 3000,
                    error: false,
                });
            } else {
                setLoading(false);
                showToast({
                    content: "Something went wrong, please try again.",
                    duration: 3000,
                    error: true,
                });
            }
        } catch (e) {
            console.log(e);
            window.vex.dialog.alert({
                unsafeMessage: `Something went wrong, please try again.`,
                callback: () => {
                    return;
                },
            });
        }
        setLoading(false);
    }


    async function resendClaimEmail({
        prizeWinnerKey,
        sessionKey
    }: {
        prizeWinnerKey: string;
        sessionKey: string
    }) {
        if (loading) return;
        setLoading(true);
        try {
            const res: { result: string; error?: string; message?: string } = await campaignClient.call("resendClaimEmail", {
                sessionKey, prizeWinnerKey
            });

            if (res.error) {
                setLoading(false);
                showToast({
                    content: `${res.message}`,
                    duration: 3000,
                    error: true,
                });
            }

            if (res?.result) {
                refetch();
                setLoading(false);
                showToast({
                    content: "Email resent successfully!",
                    duration: 3000,
                    error: false,
                });
            }
        } catch (e) {
            window.vex.dialog.alert({
                unsafeMessage: `Something went wrong, please try again.`,
                callback: () => {
                    return;
                },
            });
        }
        setLoading(false);
    }

    const reportData = useMemo(() => {
        if (data && questions) {

            let allData = [...data]
            if (dateRange.startDate && dateRange.endDate) {
                allData = allData.filter(d => dayjs(d.creationDate).isBetween(dayjs(dateRange.startDate), dayjs(dateRange.endDate), "day", "[)"))
            }
            if (selectType?.value) {
                allData = allData.filter(d => d.winnerType === selectType.label)
            }
            if (multiValue.length) {
                let values = multiValue.map(v => v.value)
                allData = allData.filter(d => values.some(a => d.answerForSearch.includes(a)))
            }
            if (selectedStatus) {
                allData = allData.filter(d => d.status == selectedStatus.label)
            }

            return allData.map((rp) => {
                return assign({
                    "First Name": rp.firstName,
                    "Last Name": rp.lastName,
                    "Email Address": rp.email,
                    "Rules/Terms": rp.rules,
                    "Draw Date": rp.creationDate,
                    "Language": rp.preferredLanguage,
                    "Winner Type": rp.winnerType,
                    "Prize": rp.prize,
                    "Prize Claimed Time": rp.fulfillTime.toLocaleString(),
                    "Status": rp.status,
                    "Legal Name": rp.legalName ? rp.legalName : "",
                    "Address Line 1": rp.address || "",
                    "Address Line 2": rp.address2 || "",
                    "City": rp.city || "",
                    "Province": rp.province || "",
                    "Postal": rp.postal || "",
                    "Phone Number": rp.phone ? rp.phone : "N/A",
                    "Store Banner": rp.banner ? rp.banner : "N/A",
                    "Store No": rp.storeNo,
                    "EmployeeNo": rp.employeeNo,
                    "Location Type": rp.locationType,
                    "Store Type": rp.storeType,
                    "Location": rp.location,
                    "Manager": rp.manager,
                    "sceneplus": rp.sceneplus ? rp.sceneplus : ""
                },
                    ...questions.map(q => {

                        if (q.type === "radio") {
                            const answer = rp.answers ? q.options.find(o => o.id === rp.answers![q.id]) : ""

                            return { [q.question.en]: answer ? answer.label_en : "" };
                        } else {

                            return { [q.question.en]: `${rp.answers && rp.answers[q.id] ? rp.answers[q.id] : ""}` }

                        }
                    })
                );
            });
        }
    }, [data, questions, selectType, dateRange, multiValue]);

    const columns = useMemo(() => {

        if (selectType && selectType.value === "survey") {
            return TableColumns({ selectWinner, resendClaimEmail, selectSurveyWinner, questions: questions })
        }
        else if (selectType && selectType.value === "trivia") {
            return TableColumns({ selectWinner, resendClaimEmail, selectSurveyWinner, prizeKey: selectPrize?.value, selectType: selectType.value, setPrizeError })

        }
        else {
            return TableColumns({ selectWinner, resendClaimEmail, selectSurveyWinner })
        }

    }, [selectWinner, resendClaimEmail, selectSurveyWinner, selectType])

    if (isloadinRankings || isLoadingPrizes || isloadingWinners || isSurveyLoading || isLoadingParticipants || isQuestionLoading) {
        return <PageLoader />;
    }

    return !isloadinRankings && !isloadingWinners && rankings && rankings.length > 0 ? (
        <>
            <div className="main__head">
                <div className="row">
                    <div className="col"><h2 className="main__title">Winners</h2></div>

                </div>
                <div className="row mt-2 gy-2">
                    <div className={`${selectType && selectType.value === "trivia" ? "col-3" : "hidden"}`}>
                        <MultilSelect isMulti={false} multiValue={selectMonth} setMultiValue={(data: MultiOptionType) => { handleSingleFilter("winnerMonth", data) }} placeholder="Select by month..." Options={MONTH_TYPE_FILTER} /></div>

                    <div className={`${selectType && selectType.value === "trivia" ? "hidden" : "col-auto"}`}>
                        <ReactDatePicker selectDate={startDate} setSelectDate={(value: Date) => { handleSingleFilter("startDate", value) }}
                            placeholder="Start Date"
                        />
                    </div>
                    <div className={`${selectType && selectType.value === "trivia" ? "hidden" : "col-auto"}`}><ReactDatePicker selectDate={endDate} setSelectDate={(value: Date) => { handleSingleFilter("endDate", value) }}
                        placeholder="End Date"
                    /></div>

                    <div className="col-3">
                        <MultilSelect isMulti={false} multiValue={selectType} setMultiValue={(data: MultiOptionType) => { handleSingleFilter("winnerType", data) }} placeholder="Select winner type..." Options={WINNER_TYPE_FILTER} /></div>
                    <div className="col-3">
                        <MultilSelect isMulti={false} multiValue={selectedStatus} setMultiValue={(data: MultiOptionType) => { handleSingleFilter("status", data) }} placeholder="Select status..." Options={Status_type_filter} /></div>

                    <div className={`${selectType && selectType.value === "trivia" ? "col-3" : "hidden"}`}>
                        <MultilSelect isMulti={false} multiValue={selectPrize} setMultiValue={(data: MultiOptionType) => { setSelectedPrize(data) }} placeholder="Prize selection..." Options={prizes ? [...prizes["b7a0a5d89c00fecded7a92160b4e6f24"],
                        ...prizes["dbf9ea11dbd35adf8ed0f0265d18e62e"]].map(p => { return { value: p.prizeKey, label: p.prizeName } }) : []} />
                        {!selectPrize && <p className="error-message">{prizeError}</p>}

                    </div>

                    <div className={`${selectType && selectType.value === "survey" ? "col-3" : "hidden"}`}><MultilSelect isMulti={true} multiValue={multiValue} setMultiValue={(data: MultiOptionType[]) => { handleSingleFilter("surveyType", data) }} Options={SURVEY_FILTER} /></div>
                    {/* <div className="col-auto">
                        <Button className="btn  btn__cancel fs-6" onClick={()=>{ 
                            setDateRange({startDate: "", endDate: ""})
                            setStartDate(null)
                            setEndDate(null)
                            setSelectedType(null)
                            setColumnFilter({winnerType: []})
                            }}>
                            Reset
                        </Button>
                    </div> */}
                </div>

            </div>
            <div className="main__body">
                <div className="tabs js-tabs">
                    <div className="search search--alt search--medium main__search">
                        <div className="row justify-content-between">
                            <div className="col-auto">

                                <div className="">
                                    <CSVLink
                                        filename={`Campaign-Winner-Report-${new Date().toLocaleDateString()}`}
                                        className="btn btn--small btn--mobile-small"
                                        data={reportData ? reportData : ""}
                                        asyncOnClick={true}
                                        target="_blank">
                                        Download Report
                                    </CSVLink>
                                </div>

                            </div>
                            <div className="col-auto">
                                <div className="search__row">
                                    <label htmlFor="q" className="hidden">
                                        Search
                                    </label>

                                    <input
                                        type="search"
                                        name="winner_search"
                                        id="winner_search"
                                        value={filterTable}
                                        placeholder="Search"
                                        className="search__field"
                                        onChange={(e) => setFilterTable(e.target.value)}></input>

                                    <button type="submit" className="search__btn">
                                        <svg className="ico-search">
                                            <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                                        </svg>
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="tabs__head">

                    </div>
                    <div className="tabs__body mt-5">
                        <div className="table table--alt table--tabs table--big">
                            <Table columns={columns} data={data ? data : []} filterId="fulfillTime" desc={true}
                                tablePageSize={10} filterValue={filterTable} singleFilter={columnFilter} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <p>Currently there are no any winners to display.</p>
    );
}

export default Winners;
