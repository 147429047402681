import React, { useState, useRef } from 'react'
import { useForm, useFieldArray, Controller, } from 'react-hook-form';
import { campaignClient } from '../../api';
import { getFileUrl } from '../../helpers/utils';
import { showToast } from '../../Components/Toast/ToastManager';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import * as tus from "tus-js-client";
import Dropzone from '../../Components/DropZone';
import ReactPlayer from 'react-player';


declare const window: Window &
    typeof globalThis & {
        vex: any;
    };

export type spotlightVideo = {
    id?: string,
    athletName: string,
    thumbnail: { fileName: string; key: string },
    videoLink: string | undefined,
    description: { title_fr: string, title_en: string, desc_en: string, desc_fr: string }[]
}

const AddSpotlite = ({ newAthlete, athlete, refetch }: { athlete?: spotlightVideo, newAthlete: boolean, refetch: Function }) => {

    let hiddenFileInput = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<File | null>();
    const [videoFile, setVideoFile] = useState<File | null>();
    const [uploading, setUploading] = useState(false);
    const [previewImg, setPreviewImg] = useState("");
    const [uploadingPercent, setUploadingPercent] = useState(0);
    const [uploadStart, setUploadStart] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [videoUrl, setVideoUrl] = useState("")
    const navigate = useNavigate()

    const default_values: spotlightVideo = {
        athletName: "",
        videoLink: "",
        thumbnail: { fileName: "", key: "" },
        id: "",
        description: [{ title_en: "", title_fr: "", desc_en: "", desc_fr: "" }],

    }
    const {
        handleSubmit,
        register,
        watch,
        setValue,
        control,
        formState: { errors },
    } = useForm<spotlightVideo>({
        mode: "onTouched",
        criteriaMode: "all",
        defaultValues: newAthlete ? { ...default_values } : { ...athlete }
    });



    const formwatch = watch()
    const {
        fields,
        append,
        prepend,
        remove,
        swap,
        move,
        insert,
        replace
    } = useFieldArray({
        control,
        name: "description"
    });

    async function handleUpload2(uploadurl: string) {
        if (!videoFile || uploading || !uploadurl) {
            return;
        }
        const fileToUpload = videoFile;
        setUploading(true);
        setUploadingPercent(0);
        setUploadComplete(false);
        try {
            setUploadStart(true);
            const upload = new tus.Upload(fileToUpload, {
                endpoint: "https://api.vimeo.com/me/videos",
                uploadUrl: uploadurl,
                retryDelays: [0, 3000, 5000, 10000, 20000],
                metadata: {
                    filename: fileToUpload.name,
                    filetype: fileToUpload.type,
                },
                headers: {},
                onError: function (error) {
                    console.log("Failed because: " + error);
                },
                onProgress: function (bytesUploaded, bytesTotal) {
                    let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
                    // console.log(bytesUploaded, bytesTotal, percentage + "%");
                    setUploadingPercent(+percentage);
                },
                onSuccess: async function () {
                    setUploadComplete(true)
                    showToast({
                        content: "Video has been saved!. ",
                        duration: 3000,
                        error: false,
                    });
                    setUploading(false);
                    await refetch()
                    navigate("/spotlights")
                },
            });
            upload.start();
        } catch (e) {
            setUploading(false);
            setLoading(false);
        }
    }

    async function handleUpload() {
        if (!file || uploading) {
            return;
        }
        setUploading(true);

        const fileToUpload = file;
        try {
            const data = await fileToUpload.arrayBuffer();
            const { uploadUrl, key } = await campaignClient.call<{
                uploadUrl: string;
                key: string;
            }>("getPublicHostingUrl", { filename: file.name.replaceAll(" ","_"), filetype: file.type, fileSize: file.size });

            await axios.put(uploadUrl, data, {
                onUploadProgress: function (progressEvent) { },
                headers: {
                    "Content-Type": file.type,
                },
            });

            return { key: key, fileName: file.name.replaceAll(" ","_") };
        } catch (e) {
            console.log(e);
        }
        setUploading(false);
    }

    const handleSaveChanges = async (data: spotlightVideo) => {
        if (loading) {
            return;
        }
        let key, fileName;
        setLoading(true);
        if (previewImg) {
            const res = await handleUpload();
            key = res?.key
            fileName = res?.fileName
        }
        else if (formwatch.thumbnail.fileName) {
            key = formwatch.thumbnail.key
            fileName = formwatch.thumbnail.fileName
        }

        if (videoFile) {

            const result: {
                upload_link: string | null;
                player_embed_url: string;
                videoKey: string;
                error?: string;
            } = await campaignClient.call("getvideouploadurl", {
                fileSize: videoFile.size,
                fileName: videoFile.name,
            });
            if (result.upload_link) {
                data.videoLink = result.player_embed_url
                const res = await handleUpload2(result.upload_link);
            }
        }
        const { result, error, message } = await campaignClient.call<{ result: string; error: string, message: string }>("saveSpotlightVideo", {
            ...data,
            thumbnail: { key, fileName: fileName },
        });
        if (error) {
            showToast({
                content: `${message}`,
                duration: 3000,
                error: true,
            });
        }

        if (!videoFile && result) {
            await refetch()
            showToast({
                content: "Video has been saved!. ",
                duration: 3000,
                error: false,
            });
            navigate("/spotlights")
        }
        setLoading(false);
    };
    const DeleteImage = async () => {
        if (loading) {
            return;
        }

        if (athlete) {
            setValue("thumbnail", { fileName: "", key: "" })
            setLoading(true);


        }
        setPreviewImg("");
        setFile(null);

        setLoading(false);

    };
    async function handleSingleFile(fileData: any) {
        if (!fileData?.target?.files?.length) {
            return;
        }
        const type = fileData?.target?.files[0].type;
        if (+fileData?.target?.files[0].size > 5266467) {
            return showToast({
                content: "Image size more then  5mb.",
                duration: 3000,
                error: true,
            });
        } else if (type.includes("image/")) {
            const url = URL.createObjectURL(fileData.target.files[0]);
            setPreviewImg(url);
            setFile(fileData.target.files[0]);
        } else {
            showToast({
                content: "An error while upload .",
                duration: 3000,
                error: true,
            });
        }
    }

    return (
        <>
            <div className="main__head">
                <h2 className="main__title">{newAthlete ? "Add" : "Save"} Athlete spotlight video</h2>
            </div>
            <p>{newAthlete ? "Add" : "Save"} the details for the athlete spotlight video below.</p>
            <div className="main__body main__body--flex main__body--flex-alt">
                <form onSubmit={handleSubmit(handleSaveChanges)} className="form">
                    <div style={{ maxWidth: "700px" }} >
                        <div className="row mt-10">
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="athletName" className="form__label">
                                    <b>Athlete name</b>
                                </label>

                                <div className="form__controls">

                                    <input
                                        type="text"
                                        className="field"
                                        id="firstName"
                                        {...register("athletName", {
                                            required: {
                                                value: true,
                                                message: "Please enter athlete name.",
                                            },
                                        })}
                                        defaultValue={athlete?.athletName ?? ""}
                                    />
                                </div>
                                {errors.athletName && (
                                    <p className="error-message">
                                        <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                                        {errors.athletName.message}
                                    </p>
                                )}

                            </div>

                        </div>
                        <ul className='field-control-item'>
                            {fields.map((item, index) => {
                                return (
                                    <li key={item.id}>
                                        <div className="row mt-10">
                                            <div className="col-sm-12 col-md-5">
                                                <label htmlFor="title_en" className="form__label">
                                                    <b>Title - English</b>
                                                </label>

                                                <Controller
                                                    render={({ field }) => <input className="field" id="message"{...field}></input>}
                                                    name={`description.${index}.title_en`}
                                                    control={control}
                                                />

                                                {errors.description && errors.description[index]?.title_en && (
                                                    <p className="error-message">
                                                        <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                                                        {errors.description[index]?.title_en?.message}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-sm-12 col-md-5">
                                                <label htmlFor="desc_fr" className="form__label">
                                                    <b>Title - French</b>
                                                </label>

                                                <Controller
                                                    render={({ field }) => <input className="field" id="message" {...field}></input>}
                                                    name={`description.${index}.title_fr`}
                                                    control={control}
                                                />
                                                {errors.description && errors.description[index]?.title_fr && (
                                                    <p className="error-message">
                                                        <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                                                        {errors.description[index]?.title_fr?.message}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-sm-2 col-md-1 message__wrapper">
                                                <button type="button" className='btn-icon' onClick={() => append(default_values.description[0])}>
                                                    <img src="\assets\images\icons\icon-plus-circle.svg" alt="update" />
                                                </button>
                                                <button type="button" className='btn-icon' onClick={() => remove(index)}>
                                                    <img src="/assets/images/icons/trash.svg" alt="delete" />
                                                </button>
                                            </div>
                                        </div>

                                        <div className="row mt-10">
                                            <div className="col-sm-12 col-md-5">
                                                <label htmlFor="firstName" className="form__label">
                                                    <b>Description - English</b>
                                                </label>

                                                <Controller
                                                    render={({ field }) => <textarea className="field text-area-1" id="message" rows={4} cols={10} {...field}></textarea>}
                                                    name={`description.${index}.desc_en`}
                                                    control={control}
                                                />

                                                {errors.description && errors.description[index]?.desc_en && (
                                                    <p className="error-message">
                                                        <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                                                        {errors.description[index]?.desc_en?.message}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="col-sm-12 col-md-5">
                                                <label htmlFor="desc_fr" className="form__label">
                                                    <b>Description - French</b>
                                                </label>

                                                <Controller
                                                    render={({ field }) => <textarea className="field text-area-1" id="message" rows={4} cols={10} {...field}></textarea>}
                                                    name={`description.${index}.desc_fr`}
                                                    control={control}
                                                />
                                                {errors.description && errors.description[index]?.desc_fr && (
                                                    <p className="error-message">
                                                        <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                                                        {errors.description[index]?.desc_fr?.message}
                                                    </p>
                                                )}
                                            </div>
                                        </div>



                                    </li>
                                );
                            })}

                        </ul>
                        {(previewImg || formwatch?.thumbnail.fileName) ?
                            <div className="row" style={{ alignItems: "center" }} >
                                <b>Thumbnail Image</b>
                                <div className="col-sm-8 col-md-3 mt-10">
                                    <label htmlFor="upload">
                                        <img
                                            src={previewImg ? previewImg : athlete ? getFileUrl(formwatch.thumbnail.fileName) : "/assets/images/photo.png"}
                                            alt=""
                                            style={{ cursor: "pointer" }}
                                            width={200}
                                        />
                                    </label>
                                </div>
                                <div className="col-sm-8 col-md-6">
                                    {(file?.name || formwatch?.thumbnail?.fileName) && (
                                        <div className="row-icons">
                                            <p>{file?.name ? file?.name : formwatch?.thumbnail.fileName ?? ""} </p>
                                            <button className="btn-icon" type="button" onClick={DeleteImage}>
                                                <img src="/assets/images/svg/remove.svg" alt="remove" />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div> :

                            <div className="row">
                                <div className="col-sm-8 col-md-4">
                                    <b>Thumbnail Image</b>

                                    <button className="btn__cancel small mt-20" onClick={(e) => hiddenFileInput.current?.click()} type="button">
                                        Upload an image
                                    </button>

                                    <input
                                        type="file"
                                        onChange={handleSingleFile}
                                        className="form__field"
                                        id="upload"
                                        ref={hiddenFileInput}
                                        name="upload"
                                        accept=".png, .jpg, .jpeg, capture=user"
                                        hidden
                                    />
                                </div>



                            </div>}

                        <div className="row mt-20">
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="athletName" className="form__label">
                                    <b>Video link</b>
                                </label>
                                <p> Please provide the video link in the text field below or upload the video.</p>
                                <div className="form__controls">

                                    <input
                                        type="text"
                                        className="field"
                                        id="videoLink"
                                        placeholder='Provide video link , if you already have.'
                                        {...register("videoLink", {

                                        })}
                                        disabled={videoFile ? true : false}
                                        defaultValue={athlete?.videoLink ?? ""}
                                    />
                                </div>
                                {errors.videoLink && (
                                    <p className="error-message">
                                        <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                                        {errors.videoLink.message}
                                    </p>
                                )}
                                <div className="form__controls mt-10">
                                    {videoUrl || formwatch.videoLink ? (
                                        <div className="video-preview">
                                            <ReactPlayer
                                                url={videoUrl || formwatch.videoLink}
                                                playing={false}
                                                loop={false}
                                                controls={true}
                                                width="100%"
                                            />
                                            <button className="btn-icon" type="button" onClick={() => { setVideoUrl(""); setValue("videoLink", ""); setVideoFile(null) }}>
                                                <img src="/assets/images/svg/remove.svg" alt="remove" />
                                            </button></div>) :

                                        <>
                                            <p> or</p>
                                            <Dropzone
                                                setFile={(v: any) => setVideoFile(v)}
                                                setVideoUrl={(v: any) => setVideoUrl(v)}
                                            />
                                        </>

                                    }
                                </div>


                            </div>

                        </div>
                        <div className='row mt-20'>
                            <div className="col-sm-8 col-md-2">

                                <button className="btn__cancel small" type="button" onClick={() => navigate('/spotlights')}>
                                    Cancel
                                </button>
                            </div>
                            <div className="col-sm-8 col-md-3">

                                {(uploading || loading) ?
                                    <button type="button" className="btn small" disabled={true}>
                                        {uploadStart ? uploadingPercent + "% Completed" : "Uploading"}
                                    </button>
                                    :
                                    <button className="btn small" type="submit">
                                        {newAthlete ? "Submit" : "Save"}
                                    </button>}
                            </div>
                        </div>


                    </div>
                </form >
            </div >
        </>
    )
}

export default AddSpotlite