import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { campaignClient } from "../../api";
import { PageLoader, Table } from "../../Components";
import { fileColumns } from "./FilesCol";
import { showToast } from "../../Components/Toast/ToastManager";
import axios from "axios";
import { getFileUrl } from "../../helpers/utils";
import { useForm } from "react-hook-form";

type RulesType = {
    statsType: string;
    language: string;
}

const rulesTypes: { [key: string]: string } = { "rules": "Contest rules", "champion": "Dream champion", "weekly": "Weekly challenges", "trivia": "Trivia", "lunchbox": "Lunch box" }

const ContestRules = () => {
    const [isOpen, setIsOpen] = useState(false);

    const inputEl = useRef<HTMLInputElement | null>(null);

    const [uploading, setUploading] = useState(false);
    const [selectedRows, setSelectedRows] = React.useState({});
    const handleSelection = React.useCallback((value: any) => {
        setSelectedRows(value);
    }, []);


    const {
        handleSubmit,
        register,
        watch,
        formState: { errors },
    } = useForm<RulesType>({
        mode: "onTouched",
        criteriaMode: "all",
    });
    const formwatch = watch()

    async function getRules(params: string) {

        const res: {
            statsType: string;
            key: string;
            creationTime: string;
            downloadUrl: string;
            fileName: string;
            fileSize: string;
        } = await campaignClient.call("getFiles", {
            dataType: params,
        });
        return res;
    }

    const {
        isLoading: loadingRules,
        data: files,
        refetch: refetchRules,
    } = useQuery("getRulesEn", async () => {

        const rules: any[] = [];

        await Promise.all(Object.keys(rulesTypes).map(async statsType => {
            const english = await getRules(statsType + "_en");
            const french = await getRules(statsType + "_fr");

            if (english.fileName) { rules.push({ ...english, downloadUrl: getFileUrl(english.fileName) }); }
            if (french.fileName) { rules.push({ ...french, downloadUrl: getFileUrl(french.fileName) }); }

        }))

        return rules.sort((a, b) => a.statsType - b.statsType)
    });

    async function uploadFile(event: any) {
        const file = event.target.files[0];
        let ln = "en";
        if (file?.name?.toLowerCase().includes("_fr")) ln = "fr";
        setUploading(true);

        const fileData = await file.arrayBuffer();

        const { uploadUrl,
            key } = await campaignClient.call<{
                uploadUrl: string,
                key: string
            }>("getPublicHostingUrl", { filename: file.name, filetype: file.type, fileSize: file.size })

        await axios
            .put(uploadUrl, fileData, {
                onUploadProgress: function (progressEvent) {

                },
                headers: {
                    "Content-Type": file.type,
                },
            })
        if (key) {
            const { result } = await campaignClient.call<{ result: boolean }>("saveRules", {
                language: ln,
                key: key,
                fileName: file?.name,
                fileSize: file?.size,
                statsType: formwatch.statsType + "_" + formwatch.language
            });
            if (result) {
                setIsOpen(false);
                refetchRules();
                setUploading(false);
                showToast({
                    content: "Saved.",
                    duration: 3000,
                    error: false,
                });
            }
        } else {
            showToast({
                content: "File not saved.",
                duration: 3000,
                error: true,
            });
            setUploading(false);
        }
    }

    const deleteFile = async (dataType: string) => {
        if (!dataType || uploading) {
            return;
        }
        setUploading(true);
        const result = await campaignClient.call("deleteFile", { dataType });
        if (result) {
            refetchRules();
            setUploading(false);
        }
    };

    const fileColumn = fileColumns({ deleteFile });

    if (uploading || loadingRules) {
        return <PageLoader />;
    }

    return (
        <>
            <div className="main__head" style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="main__title">Contest rules</h2>
            </div>

            <div className="main__body main__body--flex main__body--flex-alt">

                <form onSubmit={handleSubmit(() => {
                    if (inputEl !== null && inputEl.current !== null) {
                        inputEl!.current!.click?.();
                    }
                })} className="form">
                    <div style={{ display: "flex" }}>

                        <div className="form-group" style={{ marginRight: "4rem" }}
                        >
                            <select className="field" {...register("statsType", { required: { value: true, message: "Please select a rule type" } })}>
                                <option value="">Select a rule type</option>
                                {Object.keys(rulesTypes).map(k => <option key={k} value={k}>{rulesTypes[k]}</option>)}

                            </select>
                            {errors.statsType && (
                                <p className="error-message">
                                    {errors.statsType.message}{" "}
                                </p>
                            )}
                        </div>

                        <div className="form-group" style={{ marginRight: "10px" }}>
                            <select className="field" {...register("language", { required: { value: true, message: "Please select a language" } })}>
                                <option value="">Select a language</option>
                                <option value="en"> English</option>
                                <option value="fr"> French</option>

                            </select>
                            {errors.language && (
                                <p className="error-message">
                                    {errors.language.message}{" "}
                                </p>
                            )}
                        </div>


                        <button
                            className="btn btn-primary small"
                            style={{ height: "45px" }}
                            type="submit"
                            disabled={uploading ? true : false}
                        >
                            {uploading ? "uploading" : "Upload file"}
                        </button>
                        <input style={{ display: "none" }} type="file" onChange={uploadFile} id="upload" name="upload" accept="application/*" ref={inputEl} />

                    </div>
                </form>

                {files && files?.length ? (
                    <Table columns={fileColumn} data={files ? files : []} tablePageSize={15} checkbox={true} onSelectedRowsChange={handleSelection} />
                ) : (
                    <p style={{ textAlign: "center" }}>There are no records to display</p>
                )}
            </div>

        </>
    );
};

export default ContestRules;
