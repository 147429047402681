import React, { useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { campaignClient, publicCampaignClient, sessionClient } from "../../api";
import { PageLoader, Table } from "../../Components";
import { questionsColumns } from "./QuestionsCol";
import { showToast } from "../../Components/Toast/ToastManager";
import { Link, useLocation, useParams } from "react-router-dom";
import { AddQuestionArgs } from "../../helpers/types";
import CustomModal from "../../Components/CustomModal";
import { findKey, groupBy, map, mapKeys, max, toInteger } from "lodash";
import { EquestionStatus } from "../../helpers/constants";

const EditQuiz = () => {
    const defaultDelete = { question: undefined, isOpen: false };

    const quizParams = useParams();  

    const quiz = quizParams.quiz ? decodeURI(quizParams.quiz) : "";

    const [selectedRows, setSelectedRows] = React.useState({});
    const [selectedQuiz, setSelectedQuiz] = useState("");
    const [deleteQuestion, setDeleteQuestion] = useState<{ question: AddQuestionArgs | undefined; isOpen: boolean }>(defaultDelete);
    const [loading, setLoading] = useState(false);
    const {
        isLoading,
        data: questions,
        refetch: refetchQuestions,
    } = useQuery(["getQuiz", quiz], async () => {
        const res: { questions: AddQuestionArgs[] } = await campaignClient.call("getQuiz", {quiz});
        return res.questions || [];
    });

    const handleSelection = React.useCallback((value: any) => {
        setSelectedRows(value);
    }, []);

    const updateStatus = async ({ question, status }: { question: AddQuestionArgs; status: string }) => {
        if (loading) {
            return;
        }

        setLoading(true);
         const { result, error, message } = await campaignClient.call<{ result: boolean; error?: string; message: "string" }>("questionStatus", { ...question, status });
         refetchQuestions()
        // if (result) {
        //     refetchQuestions();
        //     showToast({
        //         content: "Saved ",
        //         duration: 3000,
        //         error: false,
        //     });
        // } else if (error && message) {
        //     showToast({
        //         content: message,
        //         duration: 3000,
        //         error: true,
        //     });
        // } else {
        //     showToast({
        //         content: "Try again ",
        //         duration: 3000,
        //         error: true,
        //     });
        // }
         setLoading(false);
    };

    const fileColumns = useMemo(()=>{
        if (questions && questions.length > 0){

            const maxOption = max(Object.keys(groupBy(questions, function(q){ return q.options.length}))) || 4

            const fileColumns = questionsColumns({ updateStatus, setDeleteQuestion, maxOption: toInteger(maxOption) })

            return fileColumns
        }
    }, [questions])

    if (isLoading ) {
        return <PageLoader />;
    }

    return (
        <>
            <div className="main__head" style={{ display: "flex", justifyContent: "space-between" }}>
                <Link to="/trivia" className="noUnderline row-icons">
                    <img src="/assets/images/svg/ico-arrow.svg" alt="update" />
                    <h2 className="main__title">EditQuiz</h2>{" "}
                </Link>
            </div>

            <div className="main__body main__body--flex main__body--flex-alt">
                <div className="section__body">
                    <div className="flex-between">
                        <form
                            // onSubmit={
                            //     // handleSubmit(handleSaveChanges)
                            // }
                            className="form"
                            style={{ flexBasis: "300px" }}>
                            <div className="form__row">
                                <label htmlFor="type" className="form__label">
                                    <b>Quiz Title: { questions && questions.length > 0 && questions[0].quizName}</b>
                                </label>
                                {/* <select
                                    className="form-control-select"
                                    defaultValue={selectedQuiz}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setSelectedQuiz(e.currentTarget.value);
                                    }}>
                                    {data?.quizzes && data.quizzes?.map((q) => <option value={q}>{q}</option>)}
                                </select> */}
                            </div>
                        </form>
                        <div className="row-buttons">                            
                            {/* <button
                                disabled={Object.keys(selectedRows).length ? false : true}
                                className="btn__cancel button--small"
                                onClick={async () => {
                                    // if (data && selectedRows && Object.keys(selectedRows)) {
                                    //     const ids = Object.keys(selectedRows);
                                    //     await Promise.all(
                                    //         ids.map(async (r) => {
                                    //             await updateStatus({ question: data.quiz[+r], status: "2" });
                                    //         })
                                    //     );
                                    // }
                                }}>
                                Delete
                            </button> */}
                            <Link className="btn button--small" to={`/trivia/question/${quiz}`}>
                                Add question
                            </Link>
                        </div>
                    </div>

                    <br />
                    {questions && fileColumns && questions.length > 0 ? (
                        <Table columns={fileColumns} data={questions} tablePageSize={15} checkbox={true} onSelectedRowsChange={handleSelection} />
                    ) : (
                        <p style={{ textAlign: "center" }}>There are no records to display</p>
                    )}
                </div>
                <div className="section__actions">
                    <div className="paging">{/* <ReactPaginate /> */}</div>
                </div>
            </div>

            <CustomModal
                isOpen={deleteQuestion.isOpen}
                setIsOpen={() => {
                    setDeleteQuestion(defaultDelete);
                }}
                setAction={() => {
                    deleteQuestion.question && updateStatus({ question: deleteQuestion.question, status: EquestionStatus.deleted });
                }}
                actionTitle="Delete"
                message={deleteQuestion.question?.question?.en}
                loading={isLoading}
                header="Are you sure you would like to delete a question?"
            />
        </>
    );
};

export default EditQuiz;
