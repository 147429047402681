import { Row, TableProps } from "react-table";

export const TableColumns = () => {
    const COLUMNS = [
        {
            Header: "Enabled message",
            accessor: "actions",
            width: "1%",
        },
        {
            Header: "Create Time",
            sortType: (rowA: Row, rowB: Row, columnId: number) => {
                if (rowA.values[columnId] > rowB.values[columnId]) return 1;
                if (rowB.values[columnId] > rowA.values[columnId]) return -1;
                return 0;
            },
            accessor: "creationTime",
            Cell: (TableInfo: TableProps) => {
                const dateString = TableInfo.data[TableInfo.row.index].creationTime.toLocaleString();
                return dateString;
            },
        },

        {
            Header: "Full Name",
            accessor: "fullName",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].fullName ? (
                    <a className="link-more link-more--alt" href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
                        {TableInfo.data[TableInfo.row.index].fullName}
                    </a>
                ) : (
                    "N/A"
                );
            },
        },

        {
            Header: "Email Address",
            accessor: "email",
            width: "8%",

            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].email ? (
                    <a className="link-more link-more--alt" href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
                        {TableInfo.data[TableInfo.row.index].email}
                    </a>
                ) : (
                    "N/A"
                );
            },
        },

        {
            Header: "Message",
            accessor: "message",
            width: "30%",
        },
        // {
        //     Header: "Status",
        //     accessor: "status",
        //     width: "5%",
        // },

        // {
        //     Header: "Send",
        //     width: "10%",
        //     accessor: "feedTheDreamAction",
        // },
    ];
    return COLUMNS;
};
