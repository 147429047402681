import { Link } from "react-router-dom";
import { Row, TableProps } from "react-table";
import { formatSizeUnits } from "../../helpers/utils";
import dayjs from "dayjs";
import { SurveyType } from "./AddSurveyQuestion";

export const questionsColumns = ({ updateStatus, setDeleteQuestion, updateSequence }: { setDeleteQuestion: Function; updateStatus: Function; updateSequence: Function }) => {
    const COLUMNS = [
        {
            Header: "#",
            accessor: "q_seq",
            width: "3%",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.row.index + 1;
            },
        },
        {
            Header: "Visible",
            accessor: "visible",
            width: "5%",
            Cell: (TableInfo: TableProps) => {
                const isVisible = TableInfo.data[TableInfo.row.index].status === "1";
                return TableInfo.data[TableInfo.row.index].status ? (
                    <div className="textCenter">
                        <button
                            className={isVisible ? "btn-approve" : "btn-decline"}
                            onClick={() => updateStatus({ question: TableInfo.data[TableInfo.row.index], status: isVisible ? "0" : "1" })}>
                            <img src={!isVisible ? "/assets/images/icons/eye-off.svg" : "/assets/images/icons/eye.svg"} alt="visible" />
                        </button>
                    </div>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Question",
            accessor: "question",
            with: "30%",
            Cell: (TableInfo: TableProps) => {
                const en = TableInfo.data[TableInfo.row.index].question.en ?? "N/A";
                const fr = TableInfo.data[TableInfo.row.index].question.fr ?? "N/A";

                return TableInfo.data[TableInfo.row.index].status ? (
                    <div className="">
                        <p>
                            EN: {en}
                            <br />
                            FR: {fr}
                        </p>
                    </div>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Question type",
            accessor: "type",
            width: "10%",
            Cell: (TableInfo: TableProps) => {
                const type: SurveyType = TableInfo.data[TableInfo.row.index].type;
                return type === "radio" ? "Radio button" : type === "text" ? "Text Box" : "Free input";
            },
        },
        {
            Header: `Answers`,
            accessor: "options ",
            with: "8%",

            Cell: (TableInfo: TableProps) => {
                const isValid = TableInfo.data[TableInfo.row.index]?.options?.length >= 0 + 1;
                const en = isValid ? TableInfo.data[TableInfo.row.index]?.options?.map((o: any) => o.label_en).join(" / ") : "N/A";
                const fr = isValid ? TableInfo.data[TableInfo.row.index]?.options?.map((o: any) => o.label_fr).join(" / ") : "N/A";
                const type = TableInfo.data[TableInfo.row.index].type;
                return type === "free" || type === "text" ? (
                    "N/A"
                ) : (
                    <p>
                        EN: {en}
                        <br />
                        FR: {fr}
                    </p>
                );
            },
        },
        {
            Header: "Modified",
            accessor: "updateTime",
            width: "8%",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].updateTime ? dayjs(TableInfo.data[TableInfo.row.index].updateTime).format("MMMM DD, YYYY") : "N/A";
            },
        },

        {
            Header: " ",
            accessor: "actions",
            width: "15%",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].statsType ? (
                    <div className="row-icons">
                        <button
                            className="btn-icon"
                            disabled={!TableInfo.row.index}
                            onClick={() => updateSequence({ questionUp: TableInfo.data[TableInfo.row.index], questionDown: TableInfo.data[TableInfo.row.index - 1] })}>
                            <img src="/assets/images/icons/arrow-up.svg" alt="arrow_up" />
                        </button>
                        <button
                            className="btn-icon"
                            disabled={TableInfo.row.index >= TableInfo.data.length - 1}
                            onClick={() => updateSequence({ questionUp: TableInfo.data[TableInfo.row.index + 1], questionDown: TableInfo.data[TableInfo.row.index] })}>
                            <img src="/assets/images/icons/arrow-down.svg" alt="arrow_down" />
                        </button>{" "}
                        <Link className="btn-icon textCenter" to={`/updateSurveyQuestion/${TableInfo.data[TableInfo.row.index].id}`}>
                            <img src="/assets/images/icons/udate.svg" alt="update" />
                        </Link>
                        <button className="btn-icon" onClick={() => setDeleteQuestion({ question: TableInfo.data[TableInfo.row.index], isOpen: true })}>
                            <img src="/assets/images/icons/trash.svg" alt="delete" />
                        </button>
                    </div>
                ) : (
                    "N/A"
                );
            },
        },
    ];
    return COLUMNS;
};
