import { Route, Navigate } from "react-router-dom";
import { Winners, NoMatch, Overview, Participants, Participant } from "../Pages";
import { Layout } from "../Components";
import Messages from "../Pages/Messages";
import AthleteSpotlights from "../Pages/AthleteSpotlights";
import ContestRules from "../Pages/ContestRules";
import LiveEvents from "../Pages/LiveEvents";
import Survey from "../Pages/Survey";
import AddSurveyQuestion from "../Pages/Survey/AddSurveyQuestion";
import AddTriviaQuestion from "../Pages/Trivia/AddTriviaQuestion";
import Trivia from "../Pages/Trivia";
import UpdateTriviaQuestion from "../Pages/Trivia/UpdateTriviaQuestion";
import UpdateSurveyQuestion from "../Pages/Survey/UpdateSurveyQuestion";
import EditQuiz from "../Pages/Trivia/EditQuiz";
import Champion from "../Pages/Champion";
import Scrap from "../Pages/Scrap";

export default (
    <>
        <Route path="/" element={<Layout />}>
            <Route index element={<Navigate to="/overview" />} />
            <Route path="overview" element={<Overview />} />
            <Route path="participants" element={<Participants />} />
            <Route path="participant/:key" element={<Participant />} />
            <Route path="winners" element={<Winners />} />
            <Route path="messages" element={<Messages />} />
            <Route path="spotlights" element={<AthleteSpotlights />} />
            <Route path="rules" element={<ContestRules />} />
            {/* <Route path="events" element={<LiveEvents />} /> */}
            <Route path="survey" element={<Survey />} />
            <Route path="addSurveyQuestion" element={<AddSurveyQuestion />} />
            <Route path="updateSurveyQuestion/:id" element={<UpdateSurveyQuestion />} />

            <Route path="trivia" >
                <Route index element={<Trivia />} />                
                <Route path="add/:quiz" element={<AddTriviaQuestion />} />
                <Route path="edit/:quiz" element={<EditQuiz />} />
                <Route path="question/:quiz/:qid?" element={<UpdateTriviaQuestion />} />
            </Route>
           

            <Route path="dreamChampion" element={<Champion type={"dream"} />} />
            <Route path="weeklyChallenge" element={<Champion type={"weekly"} />} />
            <Route path="scrapBook" element={<Scrap/>} />




            <Route path="*" element={<NoMatch />} />
        </Route>
    </>
);
