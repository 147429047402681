import { Row, TableProps } from "react-table";
import { AddQuestionArgs } from "../../helpers/types";
import { concat, head, initial, last, tail } from "lodash";
import { ESurveyWinnerStatus } from "../../helpers/constants";

type WinnerColProps = { selectWinner: Function, resendClaimEmail: Function, selectSurveyWinner: Function, questions?: AddQuestionArgs[], prizeKey?: string | undefined, selectType?: string, setPrizeError?: Function }

export function multiSelectFilter(rows: any, columnIds: any, filterValue: string[]) {

    return filterValue.length === 0
        ? rows
        : rows.filter((row: any) => {
            //filterValue.includes(row.original[columnIds[0]])   
            return filterValue.some(v => row.original[columnIds[0]] && row.original[columnIds[0]].includes(v))
        });
}

export const TableColumns = ({ selectWinner, resendClaimEmail, selectSurveyWinner, questions, prizeKey, selectType, setPrizeError }: WinnerColProps) => {


    const COLUMNS = [
        {
            Header: "No.",
            accessor: "colNumber",
            width: "3.6%",
            Cell: (TableInfo: TableProps) => {
                const nmb = TableInfo.row.index + 1;
                return nmb;
            },
        },
        {
            Header: "Draw Date",
            accessor: "drawDate",
            width: "3.6%",
            sortType: (rowA: Row, rowB: Row, columnId: number) => {
                if (rowA.values[columnId] > rowB.values[columnId]) return 1;
                if (rowB.values[columnId] > rowA.values[columnId]) return -1;
                return 0;
            },
            Cell: (TableInfo: TableProps) => {

                const dateString = TableInfo.data[TableInfo.row.index].creationDate ? new Date(TableInfo.data[TableInfo.row.index].creationDate).toLocaleString() : "";
                return dateString;
            },

        },

        {
            Header: "No.",
            accessor: "creationDate",
            width: "3.6%",
            filter: multiSelectFilter,

        },
        {
            Header: "First Name",
            accessor: "firstName",
            width: "7.7%",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].firstName ? (
                    <a className="link-more link-more--alt" href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
                        {TableInfo.data[TableInfo.row.index].firstName}
                    </a>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Last Name",
            accessor: "lastName",
            width: "7.7%",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].lastName ? (
                    <a className="link-more link-more--alt" href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
                        {TableInfo.data[TableInfo.row.index].lastName}
                    </a>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Email Address",
            accessor: "email",
            width: "13.1%",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].email ? (
                    <a className="link-more link-more--alt" href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
                        {TableInfo.data[TableInfo.row.index].email}
                    </a>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Winner Type",
            accessor: "winnerType",
            width: "5.1%",
            // Cell: (TableInfo: TableProps) => {
            //     return TableInfo.data[TableInfo.row.index].prize ? TableInfo.data[TableInfo.row.index].prize : "N/A";
            // },
        },
        {
            Header: "Prize",
            accessor: "prize",
            width: "5.1%",
            // Cell: (TableInfo: TableProps) => {
            //     return TableInfo.data[TableInfo.row.index].prize ? TableInfo.data[TableInfo.row.index].prize : "N/A";
            // },
        },
        {
            Header: "Prize Claimed Time",
            accessor: "fulfillTime",
            sortType: (rowA: Row, rowB: Row, columnId: number) => {
                if (rowA.values[columnId] > rowB.values[columnId]) return 1;
                if (rowB.values[columnId] > rowA.values[columnId]) return -1;

                return 0;
            },
            width: "9.3%",
            Cell: (TableInfo: TableProps) => {
                const dateString = TableInfo.data[TableInfo.row.index].fulfillTime ? TableInfo.data[TableInfo.row.index].fulfillTime.toLocaleString() : "";

                return dateString;
            },
        },
        {
            Header: "Status",
            accessor: "status",
        },
        {
            Header: "Last updated",
            accessor: "updateTime",
            sortType: (rowA: Row, rowB: Row, columnId: number) => {
                if (rowA.values[columnId] > rowB.values[columnId]) return 1;
                if (rowB.values[columnId] > rowA.values[columnId]) return -1;
                return 0;
            },
            Cell: (TableInfo: TableProps) => {

                const dateString = TableInfo.data[TableInfo.row.index].updateTime ? new Date(TableInfo.data[TableInfo.row.index].updateTime).toLocaleString() : "";

                return dateString;
            },
        },
        {
            Header: "Survey Answers",
            accessor: "answerForSearch",
            filter: multiSelectFilter
        },
        {
            Header: "",
            accessor: "actions",
            Cell: (TableInfo: TableProps) => {
                let sessionKey = TableInfo.data[TableInfo.row.index].sessionKey
                let prizeWinnerKey = TableInfo.data[TableInfo.row.index].winner
                let isRejected = TableInfo.data[TableInfo.row.index].status === "Rejected"
                let isPending = TableInfo.data[TableInfo.row.index].status === "Pending Claim"
                let isClaimed = TableInfo.data[TableInfo.row.index].status === "Claimed"
                let status = TableInfo.data[TableInfo.row.index].status
                let survey = TableInfo.data[TableInfo.row.index].prize === "survey"
                let trivia = selectType === "trivia"


                if (isClaimed) {
                    return <></>
                }

                return (
                    <div className="row-buttons">
                        {(!status || isRejected) &&
                            <button
                                className={`btn btn--mobile-small btn--small`}
                                style={{ backgroundColor: "#47912F", color: "#fff" }}
                                onClick={() => {

                                    if (survey) {
                                        selectSurveyWinner({
                                            type: ESurveyWinnerStatus.win,
                                            sessionKey,
                                        });
                                    } else if (trivia) {
                                        if (!prizeKey && setPrizeError) {
                                            setPrizeError("please select a prize to approve.")
                                        }
                                        else {
                                            selectWinner({
                                                grandPrizeDrawRankingKey: TableInfo.data[TableInfo.row.index].grandPrizeDrawRankingKey,
                                                grandPrizeDrawKey: TableInfo.data[TableInfo.row.index].grandPrizeDrawKey,
                                                type: "assign",
                                                email: TableInfo.data[TableInfo.row.index].email,
                                                prizeKey
                                            });
                                        }

                                    }
                                    else {
                                        selectWinner({
                                            grandPrizeDrawRankingKey: TableInfo.data[TableInfo.row.index].grandPrizeDrawRankingKey,
                                            grandPrizeDrawKey: TableInfo.data[TableInfo.row.index].grandPrizeDrawKey,
                                            type: "assign",
                                            email: TableInfo.data[TableInfo.row.index].email,
                                        });
                                    }

                                }}>
                                Approve
                            </button>
                        }
                        {
                            isPending && <button className="btn btn--small btn--mobile-small" onClick={() => { resendClaimEmail({ sessionKey, prizeWinnerKey }) }}> Resend Email</button>
                        }
                        {(!status || !isRejected) &&
                            <button
                                className={`btn btn--mobile-small btn--small`}
                                style={{ backgroundColor: "#ED1C24", color: "#fff" }}

                                onClick={() => {

                                    if (survey) {
                                        selectSurveyWinner(TableInfo.data[TableInfo.row.index].winner ? {
                                            type: ESurveyWinnerStatus.reject,
                                            prizeWinnerKey: TableInfo.data[TableInfo.row.index].winner,
                                            sessionKey,
                                        } : {
                                            type: ESurveyWinnerStatus.decline,
                                            sessionKey
                                        })
                                    } else {
                                        selectWinner({
                                            grandPrizeDrawRankingKey: TableInfo.data[TableInfo.row.index].grandPrizeDrawRankingKey,
                                            grandPrizeDrawKey: TableInfo.data[TableInfo.row.index].grandPrizeDrawKey,
                                            type: "reject",
                                            email: TableInfo.data[TableInfo.row.index].email,
                                        });
                                    }

                                }}>
                                Decline
                            </button>}
                    </div>
                );
            },
        },

    ];

    if (questions && questions.length > 0) {

        const tailColumn = last(COLUMNS)
        const initialColumn = initial(COLUMNS)
        questions.forEach(q => {
            initialColumn.push({
                Header: q.question.en,
                accessor: q.id,
                Cell: (TableInfo: TableProps) => {
                    return <>{TableInfo.data[TableInfo.row.index]?.answers ? TableInfo.data[TableInfo.row.index]?.answers[q?.id] : "N/A"}</>
                }
            })
        })

        return concat(initialColumn, tailColumn)

    }


    return COLUMNS;
};
