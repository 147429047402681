import { useMutation, useQuery, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { showToast } from "../../Components/Toast/ToastManager";
import { campaignClient } from "../../api";
import { Dictionary, assignWith, keyBy, mapValues } from 'lodash';
import { Button } from 'react-bootstrap';
import { MESSAGES } from '../../helpers/constants';

type Props = {}

type Enabled = { [key: string]: number }

const PAGE_LIST = [
    { title: "Trivia game", name: "trivia", enabled: false },
    { title: "Send a message", name: "message", enabled: false },
    { title: "Correspondent program", name: "survey", enabled: false },
    { title: "Athlete spotlight", name: "spotlight", enabled: false },
    { title: "Photo submissions", name: "photo", enabled: false },
    { title: "Weekly challenges", name: "weeklyChallenges", enabled: false },
    { title: "Scrapbook", name: "scrapbook", enabled: false },

]

function EnablePages({ }: Props) {

    const queryClient = useQueryClient();

    const {
        handleSubmit,
        register,
        formState: { isSubmitting },
        watch, reset
    } = useForm<Enabled>({
        mode: "onTouched",
        criteriaMode: "all",
    });

    const {
        isLoading: isLoadingEnabled,
        data: enabled,
    } = useQuery("getEnabled", async () => {
        const res: { data: { pages: Enabled } } = await campaignClient.call("getData", { statsType: "enable" });
        reset(res.data.pages)
        return res.data.pages;
    });


    const mutation = useMutation((pages: { [key: string]: number }) => {
        return campaignClient.call<{ result: boolean }>("saveEnable", {
            pages,
        })
    }, {
        onSuccess(data) {
            if (data.result) {
                showToast({
                    content: MESSAGES.SUCCESS,
                    duration: 3000,
                    error: false,
                });
            } else {
                showToast({
                    content: MESSAGES.INVALID_RESPONSE,
                    duration: 3000,
                    error: true,
                });
            }

            queryClient.invalidateQueries('getEnabled')
        },
        onError(error) {
            showToast({
                content: (error as Error).toString(),
                duration: 3000,
                error: true,
            });
        },
    })


    const handleSaveChanges = async (data: Enabled) => {

        mutation.mutate(data);

    };

    if (isLoadingEnabled) {
        return <></>
    }

    return (
        <div className="card" style={{ maxWidth: "600px" }}>
            <div className="card-body">
                <form onSubmit={handleSubmit(handleSaveChanges)} className="form">
                    <div className="form__label--alt paddingBottom">
                        <b>Enabled pages</b>
                    </div>
                    <div className="form__label--alt paddingBottom">Use this section to enable and disable the pages on the microsite.</div>

                    {
                        PAGE_LIST.map(p => {
                            return <div className="form__row " key={p.name} style={{ width: "25rem" }}>
                                <label style={{ marginBottom: "5px", fontWeight: "600" }}>{p.title}</label>
                                <select className="select field" {...register(p.name)}>
                                    <option value="1">Active</option>
                                    <option value="2">Coming soon</option>
                                    <option value="0">Hidden</option>
                                </select>
                            </div>
                        })
                    }

                    <br />
                    <br />
                    <div className="flexEnd">
                        <Button className="btn button--small" type="submit" disabled={isSubmitting} >
                            {isSubmitting ? 'Saving…' : 'Save changes'}
                        </Button>
                    </div>
                </form>
            </div>


        </div>
    )
}

export default EnablePages