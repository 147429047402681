import { useMemo } from 'react';
import { useQuery } from "react-query";
import { groupBy, mapValues} from "lodash";
import { campaignClient } from "../../api";
import { PageLoader, Table } from "../../Components";
import { showToast } from "../../Components/Toast/ToastManager";
import { Button, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AddQuestionArgs, TriviaAnswerReturn } from "../../helpers/types";
import { ResultColumns } from "./ResultCol";




type Props = {
    quizId: string
}

export default function ParticipantTrivia({ quizId }: Props) {

    const {
        isLoading,
        data,
    } = useQuery("getQuiz", async () => {
        const res: { currentActive: string; quizes: { [key: string]: AddQuestionArgs[] } } = await campaignClient.call("getQuiz", {});
        return { ...res, quizzes: Object.keys(res.quizes).length > 0 ? Object.keys(res.quizes) : [] };
    });

    const {
        isLoading: isAnswersLoading,
        data: participantAnswers,
    } = useQuery(["getResults", { statsType: "trivia", quiz: quizId }], async () => {
        const res = await campaignClient.call<{ items: TriviaAnswerReturn[] }>("getResults", { statsType: "trivia", quiz: quizId });
        return res.items;
    }, { enabled: !!quizId }
    );   

    const questions = useMemo(() => {

        if (data && quizId && participantAnswers) {

            const questions = data.quizes?.[quizId];

            const questionGroup = groupBy(questions, function (q) { return q.question.id })

            const questionResults = mapValues(questionGroup, function (g) {

                const options = groupBy(g[0].options, 'id')
                return { id: g[0].id, answer: g[0].answer, question: g[0].question, q_seq: g[0].q_seq, options: mapValues(options, function (o) { return { ...o[0], count: 0 } }) }
            })
            // console.log("QUestion GROUP",  questionResults)

            for (let participant of participantAnswers) {

                for (let answer of participant.answers) {
                    if (questionResults[answer.id] && questionResults[answer.id].options[answer.answer]) {

                        ++questionResults[answer.id].options[answer.answer].count
                    }

                }

            }

            //console.log("Final", questionResults)
            return questionResults;
        }



    }, [data, quizId, participantAnswers]);

    if (isLoading || !data) {
        return <PageLoader />
    }


    return (
        <div className="table">
            
            <br />
            {questions && participantAnswers && participantAnswers.length > 0 ? (
                <ResultColumns questions={questions} Total={participantAnswers.length} />

            ) : (
                <p className="text-center py-5 fs-2">There are no records to display</p>
            )}
        </div>
    )
}