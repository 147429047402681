import { downloadImage } from '../../Pages/Champion';

const DownloadImage = ({ imageName, imageUrl, imageKey }: { imageUrl: string, imageName: string, imageKey:string }) => {
    const handleDownload = async () => {
        try {

            const res = await downloadImage(imageKey);

            const image = await fetch(res);          
            const imageBlog = await image.blob()
            const imageURL = URL.createObjectURL(imageBlog)
          
            const link = document.createElement('a')
            link.href = imageURL
            link.download = imageName
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
           
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    };

    return (
        <button onClick={handleDownload} className='btn btn--small'>Download Image</button>
    );
};

export default DownloadImage;