import { useMemo } from "react";
import { campaignClient } from "../../api";
import { useQuery, useQueryClient } from "react-query";
import { PageLoader } from "../../Components";
import { Link, useParams } from "react-router-dom";
import { AddQuestionArgs } from "../../helpers/types";
import { QuizForm } from "./QuizForm";
import { getQuestion, questionAdaptor } from "../../helpers/utils";

function UpdateTriviaQuestion() {

    const { quiz, qid } = useParams();
   
    const queryClient = useQueryClient();

    const initialQuiz = Array.from(Array(1).keys()).map(() => {
        return getQuestion()
    })

    const {
        isLoading,
        data: questions,       
    } = useQuery(["getQuiz", quiz], async () => {
        const res = await campaignClient.call<{questions: AddQuestionArgs[]}>("getQuiz", { quiz });
        return res.questions ? res.questions.map(q=>questionAdaptor(q)) : []
    });


    const question = useMemo(()=>{
        if (questions && questions.length > 0){
            return questions.filter(q=>q.qid === qid)
        }
    }, [questions])

    if (isLoading || !questions || !question) {
        return <PageLoader />;
    }

    return (
        <>
        <div className="main__head" style={{ display: "flex", justifyContent: "space-between" }}>
                <Link to={`/trivia/edit/${quiz}`} className="noUnderline row-icons">
                    <img src="/assets/images/svg/ico-arrow.svg" alt="update" />
                    <h2 className="main__title">Edit a trivia Question</h2>{" "}
                </Link>
            </div>  

            <QuizForm FormValues={{
            quiz: question && question.length > 0  ? question : initialQuiz,
            quizName: question && question.length > 0 ? question[0].quizName : questions[0].quizName,
            quizId: quiz || "",           
            }} 
        queryClient= { queryClient }
        backPath={`/trivia/edit/${quiz}`}
        questionCount ={ questions? questions.length : 0 }
        questionId = {qid ? qid : initialQuiz[0].qid}
        />  
           
        </>
    );
}

export default UpdateTriviaQuestion;
