import { Link } from "react-router-dom";
import { Row, TableProps } from "react-table";
import { formatSizeUnits } from "../../helpers/utils";

const rulesTypes: { [key: string]: string } =
{
    "rules_en": "Contest rules",
    "rules_fr": "Contest rules",
    "champion_en": "Dream champion",
    "champion_fr": "Dream champion",
    "weekly_en": "Weekly challenges",
    "weekly_fr": "Weekly challenges",
    "trivia_en": "Trivia",
    "trivia_fr": "Trivia",
    "lunchbox_en": "Lunch box",
    "lunchbox_fr": "Lunch box"

}


export const fileColumns = ({ deleteFile }: { deleteFile: Function }) => {
    const COLUMNS = [
        {
            Header: "Rules Type",
            accessor: "statsType",
            Cell: (TableInfo: TableProps) => {
                return (
                    <Link className="link-more link-more--alt" target="_blank" to={TableInfo.data[TableInfo.row.index].downloadUrl}>
                        {rulesTypes[TableInfo.data[TableInfo.row.index].statsType]}
                    </Link>
                );
            },
            
        },
        {
            Header: "Language",
            accessor: "language",
            Cell: (TableInfo: TableProps) => {
                return (
                    TableInfo.data[TableInfo.row.index].statsType.split("_")[1]
                );
            },

        },
        {
            Header: "Name",
            accessor: "fileName",

        },
        {
            Header: "Modified",
            accessor: "creationTime",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].creationTime ? new Date(TableInfo.data[TableInfo.row.index].creationTime).toLocaleString() : "N/A";
            },
        },

        {
            Header: "File size ",
            accessor: "fileSize",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].fileSize ? formatSizeUnits(TableInfo.data[TableInfo.row.index].fileSize) : "N/A";
            },
        },
        {
            Header: " ",
            accessor: "delete",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].statsType ? (
                    <button className="btn-icon" onClick={() => deleteFile(TableInfo.data[TableInfo.row.index].statsType)}>
                        <img src="/assets/images/icons/trash.svg" alt="delete" />
                    </button>
                ) : (
                    "N/A"
                );
            },
        },
    ];
    return COLUMNS;
};
