import React, { useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as XLSX from 'xlsx';
import { campaignClient } from "../../api";
import { PageLoader, Table } from "../../Components";
import { showToast } from "../../Components/Toast/ToastManager";
import { Button, Nav, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AddQuestionArgs, TriviaAnswerReturn } from "../../helpers/types";
import CustomModal from "../../Components/CustomModal";
import { quizColumns } from "./QuizCol";
import { groupBy, map, mapKeys, mapValues, pick } from "lodash";
import ParticipantTrivia from "./ParticipantTrivia";
import { Participants } from "@sprycore/spry-api-client/dist/MainDbReturnTypes";
import { byKey } from "../../helpers/utils";
import dayjs from "dayjs";

const Trivia = () => {

    const queryClient = useQueryClient();

    const [key, setKey] = useState("quizzes");

    const defaultDelete = { quiz: "", isOpen: false, quizName: "" };

    const [downloading, setDownloading] = useState(false);

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    const [deleteQuiz, setDeleteQuiz] = useState<{ quiz: string; isOpen: boolean, quizName: string }>(defaultDelete);

    const [selectedQuiz, setSelectedQuiz] = useState<string>("");

    const quizDeleteMutation = useMutation((data: string[]) => {
        return Promise.all(data.map(d => campaignClient.call<{ result: boolean }>("deleteQuiz", { quiz: d })))
    }, {
        onSuccess(data) {
            showToast({
                content: "Delete Successfully",
                duration: 3000,
                error: false,
            });
            queryClient.invalidateQueries('getQuiz')
        },
        onError(error) {
            showToast({
                content: (error as Error).toString(),
                duration: 3000,
                error: true,
            });
        },
    })

    const {
        isLoading,
        data,
        refetch: refetchQuizzes,
    } = useQuery("getQuiz", async () => {
        const res: { currentActive: string; quizes: { [key: string]: AddQuestionArgs[] } } = await campaignClient.call("getQuiz", {});
        return { ...res, quizzes: Object.keys(res.quizes).length > 0 ? Object.keys(res.quizes) : [] };
    });

    const quizzes = useMemo(() => {
        if (data && data.quizes) {

            return Object.entries(data.quizes).map((quiz) => {
                const status = data.currentActive === quiz[0] ? "1" : "0";
                return { quizLength: quiz[1]?.length, quizName: quiz[1][0].quizName, quiz: quiz[0], status, updateTime: quiz[1]?.[0]?.updateTime };
            });
        }
    }, [data]);

    // const questions = useMemo(() => {
    //     if (data) {
    //         return data.quizes?.[selectedQuiz];
    //     }
    // }, [data, selectedQuiz]);

    // const handleSelection = React.useCallback((value: any) => {
    //     console.log("check box ", value)
    //     setSelectedRows(value);
    // }, []);

    const handleKeySelection = (key: string | null) => {
        if (key) {
            setKey(key);
            if (key === "quizzes") {
                setSelectedQuiz("")
            }
        }
    };

    const updateStatus = async (data: { quiz: string; status: string }) => {

        const result = await campaignClient.call("quizStatus", { ...data });
        queryClient.invalidateQueries("getQuiz");
        if (result) {
           
            showToast({
                content: "Saved ",
                duration: 3000,
                error: false,
            });
        } else {
            showToast({
                content: "Try again ",
                duration: 3000,
                error: true,
            });
        }
    };
    const removeQuiz = async (quiz: string) => {

        quizDeleteMutation.mutate([quiz]);

        // const result = await campaignClient.call("deleteQuiz", { quiz });
        // if (result) {
        //     refetchQuizzes();
        //     showToast({
        //         content: "Saved ",
        //         duration: 3000,
        //         error: false,
        //     });
        // } else {
        //     showToast({
        //         content: "Try again ",
        //         duration: 3000,
        //         error: true,
        //     });
        // }
    };

    const fileColumns = useMemo(() => {

        return quizColumns({ updateStatus, setDeleteQuiz });

    }, [updateStatus, setDeleteQuiz]);

    const handleFileDownload = async () => {
        setDownloading(true)

        if (selectedQuiz && data) {
            const res = await campaignClient.call<Participants>("getParticipants", {});
            const resResults = await campaignClient.call<{ items: TriviaAnswerReturn[] }>("getResults", { statsType: "trivia", quiz: selectedQuiz });
            const questions = data.quizes?.[selectedQuiz];

            const participantByKey = byKey(res.participants, (s) => { return s.sessionKey });

            const questionGroup = groupBy(questions, function (q) { return q.question.id })

            const questionResults = mapValues(questionGroup, function (g) {

                const options = groupBy(g[0].options, 'id')
                return { id: g[0].id, answer: g[0].answer, question: g[0].question, q_seq: g[0].q_seq, options: mapValues(options, function (o) { return { ...o[0], count: 0 } }) }
            })

            const questionReport = resResults.items.map(item => {

                let answers: any = {}

                for (let answer of item.answers) {
                    if (questionResults[answer.id]) {

                        answers[answer.id] = questionResults[answer.id].options[answer.answer] ? questionResults[answer.id].options[answer.answer].label_en : "";
                    }

                }

                const newAnswers = mapKeys(answers, function (value, key) {
                    return questionResults[key].question.en
                })


                return {
                    FirstName: participantByKey[item.sessionKey] && participantByKey[item.sessionKey][0].firstName,
                    LastName: participantByKey[item.sessionKey] && participantByKey[item.sessionKey][0].lastName,
                    Email: participantByKey[item.sessionKey] && participantByKey[item.sessionKey][0].email,
                    Province: participantByKey[item.sessionKey] && participantByKey[item.sessionKey][0].province,
                    Language: participantByKey[item.sessionKey] && participantByKey[item.sessionKey][0].preferredLanguage,
                    StoreBanner: participantByKey[item.sessionKey] && participantByKey[item.sessionKey][0].metadata.banner || "",
                    LocationType: participantByKey[item.sessionKey] && participantByKey[item.sessionKey][0].metadata.locationType || "",
                    StoreType: participantByKey[item.sessionKey] && participantByKey[item.sessionKey][0].metadata.storeType || "",
                    Location: participantByKey[item.sessionKey] && participantByKey[item.sessionKey][0].metadata.location || "",
                    StoreNo: participantByKey[item.sessionKey] && participantByKey[item.sessionKey][0].metadata.storeNo || "",
                    EmployeeNo: participantByKey[item.sessionKey] && participantByKey[item.sessionKey][0].metadata.employeeNo || "",
                    Manager: participantByKey[item.sessionKey] && participantByKey[item.sessionKey][0].metadata.manager || "",
                    ...newAnswers,
                    creationTime: item.creatioinTime ? new Date(item.creatioinTime).toLocaleString() : ""


                }
            })

            const worksheet = XLSX.utils.json_to_sheet(questionReport);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet);
            await XLSX.writeFile(workbook, `Trivia-result-${dayjs(new Date).format("YYYYMMDDhms")}.xlsx`, { compression: true });
        }

        setDownloading(false)

    }


    if (isLoading) {
        return <PageLoader />;
    }


    return (
        <>
            <div className="main__head" style={{ display: "flex", justifyContent: "space-between" }}>
                <h2 className="main__title">Trivia</h2>
            </div>

            <div className="main__body main__body--flex main__body--flex-alt">
                <div className="tabs__body">
                    <Tab.Container activeKey={key} onSelect={(key) => handleKeySelection(key)}>
                        <div className="row">
                            <div className="col">
                                <Nav variant={"tabs"} className="flex nav__tabs" as="ul">
                                    <Nav.Item>
                                        <Nav.Link eventKey="quizzes">List of quizzes</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="results">Results</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="col">
                                {
                                    key === "quizzes" ? <div className="d-flex justify-content-end gap-2 ">
                                        <Button className="btn__cancel fs-6"
                                            type="button" disabled={quizDeleteMutation.isLoading || selectedRows.length <= 0}
                                            onClick={async () => {
                                                if (selectedRows.length > 0) {

                                                    quizDeleteMutation.mutate(map(selectedRows, "quiz"))

                                                }
                                            }}
                                        >
                                            {quizDeleteMutation.isLoading ? 'Saving…' : 'Delete'}
                                        </Button>

                                        <Link className="btn fs-6" to={`/trivia/add/${window.crypto.randomUUID()}`}>
                                            New quiz
                                        </Link>

                                    </div> : <div className="d-flex justify-content-end gap-2">

                                        <select
                                            className="form-select w-50"
                                            defaultValue={""}
                                            onChange={(e) => {
                                                setSelectedQuiz(e.currentTarget.value);
                                            }}>
                                            <option value="">Select a quiz</option>
                                            {data?.quizzes && data.quizzes?.map((q) => <option value={q} key={q}>{data.quizes[q][0].quizName || ""}</option>)}
                                        </select>

                                        <Button className=" button--small fs-6"
                                            type="button" disabled={downloading}
                                            onClick={handleFileDownload}
                                        >
                                            {downloading ? 'preparing…' : 'Download Full Report'}
                                        </Button>

                                    </div>
                                }

                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Tab.Content>
                                    <Tab.Pane eventKey="quizzes" title="List of quizzes" className="fs-6">
                                        <div className="section__body">
                                            <div className="table">

                                                <br />
                                                {/* Test dada, has to be replaced*/}
                                                {quizzes && quizzes?.length ? (
                                                    <div className="table-responsive-lg">
                                                        <Table columns={fileColumns} 
                                                        data={quizzes ? quizzes : []} tablePageSize={15} checkbox={true} 
                                                        onSelectedRowsChange={setSelectedRows} />
                                                    </div>
                                                ) : (
                                                    <div className="textCenter">
                                                        <p style={{ textAlign: "center", paddingTop: "3rem" }}>
                                                            <b>No Quiz added yet</b>{" "}
                                                        </p>
                                                        <Link className="btn button--small" to={`/trivia/add/${window.crypto.randomUUID()}`}>
                                                            Add a quiz
                                                        </Link>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="section__actions">
                                            <div className="paging">{/* <ReactPaginate /> */}</div>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="results" title="Results">
                                        <div className="section__body">
                                            <ParticipantTrivia quizId={selectedQuiz} />
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>

                    </Tab.Container>

                </div>
            </div>

            <CustomModal
                isOpen={deleteQuiz.isOpen}
                setIsOpen={() => {
                    setDeleteQuiz(defaultDelete);
                }}
                setAction={() => {
                    removeQuiz(deleteQuiz.quiz);
                }}
                message={deleteQuiz.quizName}
                actionTitle="Delete"
                loading={isLoading}
                header="Are you sure you would like to delete quiz?"
            />
        </>
    );
};

export default Trivia;
