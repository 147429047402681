import { Row, TableProps } from "react-table";
import { getFileUrl } from "../../helpers/utils";
import { useNavigate } from "react-router-dom";

export const TableColumns = ({ deleteAthlete }: { deleteAthlete: Function }) => {
    const navigate = useNavigate()
    const COLUMNS = [
        {
            Header: "No.",
            accessor: "colNumber",
            width: "1%",
            Cell: (TableInfo: TableProps) => {
                const nmb = TableInfo.row.index + 1;
                return nmb;
            },
        },
        {
            Header: "Athlet name",
            accessor: "athletName",
        },
        // {
        //     Header: "Title EN",
        //     accessor: "title_en",
        //     width: "3%",
        // },
        // {
        //     Header: "Title Fr",
        //     accessor: "title_fr",

        // },

        {
            Header: "Video link",
            accessor: "videoLink",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].videoLink ? (
                    <a className="link-more link-more--alt"  rel="noreferrer" href={TableInfo.data[TableInfo.row.index].videoLink} target="_blank">
                        {TableInfo.data[TableInfo.row.index].videoLink}
                    </a>
                ) : (
                    "N/A"
                );
            },
        },

        {
            Header: "Modified",
            sortType: (rowA: Row, rowB: Row, columnId: number) => {
                if (rowA.values[columnId] > rowB.values[columnId]) return 1;
                if (rowB.values[columnId] > rowA.values[columnId]) return -1;
                return 0;
            },
            accessor: "updateTime",
            Cell: (TableInfo: TableProps) => {
                const dateString = new Date(TableInfo.data[TableInfo.row.index].updateTime).toLocaleString();
                return dateString;
            }
        },
        {
            Header: "Thumbnail image",
            accessor: "thumbnail",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].thumbnail ? (
                    <a className="link-more link-more--alt" rel="noreferrer" href={getFileUrl(TableInfo.data[TableInfo.row.index].thumbnail.fileName)} target="_blank">
                        {TableInfo.data[TableInfo.row.index].thumbnail.fileName}
                    </a>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: " ",
            accessor: "actions",
            width: "5%",
            Cell: (TableInfo: TableProps) => {
                return (
                    <div className="row-buttons">
                        <button className="btn-icon" onClick={() => deleteAthlete(TableInfo.data[TableInfo.row.index].id)}>
                            <img src="/assets/images/icons/trash.svg" alt="delete" height={20} style={{ height:"20px" }} />
                        </button>
                        <button
                            className="btn-icon"
                            style={{ flexBasis: "62px"}}
                            onClick={() => {
                                navigate(`/spotlights/?id=${TableInfo.data[TableInfo.row.index].id}`)
                            }}>
                            <img src="/assets/images/icons/udate.svg" alt="update" style={{ height:"20px" }} />
                        </button>
                    </div>
                )
            },
        },
    ];
    return COLUMNS;
};
