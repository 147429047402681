import { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader } from "../../Components";
import { campaignClient } from "../../api";
import { byKey, emailRegex, looseNorthAmericanPhoneRegex, stringRegex } from "../../helpers/utils";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";

type sentEmailResult = {
  campaignKey: string;
  creationTime: Date;
  emailTemplateKey: string;
  fromEmail: string;
  fromName: string;
  lastUpdateTime: Date;
  operationId: string;
  targets: any[];
};

type sentEmailResults = {
  sentEmailResults: sentEmailResult[];
};

interface EmailTemplateHasSubj extends MainDbReturnTypes.EmailTemplate {
  subject: string;
}

type RegisterForm = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  city: string;
  province: string;
  address: string;
  address2: string;
  postal: string;
  metadata: { rules: boolean; optin1: boolean; rules1: boolean };
};

function Participant() {
  const params = useParams();

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);

  const [message, setMessage] = useState({ error: false, msg: "" });

  const [emailParams, setemailParams] = useState<any>();

  const [profile, setProfile] = useState("");

  const getProfile = async () => {
    let user = await Auth.currentAuthenticatedUser();
    setProfile(user.attributes.profile);
  };

  useEffect(() => {
    getProfile();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterForm>();

  const { isLoading: isLoadingParticipant, data: user } = useQuery(["getParticipants", {sessionKey:  params.key}], async () => {
    const res: MainDbReturnTypes.Participants = await campaignClient.call("getParticipants", {
      sessionKey: params.key,
    });
    return res.participants[0];
  });

  const { isLoading: isLoadingWinners, data: prizeWinners } = useQuery(
    ["getPrizeWinners", { sessionKey: params.key }],
    async () => {
      const res: MainDbReturnTypes.PrizeWinners = await campaignClient.call("getPrizeWinners", {
        sessionKey: params.key,
      });

      return res.prizeWinners;
    }
  );

  const { isLoading: isLoadingPrizes, data: prizes } = useQuery("getPrizes", async () => {
    const res: MainDbReturnTypes.Prizes = await campaignClient.call("getPrizes", {});

    const prizes = byKey(res.prizes, (x) => x.prizeKey);

    return prizes;
  });

  const { isLoading: isLoadingPools, data: pools } = useQuery("getPrizePools", async () => {
    const res: MainDbReturnTypes.PrizePools = await campaignClient.call("getPrizePools", {});

    return res.prizePools;
  });

  // const { isLoading: isLoadingEmails, data: sentEmails } = useQuery(
  //   ["getSentEmail", { email: user ? user.email : "" }],
  //   async () => {
  //     const res: sentEmailResults = await campaignClient.call("getSentEmail", {
  //       email: user!.email,
  //     });

  //     return res.sentEmailResults;
  //   },
  //   { enabled: user ? true : false }
  // );

  const { isLoading: isLoadingTemplates, data: templates } = useQuery(
    "getEmailTemplates",
    async () => {
      const res: EmailTemplateHasSubj[] = await campaignClient.call("getEmailTemplates", {});

      return res;
    }
  );

  // const participantEmails = useMemo(() => {
  //   if (sentEmails && templates) {
  //     const templatesByKey = byKey(templates, (x) => x.emailTemplateKey);

  //     return sentEmails.map((v: sentEmailResult) => {
  //       return {
  //         ...v,
  //         subject: templatesByKey[v.emailTemplateKey]
  //           ? templatesByKey[v.emailTemplateKey][0].subject
  //           : "",
  //       };
  //     });
  //   }
  // }, [sentEmails, templates]);

  const winners = useMemo(() => {
    if (prizeWinners && prizes && pools) {
      return prizeWinners.map((v) => {
        return {
          prize: prizes[v.prizeKey] ? prizes[v.prizeKey][0].prizeName : "",
          status: v.declarationAndRelease ? "Claimed" : "Unclaimed",
          poolName: pools[0].prizePoolName,
          winningTime: new Date(v.winningTimestamp!).toLocaleString(),
          prizeWinnerKey: v.prizeWinnerKey,
        };
      });
    }
  }, [prizeWinners, prizes, pools]);

  const resendEmail = async () => {
    const emailResults: any = await campaignClient.call("sendEmail", emailParams);

    if (emailResults && emailResults[0].accepted) {
      setMessage({ ...message, msg: "Email re-sent successfully." });
    } else {
      setMessage({ error: true, msg: "Email re-sent failed." });
    }

    setTimeout(() => {
      setMessage({ error: false, msg: "" });
    }, 2000);

    handleClose();
  };
  async function handleRegister(values: RegisterForm) {
    setIsSubmit(true);

    const params = {
      campaignKey: prizeWinners![0].campaignKey,
      sessionKey: user!.sessionKey,
      ...values,
    };

    try {
      await campaignClient.call("addParticipantData", params);
      setIsSubmit(false);
      setMessage({ ...message, msg: "Successful updated." });
      setTimeout(() => {
        setMessage({ error: false, msg: "" });
      }, 2000);
    } catch (e) {
      setIsSubmit(false);
    }
  }

  const handleClose = () => {
    setShowModal(false);
    setemailParams("");
  };

  if (
    isLoadingParticipant ||
    isLoadingWinners ||
    isLoadingPrizes ||
    isLoadingTemplates ||
    !winners ||
    isLoadingPools
    // ||
    // isLoadingEmails ||
    // !participantEmails
  ) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="main__head">
        <h2 className="main__title">Participant details</h2>
        <div className="main__actions">
          <a
            href="#"
            className="btn-back"
            onClick={(e) => {
              e.preventDefault();
              navigate("/participants");
            }}>
            <svg className="ico-arrow">
              <image xlinkHref="/assets/images/svg/ico-arrow.svg"></image>
            </svg>
          </a>
        </div>
      </div>
      <div className="main__body main__body--flex">
        <div className="main__form form">
          <form onSubmit={handleSubmit(handleRegister)}>
            <div className="form__body">
              <h4 className="form__title">Info</h4>

              <div className="form__row">
                <label htmlFor="firstName" className="form__label">
                  First name
                </label>

                <div className="form__controls">
                  <input
                    type="text"
                    className="field"
                    id="firstName"
                    {...register("firstName", {
                      required: {
                        value: true,
                        message: "Please enter first name.",
                      },
                      pattern: {
                        value: stringRegex,
                        message: "Please enter a valid first name.",
                      },
                    })}
                    defaultValue={user?.firstName!}
                    disabled={profile !== "Admin"}
                  />
                </div>
                {errors.firstName && (
                  <p className="error-message">
                    <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                    {errors.firstName.message}
                  </p>
                )}
              </div>

              <div className="form__row">
                <label htmlFor="lastName" className="form__label">
                  Last name
                </label>

                <div className="form__controls">
                  <input
                    type="text"
                    className="field"
                    id="lastName"
                    {...register("lastName", {
                      required: {
                        value: true,
                        message: "Please enter last name.",
                      },
                      pattern: {
                        value: stringRegex,
                        message: "Please enter a valid last name.",
                      },
                    })}
                    defaultValue={user?.lastName!}
                    disabled={profile !== "Admin"}
                  />
                </div>
                {errors.lastName && (
                  <p className="error-message">
                    <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                    {errors.lastName.message}
                  </p>
                )}
              </div>
              {user?.email && (
                <div className="form__row">
                  <label htmlFor="email" className="form__label">
                    Email
                  </label>

                  <div className="form__controls">
                    <input
                      type="text"
                      className="field"
                      {...register("email", {
                        required: {
                          value: true,
                          message: "Please enter email.",
                        },
                        pattern: {
                          value: emailRegex,
                          message: "Please enter a valid email.",
                        },
                      })}
                      defaultValue={user.email}
                      disabled={profile !== "Admin"}
                    />
                  </div>
                  {errors.email && (
                    <p className="error-message">
                      <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                      {errors.email.message}
                    </p>
                  )}
                </div>
              )}

              {user?.phone && (
                <div className="form__row">
                  <label htmlFor="phone" className="form__label">
                    Phone number
                  </label>

                  <div className="form__controls">
                    <input
                      type="text"
                      className="field"
                      {...register("phone", {
                        required: {
                          value: true,
                          message: "Please enter phone.",
                        },
                        pattern: {
                          value: looseNorthAmericanPhoneRegex,
                          message: "Please enter a valid phone.",
                        },
                      })}
                      defaultValue={user?.phone!}
                      disabled={profile !== "Admin"}
                    />
                  </div>
                  {errors.phone && (
                    <p className="error-message">
                      <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                      {errors.phone.message}
                    </p>
                  )}
                </div>
              )}

              {user?.postal && (
                <div className="form__row">
                  <label htmlFor="postal" className="form__label">
                    Postal
                  </label>

                  <div className="form__controls">
                    <input
                      type="text"
                      className="field"
                      {...register("postal", {
                        required: {
                          value: true,
                          message: "Please enter postal.",
                        },
                      })}
                      defaultValue={user?.postal!}
                      disabled={profile !== "Admin"}
                    />
                  </div>
                  {errors.postal && (
                    <p className="error-message">
                      <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                      {errors.postal.message}
                    </p>
                  )}
                </div>
              )}
              {user?.address && (
                <div className="form__row">
                  <label htmlFor="address" className="form__label">
                    Address
                  </label>

                  <div className="form__controls">
                    <input
                      type="text"
                      className="field"
                      {...register("address", {
                        required: {
                          value: true,
                          message: "Please enter address.",
                        },
                      })}
                      defaultValue={user?.address!}
                      disabled={profile !== "Admin"}
                    />
                  </div>
                  {errors.address && (
                    <p className="error-message">
                      <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                      {errors.address.message}
                    </p>
                  )}
                </div>
              )}
              {user?.address && (
                <div className="form__row">
                  <label htmlFor="address2" className="form__label">
                    Address 2
                  </label>

                  <div className="form__controls">
                    <input
                      type="text"
                      className="field"
                      {...register("address2")}
                      defaultValue={user?.address2!}
                      disabled={profile !== "Admin"}
                    />
                  </div>
                </div>
              )}
              {user?.city && (
                <div className="form__row">
                  <label htmlFor="city" className="form__label">
                    City
                  </label>

                  <div className="form__controls">
                    <input
                      type="text"
                      className="field"
                      {...register("city")}
                      defaultValue={user.city}
                      disabled={profile !== "Admin"}
                    />
                  </div>
                </div>
              )}
              {user?.province && (
                <div className="form__row">
                  <label htmlFor="province" className="form__label">
                    Province
                  </label>

                  <div className="form__controls">
                    <input
                      type="text"
                      className="field"
                      {...register("province")}
                      defaultValue={user.province}
                      disabled={profile !== "Admin"}
                    />
                  </div>
                </div>
              )}
              {user?.metadata.rules && (
                <div className="form__row">
                  <label htmlFor="postal" className="form__label">
                    Rules
                  </label>

                  <div className="form__controls">
                    <input
                      type="text"
                      className="field"
                      {...register("metadata.rules")}
                      defaultValue={user?.metadata.rules ? "Yes" : "No"}
                      disabled={true}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="form__actions">
              {isSubmit ? (
                <button
                  type="button"
                  className="form__btn btn btn--secondary btn--secondary-alt"
                  disabled>
                  <Spinner as="span" animation="border" role="status" aria-hidden="true" /> Update
                </button>
              ) : (
                profile === "Admin" && (
                  <button type="submit" className="form__btn btn btn--secondary btn--secondary-alt">
                    Update
                  </button>
                )
              )}
            </div>
          </form>
        </div>
        <div className="main__content">
          <div className="table table--alt table--prizing">
            <h4 className="table__title">Prizing</h4>
            <table>
              <colgroup>
                <col className="table__col-1" style={{ width: "17.3%" }} />

                <col className="table__col-2" style={{ width: "27.7%" }} />

                <col className="table__col-3" style={{ width: "33.7%" }} />

                <col className="table__col-4" style={{ width: "auto" }} />

                {/* <col className="table__col-5" style={{ width: "auto" }} /> */}
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <span>Date / Time</span>
                  </th>

                  <th>
                    <span>Prize pool</span>
                  </th>

                  <th>
                    <span>Prize name</span>
                  </th>

                  <th>
                    <span>Status</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {winners.map((v) => (
                  <tr key={v.prizeWinnerKey}>
                    <td>{v.winningTime}</td>
                    <td>{v.poolName}</td>
                    <td>{v.prize}</td>
                    <td>{v.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <div className="table table--alt table--emails">
            <h4 className="table__title">Recent emails</h4>
            <table>
              <colgroup>
                <col className="table__col-1" />
                <col className="table__col-2" />
                <col className="table__col-3" />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <span>Sent</span>
                  </th>
                  <th>
                    <span>Subject</span>
                  </th>
                  <th></th>
                </tr>
              </thead>
              {/* <tbody>
                {participantEmails.map((v) => (
                  <tr key={v.targets[0].result.messageId}>
                    <td>{new Date(v.creationTime).toLocaleString()}</td>
                    <td>{v.subject}</td>
                    <td>
                      <a
                        href="#"
                        className="btn-square-alt"
                        onClick={(e) => {
                          e.preventDefault();
                          setemailParams({
                            emailTemplateKey: v.emailTemplateKey,
                            toEmail: user!.email,
                            fromName: v.fromName,
                            fromEmail: v.fromEmail,
                            parameters: v.targets[0].parameters,
                            minRetrySeconds: 60,
                            transactional: true,
                          });
                          setShowModal(true);
                        }}>
                        <svg className="ico-mail-send">
                          <image xlinkHref="/assets/images/svg/ico-mail-send.svg" />
                        </svg>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody> 
            </table>
          </div> */}
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>&nbsp;</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Resend Email</h3>
          <p>Are you sure you want to resend this email?</p>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1"> Recipient email</label>
            <input
              type="text"
              className="form-control"
              style={{ width: "100%" }}
              name="email"
              value={(user && user!.email) || ""}
              disabled
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary small" onClick={resendEmail}>
            Resend
          </button>
          <button type="button" className="btn btn-secondary small outline" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div className="alerts">
        <div
          className={`${message.msg ? "alert alert--wide is-shown" : "alert alert--wide "} `}
          id="alert-email">
          <img className="alert__icon" src="/assets/images/svg/ico-check-circle.svg" alt="" />

          <span>{message.msg}</span>

          <a href="#" className="alert__close js-alert-close">
            <svg className="ico-cross">
              <image xlinkHref="/assets/images/svg/ico-cross.svg" />
            </svg>
          </a>
        </div>
      </div>
    </>
  );
}

export default Participant;
