import React, { useState } from 'react';

import Select, { ActionMeta, OnChangeValue, StylesConfig } from 'react-select';

export type MultiOptionType = { value: string, label: string}

type Props = {
  multiValue: MultiOptionType[] | MultiOptionType | null,
  setMultiValue: Function
  Options: MultiOptionType[]
  isMulti: boolean
  placeholder?:string
}



const customStyles = {
  control: (base:any) => ({
    ...base,    
    minHeight: 45,   
  })
};


export const MultilSelect = (props: Props) => {

  // const [multiValue, setMultiValue] = useState<readonly MultiOptionType[]>(
  //   []
  // );

  const onChange = (
    newValue: OnChangeValue<MultiOptionType, true | false>,
    actionMeta: ActionMeta<MultiOptionType>
  ) => {
    // switch (actionMeta.action) {
    //   case 'remove-value':
    //   case 'pop-value':
    //     if (actionMeta.removedValue.isFixed) {
    //       return;
    //     }
    //     break;
    //   case 'clear':
    //     newValue = colourOptions.filter((v) => v.isFixed);
    //     break;
    // }

    props.setMultiValue(newValue);
  };

  return (
    <Select
      value={props.multiValue}
      isMulti = {props.isMulti}
      //styles={styles}
      isClearable={ true }
      name="colors"
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={onChange}
      options={ props.Options }
      styles={customStyles}
      placeholder={props.placeholder||"select..."}
    />
  );
};