import { defaultStorageHelper } from "@spry/campaign-client";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

import axios from "axios";

// import "./index.css";
import * as tus from "tus-js-client";

type DropzoneProps = {
    setFile: Function;
    setVideoUrl: Function;
};

type GetVideos = {
    html_embed: string;
    player_embed_url: string;
    upload_link: string | null;
    uri: string;
    videoKey: string;
    fileName: string;
    status: number;
    creationDate: string;
};

declare const window: Window &
    typeof globalThis & {
        vex: any;
    };
export default function Dropzone(props: DropzoneProps) {
    const [submitting, setSubmitting] = useState(false);
    const [progress, setProgress] = useState<string>();

    const { isDragReject, getRootProps, getInputProps } = useDropzone({
        accept: {
            "video/mp4": [".mp4"],
            "video/mov": [".mov"],
        },
        maxFiles: 1,
        maxSize: 1000000000,
        multiple: false,
        onDropRejected: (rejectFiles) => {
            rejectFiles.forEach(function (v) {
                v.errors.forEach(function (err) {
                    if (err.code === "file-too-large") {
                        window.vex.dialog.alert({
                            unsafeMessage: "file is too_large",
                            callback: () => {
                                return;
                            },
                        });
                        return;
                    }
                    if (err.code === "file-invalid-type") {
                        window.vex.dialog.alert({
                            unsafeMessage: "invalid_type",
                            callback: () => {
                                return;
                            },
                        });
                        return;
                    }

                    if (err.code === "too-many-files") {
                        window.vex.dialog.alert({
                            unsafeMessage: "too_many",
                            callback: () => {
                                return;
                            },
                        });
                        return;
                    }
                });
            });
        },
        onDropAccepted: (acceptedFiles) => {
            if (submitting) {
                return;
            }
            setSubmitting(true);

            if (isDragReject) {
                setSubmitting(false);
                return;
            }
            const accept_file = acceptedFiles[0];

            const reader = new FileReader();

            var url = URL.createObjectURL(accept_file);
            props.setVideoUrl(url);
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = async () => {
                props.setFile(accept_file);
            };
            reader.readAsDataURL(accept_file);
        },
    });

    return (
        <>
            <div {...getRootProps({ className: "form__inner" })}>
                <input {...getInputProps()} />

                <div
                    style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                    }}>
                    {progress ? (
                        progress
                    ) : (
                        <div style={{
                            border: "1px solid grey", borderStyle: "dashed", padding: "50px", alignItems: "center", display: "flex",
                            flexDirection: "column"
                        }}>
                            Drag and drop a video file (.mp4 or .mov) to upload
                            <div
                                style={{
                                    backgroundColor: "#2d2c6d",
                                    color: "#fff",
                                    padding: "5px",
                                    width: "15rem",
                                    borderRadius: "5px",
                                    margin: "2rem 0"
                                }}>
                                Or choose a file
                            </div>
                            <p>(max file size is 100mb)</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
