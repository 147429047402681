import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { campaignClient } from "../../api";
import { PageLoader, Table } from "../../Components";
import { questionsColumns } from "./QuestionsCol";
import { showToast } from "../../Components/Toast/ToastManager";
import { AddQuestionArgs, SurveyAnswersReturn } from "../../helpers/types";
import { ResultColumns } from "./ResultsCol";
import { Participant, PrizeWinners } from "@sprycore/spry-api-client/dist/MainDbReturnTypes"
import { byKey } from "../../helpers/utils";
import { assign } from "lodash";
import { PRIZES } from "../../helpers/constants";


type Props = {
    Participants: Participant[]
    Survey: AddQuestionArgs[]
    onHandle: Function,
    singleFilterValue:  {[key:string]: string[]} | null
    filterTable: string
}

const ParticipantSurvey = (props: Props) => {
    
    const {
        isLoading: isLoading,
        data,
        refetch       
    } = useQuery(["getResults", {statsType: "correspondent"}], async () => {
        const res = await campaignClient.call<{items: SurveyAnswersReturn[]}>("getResults", {statsType: "correspondent"});
        return res.items.filter(item=>item.answers);
    });

    const { isLoading: isloadingWinners, data: winners, refetch: refetchPrizeWinners } = useQuery(["getPrizeWinners", {prizePoolKey: PRIZES.survey.prizePoolKey}], async () => {
        const res = await campaignClient.call<PrizeWinners>("getPrizeWinners", {prizePoolKey: PRIZES.survey.prizePoolKey});
        return byKey(res.prizeWinners, (w)=>w.prizeWinnerKey)
    });

    const selectSurveyWinnerMutation = useMutation((winner: {sessionKey: string, type: number}) => {
        return campaignClient.call<{ result: boolean }>("selectSurvey", winner)
    }, {
        onSuccess(data) {
           
            refetch();
            showToast({
                content: "Update successfully",
                duration: 3000,
                error: false,
            });
           
        },
        onError(error) {
            showToast({
                content: (error as Error).toString(),
                duration: 3000,
                error: true,
            });
        },
    })

    const handleSelectWinner = (sessionKey: string, type: number)=>{
        selectSurveyWinnerMutation.mutate({sessionKey, type})
    }


    const resultColumns = useMemo(()=>{

        if (props.Survey && props.Survey.length > 0){
            return ResultColumns({questions: props.Survey, selectMutation: handleSelectWinner})
        }       
    }, [props.Survey]);

    const reportData = useMemo(()=>{
        if (props.Participants && props.Participants.length > 0 && data && data.length > 0 && winners && props.Survey && props.Survey.length > 0){

            const participantByKey = byKey(props.Participants, (s)=>{ return s.sessionKey});          

            const report = data.map(p=>{

                return assign({
                    sessionKey: p.sessionKey,
                    firstName: participantByKey[p.sessionKey] && participantByKey[p.sessionKey][0].firstName,
                    lastName: participantByKey[p.sessionKey] && participantByKey[p.sessionKey][0].lastName,
                    email: participantByKey[p.sessionKey] && participantByKey[p.sessionKey][0].email,
                    province: participantByKey[p.sessionKey] && participantByKey[p.sessionKey][0].province,
                    locationType: participantByKey[p.sessionKey] && participantByKey[p.sessionKey][0].metadata.locationType || "" ,                
                    location: participantByKey[p.sessionKey] && participantByKey[p.sessionKey][0].metadata.location || "" ,                
                    storeNo: participantByKey[p.sessionKey] && participantByKey[p.sessionKey][0].metadata.storeNo || "" ,
                    storeType: participantByKey[p.sessionKey] && participantByKey[p.sessionKey][0].metadata.storeType || "" ,
                    storeBanner: participantByKey[p.sessionKey] && participantByKey[p.sessionKey][0].metadata.banner || "" ,
                    employeeNo: participantByKey[p.sessionKey] && participantByKey[p.sessionKey][0].metadata.employeeNo || "",
                    office: p.office,
                    empireStore: p.e_storeNo, 
                    empireBanner: p.e_banner, 
                    empireJob: p.e_jobTitle, 
                    passport: p.passport,
                    submitTime: p.creationTime,
                    answers: p.answers,
                    answerForSearch: Object.values(p.answers).join(','),
                    reject: p.prizeWinnerKey && winners[p.prizeWinnerKey] && winners[p.prizeWinnerKey][0].forfeitTime ? true : false,
                    status: p.status,                    
                }, ...props.Survey.map(q=>{

                    if (q.type === "radio"){
                        const answer = q.options.find(o=>o.id === p.answers[q.id])                 
                           
                        return {[q.question.en]: answer ? answer.label_en : ""};
                    }else{

                        return {[q.question.en]: `${p.answers[q.id] ? p.answers[q.id] : ""}`}

                    }  
                }),)
            })           

            return report;

        }
    }, [props.Participants, data, props.Survey, winners]);

    useEffect(()=>{
        if (reportData && reportData.length > 0){            
            props.onHandle(reportData)
        }

        return ()=>{ props.onHandle([])}
    }, [reportData])

   if (!data || !props.Participants || !props.Survey || !resultColumns || isloadingWinners) {
    return <PageLoader />
   }

  return (
    <div className="table">   
    
    {reportData && reportData.length >0 && resultColumns && resultColumns.length > 0 ? (
        <div className="table-responsive"><Table columns={resultColumns} 
        data={reportData} 
        tablePageSize={10}
        singleFilter={props.singleFilterValue} 
        filterValue= { props.filterTable }
        /></div>
    ) : (
        <p style={{ textAlign: "center" }}>There are no records to display</p>
    )}
</div>
  )
}

export default ParticipantSurvey