import React, { Fragment } from "react";
import { AddQuestionArgs, Option, Question } from "../../helpers/types";


type Props = {
    questions: {[key:string]: 
        Pick<Question, 'question' | 'answer'> &
        Pick<AddQuestionArgs, 'q_seq' | 'id'> &
        {options: { [key:string] : Option & {count: number}}}
    }
    Total: number    
} 

export const ResultColumns = (
    { questions, Total }: Props) => {

    const questionArray = Object.values(questions);       
    return (
        <div className="table table-responsive" style={{ width: "100%" }}>
            <table>
                <tbody>
                    {questionArray?.length &&
                        questionArray.map((q, index) => (
                            <Fragment key={q.question.id}>
                            <tr >
                                <td><p className="fs-4 fw-bold">Question { index + 1}</p></td>
                                { Object.values(q.options).map((o, index)=>{
                                    return <td key={o.id}><span className={ o.id === q.answer ? "fw-bold text-success" : "fw-bold"}>{ `EN: ${o.label_en}`} <br /> {`Fr: ${o.label_fr}`}</span></td>
                                })}
                            </tr>
                            <tr>
                                <td><span className="fw-bold">{ `EN: ${q.question.en}`} <br /> {`Fr: ${q.question.fr}`}</span></td>
                                { Object.values(q.options).map((o, index)=>{
                                    return <td key={`${o.id}-${index}`}>{ `${Math.floor(o.count/Total * 100)}% (${o.count} users)`}</td>
                                })}
                            </tr>
                            </Fragment>
                        ))}
                </tbody>
            </table>
        </div>
    );
};
