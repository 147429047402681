import React, { useEffect } from "react";
import { useTable, useSortBy, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useRowSelect, useMountedLayoutEffect } from "react-table";

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <>
            <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
    );
});

type Props = {
    columns: any;
    data: any;
    filterValue?: string;
    singleFilter?: { [key: string]: string | string[] } | null;
    tablePageSize: number;
    checkbox?: boolean;
    onSelectedRowsChange?: Function;
    selectedRows?: any[];
    filterId?: string;
    desc?: boolean;
    hiddenColumnsArray?: string[];
};

export const Table = ({ columns, data, filterValue, tablePageSize, onSelectedRowsChange, checkbox, filterId, desc, singleFilter, hiddenColumnsArray }: Props) => {
    const maxPageShow = 6;
    const hiddenColumns = ["creationDate", "answerForSearch"];
    hiddenColumnsArray?.length && hiddenColumns.push(...hiddenColumnsArray);
    const tableInstance = useTable(
        {
            columns,
            data,
            autoResetHiddenColumns: false,
            initialState: { pageIndex: 0, pageSize: tablePageSize, sortBy: filterId ? [{ id: filterId, desc }] : [], hiddenColumns },
            useRowSelect,
            autoResetGlobalFilter: false,
            autoResetPage: false,
            autoResetFilters: false,
            autoResetSortBy: false
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            checkbox &&
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        id: "selection",
                        disableSortBy: true,
                        canSort: false,
                        width: "3%",
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllPageRowsSelectedProps }) => <div>{/* <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} /> */}</div>,
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        ),
                    },
                    ...columns,
                ]);
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // // which has only the rows for the active page

        // // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize, globalFilter, selectedRowIds },
        setGlobalFilter,
        setFilter,
    } = tableInstance;

    const handleDebounceGlobalFilter = useAsyncDebounce((value) => setGlobalFilter(value), 100);

    const handleDebounceFilter = useAsyncDebounce((value) => setFilter(Object.keys(value)[0], Object.values(value)[0]), 100);

    useEffect(() => {
        handleDebounceGlobalFilter(filterValue);
    }, [filterValue]);

    useEffect(() => {
        if (singleFilter) {
            handleDebounceFilter(singleFilter);
        }
    }, [singleFilter]);

    useMountedLayoutEffect(() => {
        // if (Object.keys(selectedRowIds)) {
        //     onSelectedRowsChange && onSelectedRowsChange(selectedRowIds);
        // } else {
        //     onSelectedRowsChange && onSelectedRowsChange({});
        // }

        if (onSelectedRowsChange) {
            onSelectedRowsChange(selectedFlatRows.map((d) => d.original));
        }
    }, [onSelectedRowsChange, selectedRowIds]);

    const caculateShowPage = (pgcnt: any, pgidx: any) => {
        const totalPages = [...Array(pgcnt)].map((_, i) => i);

        if (pgidx + maxPageShow > totalPages.length) {
            return totalPages.slice(maxPageShow * -1);
        } else {
            return totalPages.slice(pgidx, pgidx + maxPageShow);
        }
    };

    return (
        <div className="table" style={{ borderTop: "none" }}>
            <table {...getTableProps()} style={{ width: "100%" }}>
                <thead>
                    {
                        // Loop over the header rows
                        headerGroups.map((headerGroup) => (
                            // Apply the header row props
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    // Loop over the headers in each row
                                    headerGroup.headers.map((column) => {
                                        // Apply the header cell props

                                        return (
                                            // <th
                                            //   scope="col"
                                            //   {...column.getHeaderProps({
                                            //     style: {
                                            //       width: column.width,
                                            //     },
                                            //   })}
                                            // >
                                            <th
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps({
                                                        style: {
                                                            width: column.width,
                                                            fontWeight: "700",
                                                        },
                                                    })
                                                )}>
                                                {
                                                    // Render the header
                                                    column.render("Header")
                                                }
                                                <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                                            </th>
                                        );
                                    })
                                }
                            </tr>
                        ))
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {/* {!data.length && <tr style={{ fontSize: "16px" }}><td> No records to display.</td></tr> } */}

                    {
                        // Loop over the table rows
                        page.map((row: any) => {
                            // Prepare the row for display
                            prepareRow(row);
                            return (
                                // Apply the row props
                                <tr {...row.getRowProps()}>
                                    {
                                        // Loop over the rows cells
                                        row.cells.map((cell: any) => {
                                            // Apply the cell props
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {
                                                        // Render the cell contents
                                                        cell.render("Cell")
                                                    }
                                                </td>
                                            );
                                        })
                                    }
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
            {data.length > tablePageSize && (
                <div className="paginationCont">
                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    aria-label="Previous"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        previousPage();
                                    }}
                                    disabled={!canPreviousPage}>
                                    <span aria-hidden="true">&lt;</span>
                                    <span className="sr-only">Previous</span>
                                </button>
                            </li>
                            {pageIndex > 0 && pageIndex + maxPageShow <= pageOptions.length ? (
                                <>
                                    <li className="page-item ">
                                        <a
                                            className="page-link"
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                gotoPage(0);
                                            }}>
                                            1
                                        </a>
                                    </li>
                                    <li className="page-item ">
                                        <a className="page-link">...</a>
                                    </li>
                                </>
                            ) : (
                                <></>
                            )}
                            {caculateShowPage(pageCount, pageIndex).map(function (page) {
                                return (
                                    <li
                                        className={page == pageIndex ? "page-item active" : "page-item"}
                                        key={page}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            gotoPage(page);
                                        }}>
                                        <a className="page-link" href="#">
                                            {page + 1}
                                        </a>
                                    </li>
                                );
                            })}
                            {pageIndex < pageCount - maxPageShow ? (
                                <>
                                    <li className="page-item ">
                                        <a className="page-link">...</a>
                                    </li>
                                    <li className="page-item ">
                                        <a
                                            className="page-link"
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                gotoPage(pageCount - 1);
                                            }}>
                                            {pageCount}
                                        </a>
                                    </li>
                                </>
                            ) : (
                                <></>
                            )}
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        nextPage();
                                    }}
                                    aria-label="Next"
                                    disabled={!canNextPage}>
                                    <span aria-hidden="true">&gt;</span>
                                    <span className="sr-only">Next</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            )}
        </div>
    );
};
