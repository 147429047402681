import { Fragment, useEffect, useMemo, useState } from "react";
import Button from 'react-bootstrap/Button';
import { Control, FieldErrors, UseFormRegister, useFieldArray, useForm } from "react-hook-form";
import { drop, dropWhile, pullAt, slice, remove as removeElement } from "lodash";
import { campaignClient } from "../../api";
import { QueryClient, useQuery, useQueryClient } from "react-query";
import { showToast } from "../../Components/Toast/ToastManager";
import { PageLoader } from "../../Components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AddQuestionArgs, Option, Question, TriviaQuestions } from "../../helpers/types";
import { getQuestion, questionAdaptor } from "../../helpers/utils";
import { MESSAGES } from "../../helpers/constants";


export const QuizForm = ({ FormValues, queryClient, backPath, questionCount, questionId }: { queryClient: QueryClient, questionId?: string, questionCount?: number, backPath?: string, FormValues: { quiz: TriviaQuestions[], quizName: string, quizId: string}}) => {

    const navigate = useNavigate();

    const {
        handleSubmit,
        register,
        setValue,
        control,
        watch,
        reset,
        clearErrors,
        formState: { errors, defaultValues, isSubmitting },
    } = useForm<any>({
        defaultValues: FormValues
        //criteriaMode: "all",
        // defaultValues: {
        //     quiz: questions,
        //     quizName: quiz,
        //     quizId: ""
        // },
    });

    useEffect(() => {
        reset({ ...FormValues })
      }, [FormValues])

    const { fields, append, prepend, remove, swap, move, update } = useFieldArray<any>({
        control,
        name: "quiz",
    });

    const onSubmit = async (data: any) => {   

        try {

            for (let i = 0; i < data.quiz.length; i++) {

                const question = data.quiz[i];

                const { result } = await campaignClient.call<{ result: boolean }>("saveQuestion", {
                    statsType: "trivia",
                    id: question.qid,
                    answer: question.answer,
                    options: question.options.map((op:any)=>{return {...op, id: op.option_id}}),
                    question: question.question,
                    quizName: data.quizName,
                    quiz: FormValues.quizId,
                    q_seq: question.q_seq ? question.q_seq : questionCount ? questionCount + 1 : i + 1,
                    type: "multiple",
                    status: 1
                });
            }

             showToast({
            content: MESSAGES.SUCCESS,
            duration: 3000,
            error: false,
        });

       queryClient.invalidateQueries();

       if (questionId){
        navigate(`/trivia/question/${FormValues.quizId}/${questionId}`)
       }

       
    
            
        } catch (error) {
            showToast({
                content: (error as Error).toString(),
                duration: 3000,
                error: false,
            });
        }

    };

    return (
        <>
            <div className="main__body main__body--flex main__body--flex-alt">
                <form onSubmit={handleSubmit(onSubmit)} className="form">
                    <div className="container">
                        <div className="mb-3 row gap-0">
                            <div className="col-8">
                                <label htmlFor="quizName" className="form__label">Quiz Name</label>

                                <input type="text" placeholder="Weekly quiz name" className={`form-control ${errors.quizName ? 'is-invalid' : ''}`}
                                    {...register(`quizName`, { required: { value: true, message: "Please enter quiz name." } })}
                                    disabled = { questionId  ? true : false}
                                />
                            </div>
                        </div>
                        <div className="form__row">
                            {
                                fields.map((field: any, index) => {                                        
                                

                                    return <Fragment key={field.qid}>
                                        <div className="row">
                                            <div className="col">
                                                <label htmlFor={`quiz.${index}.question.en`} className="form__label">
                                                    Question { field.q_seq ? field.q_seq : questionCount ? questionCount + 1 : index + 1}
                                                </label>

                                                <div className="form-floating">
                                                    <textarea
                                                        className={` form-control ${errors && errors.quiz && (errors.quiz as any)[index] && (errors.quiz as any)[index].question && (errors.quiz as any)[index].question?.en ? "is-invalid" : ""}`}
                                                        placeholder="English"
                                                        style={{ height: "100px" }}
                                                        {...register(`quiz.${index}.question.en`, { required: { value: true, message: "Please enter question in English." } })}
                                                    ></textarea>
                                                    <label htmlFor={`quiz.${index}.question.en`}>English</label>
                                                </div>

                                            </div>
                                            <div className="col">
                                                <label htmlFor={`quiz.${index}.question.fr`} className="form__label">
                                                    &nbsp;
                                                </label>
                                                <div className="form-floating">
                                                    <textarea
                                                        className={`field form-control ${errors && errors.quiz && (errors.quiz as any)[index] && (errors.quiz as any)[index].question && (errors.quiz as any)[index].question.fr ? "is-invalid" : ""}`}
                                                        placeholder="French"
                                                        style={{ height: "100px" }}
                                                        {...register(`quiz.${index}.question.fr`, { required: { value: true, message: "Please enter question in French." } })}
                                                    ></textarea>
                                                    <label htmlFor={`quiz.${index}.question.fr`}>French</label>
                                                </div>
                                            </div>

                                            <div className="col align-self-center">
                                                <label htmlFor="question.en" className="form__label">&nbsp; </label>
                                                <button
                                                    className="btn-icon mx-3"
                                                    type="button"
                                                    onClick={() => {
                                                        remove(index);
                                                    }}
                                                    disabled={fields.length <= 1}>
                                                    <img src="\assets\images\icons\icon-minus-circle.svg" alt="update" className="" />
                                                </button>
                                                <button
                                                    className="btn-icon"
                                                    type="button"
                                                    //disabled={fields.length >= 4}
                                                    onClick={() => {
                                                        append(getQuestion());
                                                    }}>
                                                    <img src="\assets\images\icons\icon-plus-circle.svg" alt="update" />
                                                </button>

                                            </div>

                                        </div>

                                        <QuestionFormOption 
                                            questionIdx = {index}
                                            control={ control }
                                            register = { register}
                                            errors = { errors }
                                            watch = { watch }
                                            setValue = { setValue }
                                            />

                                        {
                                           
                                            // field.options.map((op: Option, idx: number) => {
                                            //     return <Fragment key={op.id}>
                                            //         <div className="row my-4">
                                            //             <div className="col">
                                            //                 <label htmlFor={`quiz.${index}.options.${idx}.label_en`} className="form__label">
                                            //                     Answer {idx + 1}
                                            //                 </label>
                                            //                 <div className="form-floating">
                                            //                     <input type="text" className={`field form-control ${errors && errors.quiz && (errors.quiz as any)[index] && (errors.quiz as any)[index].options && (errors.quiz as any)[index].options[idx] && (errors.quiz as any)[index].options[idx].label_en ? "is-invalid" : ""}`}
                                            //                         placeholder="English" {...register(`quiz.${index}.options.${idx}.label_en`, { required: true })} />
                                            //                     <label htmlFor={`quiz.${index}.options.${idx}.label_en`} >English</label>
                                            //                 </div>
                                            //             </div>
                                            //             <div className="col">
                                            //                 <label className="form__label">&nbsp;</label>
                                            //                 <div className="form-floating">
                                            //                     <input type="text" className={`field form-control ${errors && errors.quiz && (errors.quiz as any)[index] && (errors.quiz as any)[index].options && (errors.quiz as any)[index].options[idx] && (errors.quiz as any)[index].options[idx].label_fr ? "is-invalid" : ""}`}
                                            //                         placeholder="French" {...register(`quiz.${index}.options.${idx}.label_fr`, { required: true })} />
                                            //                     <label htmlFor={`quiz.${index}.options.${idx}.label_fr`}>French</label>
                                            //                 </div>

                                            //             </div>
                                            //             <div className="col-2 align-self-center">
                                            //                 <label className="form__label form-check-label" htmlFor="flexSwitchCheckDefault">Correct</label>
                                            //                 <div className="  ">
                                            //                     <label className="switch is-invalid">
                                            //                         <input
                                            //                             type="radio"
                                            //                             {...register(`quiz.${index}.answer`, { required: true })}
                                            //                             checked={watch(`quiz.${index}.answer`) === op.id}
                                            //                             className="is-invalid"
                                            //                             value={op.id}
                                            //                             onChange={(e) => {
                                            //                                 setValue(`quiz.${index}.answer`, e.target.value);

                                            //                             }}
                                            //                         />

                                            //                         <span className="slider round"></span>
                                            //                     </label>

                                            //                 </div>
                                            //                 {/* <div className="form-check form-switch"> */}
                                            //                 {/* <input className="form-check-input" type="radio" role="switch" id="flexSwitchCheckDefault" /> */}
                                            //                 {/* <label className="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
                                            //                 {/* </div> */}
                                            //                 {/* <div className=" flexSwitch ">
                                            //                        <label className="switch">
                                            //                            <input
                                            //                                type="radio"
                                            //                                name="correctAnswer"
                                            //                                checked={watch(`quiz.${index}.options.${idx}.en`) === watch("answer")}
                                            //                            // onChange={() => {
                                            //                            //     setValue("answer", watch(`options.${i}.id`));
                                            //                            // }}
                                            //                            />

                                            //                            <span className="slider round"></span>
                                            //                        </label>

                                            //                    </div> */}
                                            //             </div>
                                            //             <div className="col align-self-center">
                                            //                 <label className="form__label">&nbsp;</label>
                                            //                 <button
                                            //                     className="btn-icon me-3"
                                            //                     type="button"
                                            //                     onClick={() => {
                                            //                         update(index, {
                                            //                             ...field,
                                            //                             options: removeElement(field.options, function (o: any) { return o.id !== op.id })
                                            //                         })

                                            //                         clearErrors(`quiz.${index}.options.${idx}.label_en`)
                                            //                         clearErrors(`quiz.${index}.options.${idx}.label_fr`)
                                            //                     }}
                                            //                     disabled={field.options.length <= 1 || watch(`quiz.${index}.answer`) === op.id}>
                                            //                     <img src="\assets\images\icons\icon-minus-circle.svg" alt="update" className="" />
                                            //                 </button>
                                            //                 <button
                                            //                     className="btn-icon"
                                            //                     type="button"
                                            //                     //disabled={fields.length >= 4}
                                            //                     onClick={() => {
                                            //                         update(index, {
                                            //                             ...field,
                                            //                             options: [
                                            //                                 ...field.options,
                                            //                                 getQuestion().options[0]
                                            //                             ]
                                            //                         })

                                            //                     }}>
                                            //                     <img src="\assets\images\icons\icon-plus-circle.svg" alt="update" />
                                            //                 </button>

                                            //             </div>
                                            //         </div>

                                            //     </Fragment>
                                            // })
                                        }
                                        <hr className="my-5" />
                                    </Fragment>
                                })
                            }

                        </div>



                        <br />
                        <br />
                        <div className="flex-gap">
                            <Link className="btn__cancel button--small" to={backPath || "/trivia"}>
                                Back
                            </Link>
                            <Button className="btn button--small" type="submit" disabled={isSubmitting} >
                                {isSubmitting ? 'Saving…' : 'Save changes'}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );

}

export const QuestionFormOption = ({questionIdx, control, errors, register, watch, setValue }:{questionIdx: number, control:Control, errors: FieldErrors, register: any, watch: any, setValue: Function}) =>{

    const { fields, append, prepend, remove, swap, move, update } = useFieldArray<any>({
        control,
        name: `quiz.${questionIdx}.options`,       
    });

    return (<>
        {fields.map((op: any, idx: number) => {
            //console.log("option", op)
        return <Fragment key={op.option_id}>
            <div className="row my-4">
                <div className="col">
                    <label htmlFor={`options.${idx}.label_en`} className="form__label">
                        Answer {idx + 1}
                    </label>
                    <div className="form-floating">
                        <input type="text" className={`field form-control ${errors && errors.quiz && (errors.quiz as any)[questionIdx] && (errors.quiz as any)[questionIdx].options && (errors.quiz as any)[questionIdx].options[idx] && (errors.quiz as any)[questionIdx].options[idx].label_en ? "is-invalid" : ""}`}
                            placeholder="English" {...register(`quiz.${questionIdx}.options.${idx}.label_en`, { required: true })} />
                        <label htmlFor={`quiz.${idx}.options.${idx}.label_en`} >English</label>
                    </div>
                </div>
                <div className="col">
                    <label className="form__label">&nbsp;</label>
                    <div className="form-floating">
                        <input type="text" className={`field form-control ${errors && errors.quiz && (errors.quiz as any)[questionIdx] && (errors.quiz as any)[questionIdx].options && (errors.quiz as any)[questionIdx].options[idx] && (errors.quiz as any)[questionIdx].options[idx].label_fr ? "is-invalid" : ""}`}
                            placeholder="French" {...register(`quiz.${questionIdx}.options.${idx}.label_fr`, { required: true })} />
                        <label htmlFor={`options.${idx}.label_fr`}>French</label>
                    </div>

                </div>
                <div className="col-2 align-self-center">
                    <label className="form__label form-check-label" htmlFor="flexSwitchCheckDefault">Correct</label>
                    <div className="  ">
                        <label className="switch is-invalid">
                            <input
                                type="radio"
                                {...register(`quiz.${questionIdx}.answer`, { required: true })}
                                checked={watch(`quiz.${questionIdx}.answer`) === op.option_id}
                                className="is-invalid"
                                value={op.option_id}
                                onChange={(e) => {
                                    setValue(`quiz.${questionIdx}.answer`, e.target.value);

                                }}
                            />

                            <span className="slider round"></span>
                        </label>

                    </div>
                    {/* <div className="form-check form-switch"> */}
                    {/* <input className="form-check-input" type="radio" role="switch" id="flexSwitchCheckDefault" /> */}
                    {/* <label className="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label> */}
                    {/* </div> */}
                    {/* <div className=" flexSwitch ">
                           <label className="switch">
                               <input
                                   type="radio"
                                   name="correctAnswer"
                                   checked={watch(`quiz.${index}.options.${idx}.en`) === watch("answer")}
                               // onChange={() => {
                               //     setValue("answer", watch(`options.${i}.id`));
                               // }}
                               />

                               <span className="slider round"></span>
                           </label>

                       </div> */}
                </div>
                <div className="col align-self-center">
                    <label className="form__label">&nbsp;</label>
                    <button
                        className="btn-icon me-3"
                        type="button"
                        onClick={() => {

                            remove(idx)
                            // update(index, {
                            //     ...field,
                            //     options: removeElement(field.options, function (o: any) { return o.id !== op.id })
                            // })

                            // clearErrors(`quiz.${index}.options.${idx}.label_en`)
                            // clearErrors(`quiz.${index}.options.${idx}.label_fr`)
                        }}
                        disabled={fields.length <= 1 || watch(`quiz.${questionIdx}.answer`) === op.option_id}
                        >
                        <img src="\assets\images\icons\icon-minus-circle.svg" alt="update" className="" />
                    </button>
                    <button
                        className="btn-icon"
                        type="button"
                        //disabled={fields.length >= 4}
                        onClick={() => {

                            append(getQuestion().options[0]);
                            // update(index, {
                            //     ...field,
                            //     options: [
                            //         ...field.options,
                            //         getQuestion().options[0]
                            //     ]
                            // })

                        }}
                        >
                        <img src="\assets\images\icons\icon-plus-circle.svg" alt="update" />
                    </button>

                </div>
            </div>

        </Fragment>
    })}
    </>
)
    
}