import { useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { campaignClient } from '../../api';
import { showToast } from '../../Components/Toast/ToastManager';
import { useQuery } from 'react-query';
import { PageLoader } from '../../Components';
import { isStringValidHtml } from '../../helpers/utils';


type scrapbook = {
    seq: number;
    id?: string;
    link: string;
}

type formType = {
    scraps: scrapbook[]
}

const Scrap = () => {

    const [loading, setLoading] = useState(false);

    const {
        handleSubmit,
        watch,
        reset,
        control,
        setError,
        formState: { errors, },
    } = useForm<formType>({
        mode: "onChange",
        criteriaMode: "all",
        reValidateMode: "onChange"
    });

    const formwatch = watch()
    const {
        fields,
        append,
        remove,
    } = useFieldArray({
        control,
        name: "scraps"
    });

    const {
        isLoading: isLoadingscraps,
        refetch,
    } = useQuery("getData", async () => {
        const res: any = await campaignClient.call("getData", { statsType: "scraps" })
        reset({ scraps: res.sort((a: scrapbook, b: scrapbook) => +a.seq - +b.seq) })
        return { scraps: res }
    });

    // const validateData = (data: formType) => {

    //     const dataError: any[] = []

    //     data.scraps.map((a, i) => {
    //         const isValidHtml = isStringValidHtml(a.link)
    //         if (!isValidHtml) {
    //             setError(`scraps.${i}.link`, { message: "Invalid html." })
    //             dataError.push(i)
    //         }
    //     })
    //     return dataError
    // }

    const handleSaveChanges = async (data: formType) => {

        if (loading) {
            return;
        }
        setLoading(true);

        const { result, error, message } = await campaignClient.call<{ result: string; error: string, message: string }>("saveScrap", {
            ...data,
        });
        if (error) {
            showToast({
                content: `${message}`,
                duration: 3000,
                error: true,
            });
        }

        if (result) {
            await refetch()
            showToast({
                content: "Scrapbook links has been saved!. ",
                duration: 3000,
                error: false,
            });
        }
        setLoading(false);
    };

    if (isLoadingscraps) {
        return <PageLoader />
    }

    return (<>
        <div className="main__head">
            <h2 className="main__title"> Scrapbook</h2>
        </div>
        <p>Add the ISSUU link in the field below </p>

        {formwatch.scraps?.length ?
            <div className="main__body main__body--flex main__body--flex-alt">
                <form onSubmit={handleSubmit(handleSaveChanges)} className="form">
                    <div style={{ paddingRight:"27rem" }} >

                        <ul className='field-control-item'>
                            {fields.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <div className="row mt-10" style={{ alignItems: "center" }}>
                                            <div className="col-sm-12 col-md-10">
                                                <label htmlFor="title_en" className="form__label">
                                                    <b>ISSUU Link {index + 1}</b>
                                                </label>

                                                <Controller
                                                    render={({ field }) => <textarea className="field" id="message"{...field} style={{ minHeight: "100px" }}></textarea>}
                                                    name={`scraps.${index}.link`}
                                                    control={control}
                                                    rules={{ required: { value: true, message: "Required." } }}
                                                />

                                                {errors.scraps && errors.scraps[index]?.link && (
                                                    <p className="error-message">
                                                        <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" alt="error" />
                                                        {errors.scraps[index]?.link?.message}
                                                    </p>
                                                )}
                                            </div>

                                            <div dangerouslySetInnerHTML={{ __html: item.link }} className='preview' ></div>

                                            {
                                                (index === formwatch.scraps.length - 1 && formwatch.scraps.length <= 3) && <>
                                                    <button type="button" className='btn-icon mt-10' onClick={(e) => { e.preventDefault(); append({ link: "", seq: index + 1 }) }}>
                                                        <img src="/assets/images/icons/icon-plus-circle.svg" alt="add" />
                                                    </button>
                                                </>

                                            }
                                            <button type="button" className='btn-icon mt-10' onClick={(e) => { e.preventDefault(); remove(index) }}>
                                                <img src="/assets/images/icons/trash.svg" alt="delete" />
                                            </button>
                                        </div>


                                    </li>
                                );
                            })}

                        </ul>


                        <div className='row mt-20'>

                            <div className="col-sm-8 col-md-3">

                                {loading ?
                                    <button type="button" className="btn small" disabled={true}>
                                        Saving..
                                    </button>
                                    :
                                    <button className="btn small" type="submit">
                                        Save
                                    </button>}
                            </div>
                        </div>


                    </div>
                </form >
            </div > : <button className='btn small' onClick={() => append({ link: "", seq: 1 })}> Add Scrapbook link </button>}
    </>

    )
}

export default Scrap