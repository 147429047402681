import { useRef, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { campaignClient } from "../../api";
import { PageLoader, Table } from "../../Components";
import { showToast } from "../../Components/Toast/ToastManager";
import { useQuery } from "react-query";
import axios from "axios";
import { getFileUrl, getImage } from "../../helpers/utils";
import { TableColumns } from "./videoColumns";
import AddSpotlite, { spotlightVideo } from "./addSpotlite";
import { useSearchParams } from "react-router-dom";

type FormEvent = {
    desc_en: string,
    desc_fr: string,
    fileName: string,
    key: string,
    title_fr: string,
    title_en: string
};
type returnData = FormEvent & {
    downloadUrl: string;
    id: string
};

const AthleteSpotlights = () => {
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<File>();
    const [uploading, setUploading] = useState(false);
    const [previewImg, setPreviewImg] = useState("");
    const [searchParams, setSearchParams] = useSearchParams()
    const id = searchParams.get("id")
    const {
        handleSubmit,
        register,
        watch,
        reset,
        formState: { errors },
    } = useForm<FormEvent>({
        mode: "onTouched",
        criteriaMode: "all",
    });
    const formwatch = watch()

    const {
        isLoading: isLoadingAthlete,
        data: athlete,
        refetch,
    } = useQuery("getData", async () => {
        const res: { data: returnData } = await campaignClient.call("getData", { statsType: "athleteIntro" })
        reset(res.data)
        return res.data;
    });

    const {
        isLoading: isLoadingVideos,
        data: athleteVideos,
        refetch: refetchVideos,
    } = useQuery("getData1", async () => {
        const res: spotlightVideo[] = await campaignClient.call("getData", { statsType: "athleteVideo" })
        return res;
    });

    const handleSaveChanges = async (data: FormEvent) => {
        if (loading) {
            return;
        }
        setLoading(true);
        let key, fileName;

        if (previewImg) {

            const res = await handleUpload();
            key = res?.key
            fileName = res?.fileName

        }
        else if (formwatch.fileName) {
            key = formwatch.key
            fileName = formwatch.fileName
        }

        const result = await campaignClient.call("saveIntro", {
            desc_en: data.desc_en,
            desc_fr: data.desc_fr,
            key,
            fileName,
            title_en: data.title_en,
            title_fr: data.title_fr,
        });

        if (result) {
            refetch();
            showToast({
                content: "Saved",
                duration: 3000,
                error: false,
            });

            setLoading(false);
        }
    };

    const DeleteImage = async () => {
        if (loading) {
            return;
        }
        setLoading(true);

        if (athlete?.fileName) {
            const result = await campaignClient.call("saveIntro", {
                desc_en: watch("desc_en"),
                desc_fr: watch("desc_fr"),
                title_en: watch("title_en"),
                title_fr: watch("title_fr"),
            });

            if (result) {
                refetch();
                showToast({
                    content: "Saved",
                    duration: 3000,
                    error: false,
                });

            }
        }
        setPreviewImg("");
        setFile(undefined);
        setLoading(false);

    };

    async function handleSingleFile(fileData: any) {
        if (!fileData?.target?.files?.length) {
            return;
        }
        const type = fileData?.target?.files[0].type;
        if (+fileData?.target?.files[0].size > 5266467) {
            return showToast({
                content: "Image size more then  5mb.",
                duration: 3000,
                error: true,
            });
        } else if (type.includes("image/")) {
            const url = URL.createObjectURL(fileData.target.files[0]);
            setPreviewImg(url);
            setFile(fileData.target.files[0]);
        } else {
            showToast({
                content: "An error while upload .",
                duration: 3000,
                error: true,
            });
        }
    }

    async function handleUpload() {
        if (!file || uploading) {
            return;
        }
        setUploading(true);

        const fileToUpload = file;
        try {
            const data = await fileToUpload.arrayBuffer();
            const { uploadUrl, key } = await campaignClient.call<{
                uploadUrl: string;
                key: string;
            }>("getPublicHostingUrl", { filename: file.name, filetype: file.type, fileSize: file.size });

            await axios.put(uploadUrl, data, {
                onUploadProgress: function (progressEvent) { },
                headers: {
                    "Content-Type": file.type,
                },
            });

            return { key: key, fileName: fileToUpload.name };
        } catch (e) {
            console.log(e);
        }
        setUploading(false);
    }
    async function deleteAthlete(id: string) {
        if (loading) {
            return;
        }
        setLoading(true);

        const result = await campaignClient.call("deleteSpotlight", {
            id
        });

        if (result) {
            refetchVideos();
            showToast({
                content: "Deleted",
                duration: 3000,
                error: false,
            });

            setLoading(false);
        }

    }
    const columns = TableColumns({ deleteAthlete });

    if (loading || isLoadingAthlete || isLoadingVideos) {
        return <PageLoader />;
    }
    return (
        <>
            {id ? <AddSpotlite athlete={athleteVideos?.find(a => a.id == id)} newAthlete={id === "new" ? true : false} refetch={refetchVideos} /> : <>
                <div className="main__head">
                    <h2 className="main__title">Athlete spotlight</h2>
                </div>
                <p>Add the details for the introduction section of the athlete spotlight.</p>
                <div className="main__body main__body--flex main__body--flex-alt">
                    <form onSubmit={handleSubmit(handleSaveChanges)} className="form">
                        <div style={{ maxWidth: "80%" }}>
                            <div className="row mt-20">
                                <div className="col-sm-12 col-md-6">
                                    <label htmlFor="firstName" className="form__label">
                                        <b>Intro title - English</b>
                                    </label>

                                    <div className="form__controls">

                                        <input
                                            type="text"
                                            className="field"
                                            id="firstName"
                                            {...register("title_en", {
                                                // required: {
                                                //     value: true,
                                                //     message: "Please enter first name.",
                                                // },
                                            })}
                                            defaultValue={athlete?.title_en ?? ""}
                                        />
                                    </div>
                                    {errors.title_en && (
                                        <p className="error-message">
                                            <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                                            {errors.title_en.message}
                                        </p>
                                    )}

                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <label htmlFor="firstName" className="form__label">
                                        <b>Intro title - French</b>
                                    </label>
                                    <div className="form__controls">

                                        <input
                                            type="text"
                                            className="field"
                                            id="title_fr"
                                            {...register("title_fr", {
                                                // required: {
                                                //     value: true,
                                                //     message: "Please enter first name.",
                                                // },
                                            })}
                                            defaultValue={athlete?.title_fr ?? ""}
                                        />
                                    </div>
                                    {errors.title_fr && (
                                        <p className="error-message">
                                            <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                                            {errors.title_fr.message}
                                        </p>
                                    )}

                                </div>

                            </div>

                            <div className="row mt-20">
                                <div className="col-sm-12 col-md-6">


                                    <label htmlFor="firstName" className="form__label">
                                        <b>Intro Description - English</b>
                                    </label>
                                    <textarea className="field text-area-1" id="message" defaultValue={athlete?.desc_en ?? ""} rows={4} cols={10} {...register("desc_en")}></textarea>
                                    {errors.desc_en && (
                                        <p className="error-message">
                                            <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                                            {errors.desc_en.message}
                                        </p>
                                    )}
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <label htmlFor="desc_fr" className="form__label">
                                        <b>Intro Description - French</b>
                                    </label>

                                    <textarea className="field text-area-1" id="message" rows={4} cols={10} defaultValue={athlete?.desc_fr ?? ""} {...register("desc_fr")}></textarea>
                                    {errors.desc_fr && (
                                        <p className="error-message">
                                            <img src="/assets/images/svg/exclamation-mark.svg" title="Exclamation Mark" />
                                            {errors.desc_fr.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            {(previewImg || athlete?.fileName) &&
                                <div className="row mt-20" style={{ alignItems: "center" }} >
                                    <div className="col-sm-8 col-md-3">
                                        <label htmlFor="upload">
                                            <img
                                                src={previewImg ? previewImg : athlete ? getFileUrl(athlete.fileName) : "/assets/images/photo.png"}
                                                alt=""
                                                style={{ cursor: "pointer" }}
                                                width={200}
                                            />
                                        </label>
                                    </div>
                                    <div className="col-sm-8 col-md-6">
                                        {(file?.name || athlete?.fileName) && (
                                            <div className="row-icons">
                                                <p>{file?.name ? file?.name : athlete?.fileName ?? ""} </p>
                                                <button className="btn-icon" type="button" onClick={DeleteImage}>
                                                    <img src="/assets/images/svg/remove.svg" alt="remove" />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>}

                            <div className="row mt-20" style={{ alignItems: "center" }}>
                                <div className="col-sm-8 col-md-2">

                                    <button className="btn small" type="submit">
                                        Save changes
                                    </button>
                                </div>

                                <div className="col-sm-8 col-md-3">

                                    <button className="btn__cancel small" onClick={(e) => hiddenFileInput.current?.click()} disabled={(file?.name || athlete?.fileName) ? true : false} type="button">
                                        Upload an image
                                    </button>

                                    <input
                                        type="file"
                                        onChange={handleSingleFile}
                                        className="form__field"
                                        id="upload"
                                        ref={hiddenFileInput}
                                        name="upload"
                                        accept=".png, .jpg, .jpeg, capture=user"
                                        hidden
                                    />
                                </div>
                            </div>
                        </div>
                    </form >
                </div >
                <div className="main__head mt-20">
                    <h2 className="main__title">Athlete spotlight videos</h2>
                </div>
                <div className="flexOneLine  mb-10">
                    <p>Add the videos for the athlete spotlight here.</p>
                    <div>
                        <button className="btn small ml-10" onClick={() => setSearchParams({ id: "new" })}>Add video</button>
                    </div>

                </div>

                <Table data={athleteVideos ? athleteVideos : []} columns={columns} tablePageSize={8} />
            </>}
        </>
    );
};

export default AthleteSpotlights;
