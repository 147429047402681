export enum s3Host {
    'dev' = 'd2ztwr63umzbrb.cloudfront.net',
    'qa' = 'd19r6gsb6bv553.cloudfront.net',
    'prod' = 'd3tnfjs3dcqndn.cloudfront.net'
}

export const MESSAGES = {
    API_ERROR:  "Sorry, something seems to have gone wrong. Please try again.",
    INVALID_RESPONSE:  "Sorry, something seems to have gone wrong.",
    SUCCESS: "Saved successfully.",
}

export enum EquestionStatus {
    "invisible" = "0",
    "visible" = "1",
    "deleted" = "2",
}
export enum EmessageStatus {
    "pending" = "0",
    "approved" = "1",
    "declined" = "2",
}
export enum EquizStatus {
    "disabled" = "0",
    "active" = "1",
    "deleted" = "2",
}

export enum ESurveyWinnerStatus {
    "potential" = "1",
    "decline" = "2",
    "win" = "3",
    "reject" = "4",
}

export const PRIZES:{[key:string]: { label: string, prizePoolKey: string, prizeKey: string}} = {
    dream: {
        label: "Dream champion",
        prizePoolKey: "c10cffc80c7d62e9cbfb288895aae08e",
        prizeKey: "0fd2aa4625081987325a1461e2f35484",
    },
    weekly: {
        label: "Weekly challenge",
        prizePoolKey: "41707143c664bd2d6ad71724b687a629",
        prizeKey: "3262004fcbabda0f2f6aea74ff3e8995",
    },
    lunch: {
        label: "Messages",
        prizePoolKey: "9ab86db49eee93c3345f90822b34df89",
        prizeKey: "9ab86db49eee93c3345f90822b34df89",
    },
    trivia: {
        label: "Trivia",
        prizePoolKey: "43ea3400ae9f0fa499b61380737d9eb1",
        prizeKey: "b7a0a5d89c00fecded7a92160b4e6f24",
    },
    survey: {
        label: "Correspondent Program",
        prizePoolKey: "8418345556b79585359cca7960f479cd",
        prizeKey: "241c26036b8602ddf9456bf324931993",
    }
};

export const SURVEY_FILTER = [
    { value: "equality", label: "Equality - Égalité" },
    { value: "courage", label: "Courage - Courage" },
    { value: "determination", label: "Determination - Détermination" },
    { value: "inspiration", label: "Inspiration - Inspiration" },
    { value: "teammate", label: "Teammate - Coéquipier" },
    { value: "diversity", label: "Diversity - Diversité" },
    { value: "inclusivity", label: "Inclusivity - Inclusivité" },
    { value: "ambition", label: "Ambition - Ambition" },
    { value: "adversity", label: "Adversity - Adversité" },
    { value: "resilience", label: "Resilience - Résilience" },
    { value: "pride", label: "Pride - Fierté" },
    { value: "celebrate", label: "Celebrate - Célébrer" },
    { value: "dream", label: "Dream - Rêve" },
    { value: "athlete", label: "Athlete - Athlète" },
    { value: "media", label: "Media - Médias" },
    { value: "correspondent", label: "Correspondent - Correspondant" },
]

export const WINNER_TYPE_FILTER = [                       
    { value: "survey", label: "Correspondent Program" },     
    { value: "dream", label: "Dream champion" },
    { value: "lunch", label: "Messages" },
    { value: "trivia", label: "Trivia" },                       
    { value: "weekly", label: "Weekly Challenge" },                                
]

export const Status_type_filter = [                       
    { value: "Rejected", label: "Rejected" },     
    { value: "Pending Claim", label: "Pending Claim" },
    { value: "Claimed", label: "Claimed" },
]

export const MONTH_TYPE_FILTER = [                       
    { value: "01", label: "January" },     
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },                       
    { value: "05", label: "May" },         
    { value: "06", label: "June" },                                
    { value: "07", label: "July" },                                
    { value: "08", label: "August" },                                
    { value: "09", label: "September" },                                
    { value: "10", label: "October" },                                
    { value: "11", label: "November" },                                
    { value: "12", label: "December" },                                

]
