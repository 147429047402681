import { Link } from "react-router-dom";
import { Row, TableProps } from "react-table";
import dayjs from "dayjs";

export const quizColumns = ({ updateStatus, setDeleteQuiz }: { setDeleteQuiz: Function; updateStatus: Function }) => {
    const COLUMNS = [
        {
            Header: "#",
            accessor: "q_seq",
            width: "3%",
            Cell: (TableInfo: TableProps) => {
                return <p>{TableInfo.row.index + 1}</p>;
            },
        },
        {
            Header: "Enabled set",
            accessor: "enabled",
            width: "8%",
            Cell: (TableInfo: TableProps) => {
                const isChecked = TableInfo.data[TableInfo.row.index].status === "1";
               
                return TableInfo.data[TableInfo.row.index].status ? (
                    <label className="switch">
                        <input
                            type="checkbox"
                            id="checkbox_school"
                            name="status"
                            defaultChecked={isChecked}
                            //  {...register("spotlight")}
                            onClick={() => {
                                updateStatus({ quiz: TableInfo.data[TableInfo.row.index].quiz, status: isChecked ? "0" : "1" });
                            }}
                        />

                        <span className="slider round"></span>
                    </label>
                ) : (
                    "N/A"
                );
            },
        },
        {
            Header: "Quiz title",
            accessor: "quizName",
            with: "30%",
        },

        {
            Header: "Number of questions",
            accessor: "quizLength",
            width: "10%",
        },

        {
            Header: "Modified",
            accessor: "updateTime",
            width: "15%",
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].updateTime ? dayjs(TableInfo.data[TableInfo.row.index].updateTime).format("MMMM DD, YYYY, HH:MM:ss A") : "N/A";
            },
        },

        {
            Header: " ",
            accessor: "actions",
            width: "8%",
            Cell: (TableInfo: TableProps) => {
                return (
                    <div className="row-icons">
                        <button className="btn-icon" onClick={() => setDeleteQuiz({ quiz: TableInfo.data[TableInfo.row.index].quiz, isOpen: true })}>
                            <img src="/assets/images/icons/trash.svg" alt="delete" />
                        </button>
                        <Link className="btn-icon textCenter" to={`/trivia/edit/${encodeURI(TableInfo.data[TableInfo.row.index].quiz)}`}>
                            <img src="/assets/images/icons/udate.svg" alt="update" />
                        </Link>
                    </div>
                );
            },
        },
    ];
    return COLUMNS;
};
