import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import  "./custom_datepicker.css"

type Props = {
    selectDate: Date | null,
    setSelectDate: Function
    placeholder?: string
}

export default function ReactDatePicker(props: Props) {

    //const [selectDate, setSelectDate] = useState(new Date());

  return (
    <DatePicker 
    showIcon
    selected={props.selectDate} 
    onChange={(date: Date) =>{
      props.setSelectDate(date)
    }} 
    placeholderText = { props.placeholder || ""}
    isClearable
    
    />
  )
}